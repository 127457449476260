import NotificationImportant from '~/images/md-icons/notification_important/materialicons/24px.svg';
import ParkIcon from '~/images/md-icons/park/materialicons/24px.svg';
import NewReleasesIcon from '~/images/md-icons/new_releases/materialicons/24px.svg';

const NotificationImportantIcon = ({icon_type}) => {
  switch (icon_type) {
    case 'christmas':
      return <ParkIcon fill="#299976" width="24" height="24" />;
    case 'new-releases':
      return <NewReleasesIcon fill="#2907E3" width="24" height="24" />;
    default:
      return <NotificationImportant fill="#ff6c00" width="24" height="24" />;
  }
};

export default NotificationImportantIcon;
