export default {
  xSmall: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 10,
    lineHeight: 15,
  },
  xSmallMedium: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 12,
    lineHeight: 18,
  },
  small: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 12,
    lineHeight: 18,
  },
  smallPlus: {
    fontFamily: 'Roboto_700Bold',
    fontSize: 14,
    lineHeight: 20,
  },
  smallMedium: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 14,
    lineHeight: 20,
  },
  smallRegular: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
    lineHeight: 20,
  },
  regular: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    lineHeight: 20,
  },
  medium: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    lineHeight: 20,
  },
  mediumLight: {
    fontFamily: 'Roboto_300Light',
    fontSize: 16,
    lineHeight: 20,
  },
  mediumSemi: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    lineHeight: 20,
  },
  mediumBold: {
    fontFamily: 'Roboto_700Bold',
    fontSize: 16,
    lineHeight: 20,
  },
  mediumPlus: {
    fontFamily: 'Roboto_700Bold',
    fontSize: 16,
    lineHeight: 20,
  },
  large: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 18,
    lineHeight: 27,
  },
  largePlus: {
    fontFamily: 'Roboto_700Bold',
    fontSize: 20,
    lineHeight: 20,
  },
  xLarge: {
    fontFamily: 'Roboto_700Bold',
    fontSize: 22,
    lineHeight: 22,
  },
  xxLarge: {
    fontFamily: 'Roboto_700Bold',
    fontSize: 24,
    lineHeight: 24,
  },
  xxxLarge: {
    fontFamily: 'Roboto_400Regular',
    fontSize: 30,
    lineHeight: 30,
  },
};
