import React, {useState, useEffect} from 'react';
import {Button} from 'react-native-paper';

const PrimaryButton = (props) => {
  const {icon, onPress, children} = props;
  const [disabled, setDisabled] = useState(props.disabled);

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  const _onPress = () => {
    setDisabled(true);
    onPress();
  };

  return (
    <Button
      icon={icon}
      disabled={disabled}
      mode="outline"
      onPress={_onPress}
      color="#828282">
      {children}
    </Button>
  );
};

export default PrimaryButton;
