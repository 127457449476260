import {StyleSheet} from 'react-native';
export default StyleSheet.create({
  actionsheet: {
    backgroundColor: '#fff',
    maxHeight: '100%',
  },
  row: {
    paddingHorizontal: 24,
    paddingVertical: 20,
  },
});
