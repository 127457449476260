import React, {useState, useEffect, useCallback} from 'react';
import {View} from 'react-native';
import _ from 'lodash';

import Pressable from '../Pressable';
import styles from './styles';
import DefaultIcon from '../../../images/md-icons/radio_button_unchecked/materialicons/24px.svg';
import CheckedIcon from '../../../images/md-icons/radio_button_checked/materialicons/24px.svg';

const RadioButton = (props) => {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const onPress = useCallback(
    _.debounce(
      () => {
        if (props.disabled) {
          return;
        }

        const value = !checked;
        if (value) {
          setChecked(value);
        }

        if (props.onPress) {
          props.onPress(value);
        }
      },
      1000,
      {leading: true, trailing: false},
    ),
    [props.disabled, props.onPress, checked],
  );

  return (
    <Pressable
      allowBubbling
      disabled={props.disabled}
      onPress={onPress}
      style={{opacity: 1}}>
      <View
        style={[styles.root, props.style, props.disabled && styles.disabled]}>
        {!checked && <DefaultIcon fill="#4a4a49" />}
        {checked && <CheckedIcon fill="#231fda" />}
      </View>
    </Pressable>
  );
};

export default RadioButton;
