import React, {useState, useEffect} from 'react';
import {View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import moment from 'moment';

import sy from '~/styles';
import {service_request as serviceRequestApi} from '~/api/private';

import {Text, Pressable, Persona} from '~/components/controls';
import {PersonaSize} from '~/components/controls/Persona';

import DoneIcon from '~/images/md-icons/done/materialicons/24px.svg';
import DoneAllIcon from '~/images/md-icons/done_all/materialicons/24px.svg';
import PhotoCameraIcon from '~/images/md-icons/photo_camera/materialicons/24px.svg';

const Message = (props) => {
  const {
    style,
    content,
    attachments,
    timestamp: message_timestamp,
    message_id,
    request_id,
    user_id,
    lessor,
    is_sent,
  } = props;
  const [is_read, setIsRead] = useState(props.is_read);

  useEffect(() => {
    const touch = async () => {
      await serviceRequestApi.message_read(request_id, message_id);
      setIsRead(true);
    };

    if (message_id) {
      touch();
    }
  }, [message_id, request_id]);

  let duration;
  if (message_timestamp) {
    const timestamp = moment(message_timestamp);
    if (timestamp.isSame(moment(), 'day')) {
      duration = timestamp.format('H:mm');
    } else {
      duration = timestamp.format('D MMMM YYYY');
    }
  }

  return (
    <>
      <View style={[sy['flex-row'], sy['overflow-hidden'], {gap: 22}, style]}>
        <Persona
          is_dealer={true}
          style={[sy['self-center']]}
          imageInitials={lessor.substring(0, 2)}
          lessor={lessor}
          size={PersonaSize.size36}
        />
        <View style={[sy['flex-1']]}>
          <View
            style={[
              sy['flex-row'],
              sy['items-center'],
              sy['justify-between'],
              sy['pb-1'],
            ]}>
            <div data-private>
              <Text style={[sy.mediumBold, {lineHeight: 16}]}>{lessor}</Text>
            </div>
            <View style={[sy['flex-row'], sy['items-center'], sy['gap-2']]}>
              <Text style={[sy.smallRegular, {lineHeight: 14}]}>
                {duration}
              </Text>
              {is_read ? (
                <DoneAllIcon width={14} height={14} fill="#231fda" />
              ) : is_sent ? (
                <DoneIcon width={14} height={14} fill="#231fda" />
              ) : null}
            </View>
          </View>
          <View style={[[sy['flex-row']]]}>
            {user_id ? <Text style={sy.smallRegular}>Jij:&nbsp;</Text> : null}
            {attachments?.length > 0 ? (
              <>
                <PhotoCameraIcon width={18} height={18} fill="#4a4a49" />
                <Text style={sy.smallRegular}>&nbsp;Foto</Text>
              </>
            ) : (
              <Text style={sy.smallRegular}>{content}</Text>
            )}
          </View>
        </View>
      </View>
    </>
  );
};

const LessorMessage = (props) => {
  const navigation = useNavigation();
  const {request_id, disabled, onPress, ...rest} = props;

  return (
    <Pressable
      style={[sy['p-4']]}
      disabled={disabled}
      onPress={() => {
        onPress?.();

        navigation.navigate('Chat', {request_id});
      }}>
      <Message request_id={request_id} {...rest} editable={false} />
    </Pressable>
  );
};

export default LessorMessage;
