import React from 'react';
import {View} from 'react-native';

import {Dialog, Text} from '~/components/controls';
import sy from '~/styles';

import InfoIcon from '~/images/md-icons/info/materialicons/24px.svg';

const DamageNotAllowedDialog = ({onDismiss}) => (
  <Dialog
    visible={true}
    onDismiss={onDismiss}
    options={{noPaddingContent: true}}
    title="Schadewerkopdracht"
    titleIcon={<InfoIcon fill="#4a4a49" />}>
    <View style={[sy['py-4'], sy['px-6'], sy['gap-4']]}>
      <Text>Schadekenmerk bij activiteit wordt niet toegestaan door LM.</Text>
      <Text>Login bij ROB-Net voor een schadewerkopdracht.</Text>
    </View>
  </Dialog>
);

export default DamageNotAllowedDialog;
