import React from 'react';
import Text from '../Text';

export default (props) => {
  return (
    <div data-private>
      <Text {...props} />
    </div>
  );
};
