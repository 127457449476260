import React, {useEffect, useState} from 'react';
import {View, ScrollView, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import {Text, Checkbox, ActionButton} from '~/components/controls';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import {rob as robApi} from '~/api/private';
import {DEFAULT_LOCATIONS, ROB_LOCATIONS, LOCATION_TEXT} from '~/types/rob';

const Location = ({
  onDismiss,
  license,
  request_id,
  rob_code,
  operation_code,
  location,
  onChange,
}) => {
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState(DEFAULT_LOCATIONS);
  const [selected, setSelected] = useState(location?.split('') || []);

  useEffect(() => {
    const fetch = async () => {
      if (rob_code) {
        const {result: rob_component} = await robApi.component(
          license,
          request_id,
          rob_code,
        );
        const selected_operation = rob_component[rob_code]?.operations.find(
          (item) => item.operation.code === operation_code,
        );

        if (selected_operation) {
          let locations = [];
          for (const location of selected_operation.locations) {
            const code = location.map((item) => item.code).join('');
            if (ROB_LOCATIONS[code]) {
              locations = [...new Set([...locations, ...ROB_LOCATIONS[code]])];
            }
          }

          setLocations(locations);
        }
      }

      setLoading(false);
    };

    fetch();
  }, [request_id, license, rob_code, operation_code]);

  const onOK = () => {
    onChange({
      location: selected.join(''),
    });
  };

  return (
    <Actionsheet visible={true} onDismiss={onDismiss}>
      {loading && (
        <View style={[sy['p-4']]}>
          <ActivityIndicator size="large" color="#231fda" />
        </View>
      )}
      {!loading && (
        <>
          <Title>Locatie</Title>
          <Divider />
          <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
            <ScrollView contentContainerStyle={{maxHeight: 400}}>
              {locations.length === 0 && (
                <View style={[sy['p-4']]}>
                  <Text>Geen locatie</Text>
                </View>
              )}
              {locations?.map((location) => (
                <View
                  key={location}
                  style={[sy['border-b'], sy['border-lightgray']]}>
                  <View style={[sy['p-4'], sy['flex-row'], sy['items-center']]}>
                    <View style={[sy['pr-4']]}>
                      <Checkbox
                        checked={selected?.includes(location)}
                        disabledTimeout={0}
                        onPress={() => {
                          if (selected?.includes(location)) {
                            setSelected(
                              selected.filter((item) => item !== location),
                            );
                          } else {
                            setSelected([...selected, location]);
                          }
                        }}
                      />
                    </View>
                    <Text>{LOCATION_TEXT[location]}</Text>
                  </View>
                </View>
              ))}
            </ScrollView>
          </Content>
          <Actions>
            <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
            <ActionButton onPress={onOK}>OK</ActionButton>
          </Actions>
        </>
      )}
    </Actionsheet>
  );
};

export default Location;
