export default {
  'select-none': {
    userSelect: 'none',
  },
  'select-text': {
    userSelect: 'text',
  },
  'select-all': {
    userSelect: 'all',
  },
  'select-auto': {
    userSelect: 'auto',
  },
  'view-disabled': {
    opacity: 0.5,
  },
};
