import React, {useState, useRef, useEffect} from 'react';
import {Menu} from 'react-native-paper';

export default (props) => {
  const [visible, setVisible] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return (
    <Menu
      ref={(ref) => {
        menuRef.current = ref;
        if (menuRef.current) {
          // HACK: For iOS home screen web apps, the menu is dismissed as soon as the menu is shown due to size changes. So we remove the listener.
          const attachListeners = menuRef.current.attachListeners;
          menuRef.current.attachListeners = () => {
            attachListeners();
            menuRef.current.dimensionsSubscription?.remove();
            menuRef.current.dimensionsSubscription = null;
          };
        }
      }}
      visible={visible}
      onDismiss={() => {
        setVisible(false);
        if (props.onDismiss) {
          props.onDismiss();
        }
      }}
      anchor={
        // HACK: Because of the way Pressable includes a tabindex and Menu auto focuses on those, we can't use Pressable.
        // That's why we use a div here.
        <div
          style={{cursor: 'pointer'}}
          onClick={() => {
            setVisible(true);
            // Work around for the fact that the menu ref is not yet assigned before measuring takes place.
            // See Menu.tsx in react-native-paper.
            requestAnimationFrame(menuRef.current.show);

            if (props.onShow) {
              props.onShow();
            }
          }}>
          {props.anchor}
        </div>
      }
      style={props.style}
      contentStyle={props.contentStyle}>
      {props.children}
    </Menu>
  );
};
