import React from 'react';

import sy from '~/styles';
import {Text} from '~/components/controls';

const ExternalLink = ({href, textStyle, children}) => {
  return (
    <a
      href={href}
      style={{textDecoration: 'none'}}
      target="_blank"
      rel="noreferrer">
      <Text style={[sy['underline'], textStyle]}>{children}</Text>
    </a>
  );
};

export default ExternalLink;
