import React, {useEffect, useState, useCallback} from 'react';
import {View, ScrollView, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';

import sy from '~/styles';
import {
  Text,
  RadioButton,
  Pressable,
  ActionButton,
} from '~/components/controls';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import SearchRobCodeModal from '~/components/shared/Modals/search_rob_code';

import {
  service_request as serviceRequestApi,
  rob as robApi,
} from '~/api/private';
import withDimensions from '~/components/hoc/with-dimensions';

import AddIcon from '~/images/md-icons/add/materialicons/24px.svg';

const HEADER_HEIGHT_IN_PX = 72;
const FOOTER_HEIGHT_IN_PX = 52;

const RobCode = ({
  dimensions,
  dismissable = true,
  onDismiss,
  request_id,
  license,
  codes: initial_codes,
  rob_code,
  onChange,
  options = {
    maintenance_codes: false,
    maintenance_codes_only: false,
  },
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedRobCode, setSelectedRobCode] = useState(rob_code);
  const [codes, setCodes] = useState([
    ...new Set([...(initial_codes ?? []), rob_code].filter(Boolean)),
  ]);
  const [rob_components, setRobComponents] = useState(null);

  const [search, setSearch] = useState(false);

  const onPrefixChange = useCallback(
    async (value) => {
      const {result, success} = await serviceRequestApi.search_rob_code(
        request_id,
        value,
        options,
      );

      if (success) {
        return result;
      }

      return [];
    },
    [request_id, options],
  );

  useEffect(() => {
    const fetch = async () => {
      const {result: rob_components} = await robApi.components(
        license,
        request_id,
      );

      setRobComponents(rob_components);

      if (loading && !codes.length) {
        setSearch(true);
      }

      setLoading(false);
    };

    fetch();
  }, [license, request_id, rob_code, codes]);

  return (
    <>
      <Actionsheet
        visible={true}
        dismissable={dismissable}
        onDismiss={onDismiss}>
        {loading && (
          <View style={[sy['p-4']]}>
            <ActivityIndicator size="large" color="#231fda" />
          </View>
        )}
        {!loading && (
          <>
            <Title>ROB activiteit</Title>
            <Divider />
            <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
              <ScrollView
                contentContainerStyle={{
                  maxHeight:
                    dimensions.window.height -
                    HEADER_HEIGHT_IN_PX -
                    FOOTER_HEIGHT_IN_PX,
                }}>
                {codes.map((rob_code) => (
                  <View key={rob_code}>
                    <View style={[sy['p-4'], sy['flex-row']]}>
                      <View style={[sy['pr-8']]}>
                        <RadioButton
                          checked={selectedRobCode === rob_code}
                          onPress={() => setSelectedRobCode(rob_code)}
                        />
                      </View>
                      <Text>
                        {rob_code}&nbsp;&middot;&nbsp;
                        {rob_components[rob_code]?.description}
                      </Text>
                    </View>
                    <Divider style={sy['bg-gray95']} />
                  </View>
                ))}
                <Pressable
                  style={[sy['p-4'], sy['flex-row']]}
                  onPress={() => setSearch(true)}>
                  <View style={[sy['pr-8']]}>
                    <AddIcon fill="#231fda" />
                  </View>
                  <Text>Keuze toevoegen</Text>
                </Pressable>
              </ScrollView>
            </Content>
            <Divider />

            <Actions>
              <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
              <ActionButton
                disabled={!selectedRobCode}
                onPress={() =>
                  onChange(
                    selectedRobCode,
                    rob_components[selectedRobCode].description,
                  )
                }>
                OK
              </ActionButton>
            </Actions>
          </>
        )}
      </Actionsheet>
      {search && (
        <SearchRobCodeModal
          codes={codes}
          onSearch={onPrefixChange}
          onDismiss={() => setSearch(false)}
          onAdd={(selection) => {
            if (!codes.length && selection.length === 1) {
              onChange(selection[0].rob_code, selection[0].description);
            } else {
              setCodes([...codes, ...selection.map((item) => item.rob_code)]);
            }

            setSearch(false);
          }}
        />
      )}
    </>
  );
};

export default withDimensions(RobCode);
