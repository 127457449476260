import React, {useState, useEffect} from 'react';
import {View} from 'react-native';
import {Divider} from 'react-native-paper';
import moment from 'moment';
import _ from 'lodash';

import {Pressable, Dialog, Text, Link} from '~/components/controls';
import Format from '~/lib/format';

import PriceCheckIcon from '~/images/md-icons/price_check/materialicons/24px.svg';
import EuroSymbolIcon from '~/images/md-icons/euro_symbol/materialicons/24px.svg';
import TimerIcon from '~/images/md-icons/timer/materialicons/24px.svg';

import sy from '~/styles';

const TYPES = {
  Green: 'green',
  Orange: 'orange',
  Red: 'red',
};

const PriceCheck = ({
  rob_code,
  amount,
  range,
  duration,
  suggested_duration,
  onChangeDuration,
}) => {
  const [type, setType] = useState(null);
  const [durationType, setDurationType] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (range?.min && range?.max) {
      if (amount <= range.min) {
        setType(TYPES.Green);
      } else if (range.min < range.max && amount > range.max) {
        setType(TYPES.Red);
      } else {
        setType(TYPES.Orange);
      }
    } else {
      setType(null);
    }
  }, [amount, range]);

  useEffect(() => {
    if (_.isNumber(duration) && _.isNumber(suggested_duration)) {
      const rounded_duration = Math.round(duration * 100) / 100;
      const rounded_suggested_duration =
        Math.round(suggested_duration * 100) / 100;
      if (rounded_duration > rounded_suggested_duration) {
        setDurationType(TYPES.Red);
      } else {
        setDurationType(TYPES.Green);
      }
    } else {
      setDurationType(null);
    }
  }, [duration, suggested_duration]);

  return (
    <>
      {
        <Pressable onPress={() => setShowDialog((prevState) => !prevState)}>
          <PriceCheckIcon
            fill={
              (type ?? durationType) === TYPES.Green
                ? '#299976'
                : (type ?? durationType) === TYPES.Red
                ? '#972727'
                : '#FF6C00'
            }
          />
        </Pressable>
      }
      <Dialog
        title={`Prijscheck ${rob_code}`}
        titleIcon={
          <PriceCheckIcon
            fill={
              (type ?? durationType) === TYPES.Green
                ? '#299976'
                : (type ?? durationType) === TYPES.Red
                ? '#972727'
                : '#FF6C00'
            }
          />
        }
        visible={showDialog}
        onDismiss={() => setShowDialog(false)}
        options={{
          noPaddingContent: true,
        }}>
        {!_.isNil(durationType) && (
          <>
            <View style={[sy['p-4'], sy['gap-8'], sy['flex-row']]}>
              <TimerIcon
                fill={
                  durationType === TYPES.Green
                    ? '#299976'
                    : durationType === TYPES.Red
                    ? '#972727'
                    : '#FF6C00'
                }
              />
              <View style={[sy['flex-1']]}>
                <Text>
                  Tijd {durationType === TYPES.Green ? <>OK</> : <>te hoog?</>}
                </Text>
                <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                  <>{Format.number(suggested_duration, 2)}</>&nbsp;uur meest
                  goedgekeurd
                </Text>
                {durationType !== TYPES.Green ? (
                  <Link
                    textStyle={[
                      sy.smallRegular,
                      sy['no-underline'],
                      {color: '#2907E3'},
                    ]}
                    onPress={() => {
                      onChangeDuration(suggested_duration, duration);
                      setShowDialog(false);
                    }}>
                    Toepassen
                  </Link>
                ) : null}
              </View>
            </View>
            <Divider />
          </>
        )}
        {!_.isNil(type) && (
          <View style={[sy['p-4'], sy['gap-8'], sy['flex-row']]}>
            <EuroSymbolIcon
              fill={
                type === TYPES.Green
                  ? '#299976'
                  : type === TYPES.Red
                  ? '#972727'
                  : '#FF6C00'
              }
            />
            <View style={[sy['flex-1']]}>
              <Text>
                Bedrag {type === TYPES.Green ? <>OK</> : <>te hoog?</>}
              </Text>
              <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                Op&nbsp;
                {moment(
                  [TYPES.Green, TYPES.Orange].includes(type)
                    ? range?.max_date
                    : range?.min_date,
                ).format('D MMMM YYYY')}
                &nbsp;met&nbsp;
                {TYPES.Green === type
                  ? 'gelijk of hoger bedrag goedgekeurd'
                  : TYPES.Orange === type
                  ? 'lager bedrag goedgekeurd'
                  : 'lager bedrag afgekeurd'}
              </Text>
            </View>
          </View>
        )}
      </Dialog>
    </>
  );
};

export default PriceCheck;
