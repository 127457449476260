import React, {useState} from 'react';
import {View} from 'react-native';

import sy from '~/styles';
import {Dialog, FormNumberInput} from '~/components/controls';

const MarkupDialog = ({value: initial_value, amount, onDismiss, onChange}) => {
  const [markup, setMarkup] = useState(initial_value ?? 0);

  return (
    <Dialog
      visible={true}
      title="Toeslag"
      onDismiss={() => {
        onChange(initial_value);
        onDismiss();
      }}
      buttons={[
        {
          text: 'Annuleren',
          onPress: () => {
            onChange(initial_value);
            onDismiss();
          },
        },
        {
          text: 'OK',
          onPress: () => {
            onChange(markup);
            onDismiss();
          },
        },
      ]}
      options={{noPaddingContent: true}}>
      <View style={[sy['p-4']]}>
        <FormNumberInput
          label="Bedrag toeslag"
          digits={2}
          value={markup}
          onChangeNumber={(value) => {
            const markup = Math.max(value, 0);
            setMarkup(markup);
            onChange(markup);
            return markup;
          }}
        />
      </View>
      <View style={[sy['p-4'], sy['pt-0']]}>
        <FormNumberInput
          label="Bedrag na toeslag"
          digits={2}
          value={amount + markup}
          onChangeNumber={(value) => {
            const markup = Math.max(value - amount, 0);
            setMarkup(markup);
            onChange(markup);
            return amount + markup;
          }}
        />
      </View>
    </Dialog>
  );
};

export default MarkupDialog;
