import React from 'react';

import ListIcon from '~/images/md-icons/list/materialicons/24px.svg';
import CoffeeIcon from '~/images/md-icons/local_cafe/materialicons/24px.svg';
import MarkChatRead from '~/images/md-icons/mark_chat_read/materialicons/24px.svg';
import PedalBikeIcon from '~/images/md-icons/pedal_bike/materialicons/24px.svg';
import SyncAltIcon from '~/images/md-icons/sync_alt/materialicons/24px.svg';
import DirectionsCarIcon from '~/images/md-icons/directions_car/materialicons/24px.svg';
import EmojiTransportationIcon from '~/images/md-icons/emoji_transportation/materialicons/24px.svg';

export default ({type, ...rest}) => {
  const ICON = {
    none: <ListIcon {...rest} />,
    wait: <CoffeeIcon {...rest} />,
    message: <MarkChatRead {...rest} />,
    bike: <PedalBikeIcon {...rest} />,
    transport: <SyncAltIcon {...rest} />,
    car: <DirectionsCarIcon {...rest} />,
  };

  return ICON[type] ?? <EmojiTransportationIcon {...rest} />;
};
