export default {
  mainView: {
    fontFamily: 'Roboto_400Regular',
    backgroundColor: '#fff',
    height: '100%',
    fontSize: 14,
    padding: 0,
    flexGrow: 1,
  },
};
