import {StyleSheet} from 'react-native';

export const styles = {
  anchor: {
    textDecorationLine: 'none',
  },
  link: {
    color: '#231fda',
  },
};

export default StyleSheet.create(styles);
