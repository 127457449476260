import {actionTypes} from '../actions';

export const reducer = (sessionState, action) => {
  const {type, value} = action;
  switch (type) {
    case actionTypes.INIT: {
      return {
        ...sessionState,
        ...value,
      };
    }

    case actionTypes.ROUTE_CHANGE: {
      return {
        ...sessionState,
        nav_history: [...sessionState.nav_history, value],
      };
    }

    case actionTypes.APP_IS_LOADING: {
      return {
        ...sessionState,
        app_loading: true,
      };
    }

    case actionTypes.APP_DONE_LOADING: {
      return {
        ...sessionState,
        app_loading: false,
      };
    }

    case actionTypes.SERVICE_REQUEST_INSERT: {
      return {
        ...sessionState,
        serviceRequestInsert: sessionState.serviceRequestInsert + 1,
      };
    }

    case actionTypes.SERVICE_REQUEST_UPDATE: {
      const {
        request_id,
        status,
        reason,
        rob_id,
        rob_status,
        rob_invoicing_status,
      } = value;
      return {
        ...sessionState,
        serviceRequestUpdate: {
          ...sessionState.serviceRequestUpdate,
          [request_id]: {
            timestamp: new Date(),
            status,
            reason,
            rob_id,
            rob_status,
            rob_invoicing_status,
          },
        },
      };
    }

    case actionTypes.SERVICE_REQUEST_LAST_FETCH: {
      return {
        ...sessionState,
        serviceRequestLastFetch: {
          ...sessionState.serviceRequestLastFetch,
          [value]: new Date(),
        },
      };
    }

    case actionTypes.SHOW_LOGOUT_DIALOG: {
      return {
        ...sessionState,
        show_logout_dialog: value,
      };
    }

    case actionTypes.SET_SHOW_DRAWER: {
      return {
        ...sessionState,
        show_drawer: value,
      };
    }

    case actionTypes.SET_CALENDAR_CTX: {
      return {
        ...sessionState,
        calendar_ctx: {
          offset: value,
        },
      };
    }

    case actionTypes.SET_USER_SETTINGS: {
      return {
        ...sessionState,
        user_settings: {
          ...sessionState.user_settings,
          ...value,
        },
      };
    }

    case actionTypes.SHOW_TECRMI_DIALOG: {
      const skip = sessionState.user_settings.tecrmi_info_dialog_never_show;
      if (skip) {
        value.resolve();
        return sessionState;
      }

      return {
        ...sessionState,
        show_tecrmi_dialog: value,
      };
    }

    case actionTypes.HIDE_TECRMI_DIALOG: {
      return {
        ...sessionState,
        show_tecrmi_dialog: null,
      };
    }

    case actionTypes.SHOW_SETTINGS_MODAL: {
      return {
        ...sessionState,
        show_settings_modal: value,
      };
    }

    case actionTypes.SHOW_HELP_MODAL: {
      return {
        ...sessionState,
        show_help_modal: value,
      };
    }

    case actionTypes.UPDATE_IMPORTANT_MESSAGE: {
      return {
        ...sessionState,
        update_important_message: new Date(),
      };
    }

    case actionTypes.UPDATE_AFTER: {
      return {
        ...sessionState,
        update_after: new Date().getTime() + 1000 * 30, // 30 seconds
      };
    }

    case actionTypes.SHOW_REFRESH_IMPORTANT_DIALOG: {
      return {
        ...sessionState,
        show_refresh_important_dialog: true,
        urgent_refresh_required: true,
        refresh_required: false,
      };
    }

    case actionTypes.HIDE_REFRESH_IMPORTANT_DIALOG: {
      return {
        ...sessionState,
        show_refresh_important_dialog: false,
      };
    }

    case actionTypes.SHOW_REFRESH_DIALOG: {
      return {
        ...sessionState,
        show_refresh_dialog: true,
        refresh_required: true,
        urgent_refresh_required: false,
      };
    }

    case actionTypes.HIDE_REFRESH_DIALOG: {
      return {
        ...sessionState,
        show_refresh_dialog: false,
      };
    }

    case actionTypes.CLIENT_MESSAGES_DIALOG: {
      return {
        ...sessionState,
        client_messages_dialog: {
          ...value,
        },
      };
    }

    case actionTypes.SET_MESSAGES: {
      return {
        ...sessionState,
        messages: value,
      };
    }

    case actionTypes.FETCH_MESSAGES: {
      return {
        ...sessionState,
        fetch_messages: (sessionState.fetch_messages ?? 0) + 1,
      };
    }

    default: {
      return sessionState;
    }
  }
};
