import React from 'react';
import {createRoot} from 'react-dom/client';

import runtime from 'serviceworker-webpack5-plugin/lib/runtime';

import App from './App';
import config from './configuration';
import {fetch} from './lib/fetch';

const asyncCallWithTimeout = async (promise, timeout_in_ms) => {
  let timeoutHandle;

  return Promise.race([
    promise,
    new Promise((_resolve, reject) => {
      timeoutHandle = setTimeout(
        () => reject(new Error('Async call timeout')),
        timeout_in_ms,
      );
    }),
  ]).finally(() => {
    clearTimeout(timeoutHandle);
  });
};

const bootstrap = async () => {
  let registration = await runtime.register();
  console.log('Successfully registered sw', registration);

  registration = await navigator.serviceWorker.ready;
  navigator.serviceWorker.startMessages();
  console.log('Service worker ready', registration);

  try {
    const res = await asyncCallWithTimeout(
      fetch(config.fetchHandlerProbe),
      2000,
    );
    if (res?.status !== 200 || res?.statusText !== 'Intercepted') {
      throw new Error('Failed to intercept fetch');
    }

    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<App />);
  } catch {
    // Reload the page to get the service worker to intercept fetches
    window.location.reload();
  }
};
window.addEventListener('DOMContentLoaded', (event) => {
  bootstrap();
});
