import {StyleSheet} from 'react-native';

import main from './main';
import header from './header';
import button from './button';
import link from './link';
import content from './content';
import footer from './footer';
import fontSizes from './font-sizes';

import backgrounds from './backgrounds';
import borders from './borders';
import spacing from './spacing';
import flexbox from './flexbox';
import typography from './typography';
import layout from './layout';
import interactivity from './interactivity';

export default StyleSheet.create({
  ...main,
  ...header,
  ...content,
  ...footer,
  ...button,
  ...link,
  ...fontSizes,
  // Tailwind styles
  ...backgrounds,
  ...borders,
  ...spacing,
  ...flexbox,
  ...typography,
  ...layout,
  ...interactivity,
});
