export const SERVICE_TYPE_CODES = {
  Maintenance: 'Maintenance',
  Repairs: 'Repairs',
  Inspection: 'Inspection',
  Tires: 'Tires',
};

export const SERVICE_TYPE_DESCRIPTIONS = {
  Maintenance: 'Onderhoud',
  // Repairs: 'Reparatie',
  Inspection: 'APK',
  Tires: 'Banden',
};

export const SERVICE_TYPE_COMPONENTS = {
  Maintenance: 'Onderhoud',
  Inspection: 'APK',
  Tires: 'Banden',
  Repairs: 'Reparatie',
};

export const SERVICE_TYPE_COMPONENTS_ORDER = [
  SERVICE_TYPE_COMPONENTS.Maintenance,
  SERVICE_TYPE_COMPONENTS.Inspection,
  SERVICE_TYPE_COMPONENTS.Tires,
  SERVICE_TYPE_COMPONENTS.Repairs,
];
