import React from 'react';
import {Modal as PaperModal, Portal} from 'react-native-paper';

const Modal = ({
  style,
  contentContainerStyle,
  visible,
  onDismiss,
  children,
}) => {
  return (
    <Portal>
      <PaperModal
        contentContainerStyle={{
          height: '100%',
          width: '100%',
          justifyContent: 'start',
          backgroundColor: 'white',
          ...(contentContainerStyle ?? {}),
        }}
        style={[{margin: 0}, style]}
        visible={visible}
        dismissable={false}
        onDismiss={onDismiss}>
        {children}
      </PaperModal>
    </Portal>
  );
};

export default Modal;
