import React, {useState} from 'react';
import {View} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import {Text, Pressable, Dialog, Link} from '~/components/controls';
import ExpandLessIcon from '~/images/md-icons/expand_less/materialicons/24px.svg';
import ExpandMoreIcon from '~/images/md-icons/expand_more/materialicons/24px.svg';

export const EditDialog = ({
  dismissable = false,
  visible,
  title,
  titleIcon,
  titleStyle,
  cancelButton,
  disableOKButton = false,
  initialValue: _initialValue,
  onOK,
  onCancel,
  onClose,
  onValidate,
  children,
}) => (
  <Dialog
    visible={visible}
    title={title}
    titleIcon={titleIcon}
    titleStyle={titleStyle}
    dismissable={dismissable}
    initialValue={_initialValue}
    onDismiss={async ({initialValue}) => {
      await onCancel?.({initialValue});
      onClose();
    }}
    buttons={[
      cancelButton && {
        text: 'Annuleren',
        onPress: async ({initialValue}) => {
          await onCancel?.({initialValue});
          onClose();
        },
      },
      {
        text: 'OK',
        disabled: disableOKButton,
        onPress: async () => {
          if (onValidate) {
            const valid = await onValidate();
            if (!valid) {
              return;
            }
          }

          if (onOK) {
            await onOK();
          }

          onClose();
        },
      },
    ].filter(Boolean)}
    options={{noPaddingContent: true}}>
    {children}
  </Dialog>
);

export const Row = ({
  disabled = false,
  title,
  style,
  linkStyle,
  contentStyle,
  children,
  onEditClicked,
}) => {
  const contents = React.Children.map(children, (child) =>
    child?.type !== EditDialog ? child : null,
  );

  const dialog = React.Children.toArray(children).find(
    (child) => child?.type === EditDialog,
  );

  const [edit, setEdit] = useState(false);

  return (
    <>
      <View
        style={[
          sy['p-4'],
          sy['border-b'],
          sy['border-lightgray'],
          sy['flex-row'],
          sy['justify-between'],
          {minHeight: 56, marginBottom: -1},
          style,
        ]}>
        {title && <Text>{title}</Text>}
        {!dialog && <View style={contentStyle}>{contents}</View>}
        {dialog && (
          <>
            <Link
              disabled={disabled}
              textStyle={linkStyle}
              onPress={async () => {
                if (onEditClicked) {
                  await onEditClicked();
                }

                setEdit(!edit);
              }}>
              {contents}
            </Link>
            {React.cloneElement(dialog, {
              visible: edit,
              onClose: () => {
                dialog.props.onClose?.();
                setEdit(false);
              },
            })}
          </>
        )}
      </View>
    </>
  );
};

const Grouping = ({
  icon,
  header,
  children,
  style,
  pressableStyle,
  onExpand,
  initial = {expand: false},
  options = {topMost: false, switchChevrons: false},
}) => {
  const [expand, setExpand] = useState(initial.expand);

  const hasChildren = React.Children.toArray(children).length > 0;

  return (
    <>
      {!options.topMost && <Divider />}
      <View style={[sy['bg-white'], style]}>
        <Pressable
          style={[
            sy['flex-row'],
            sy['items-center'],
            sy['justify-between'],
            sy['p-4'],
            sy['gap-4'],
            pressableStyle,
          ]}
          disabled={!hasChildren}
          onPress={() => {
            const value = !expand;
            setExpand(value);
            if (onExpand) {
              onExpand(value);
            }
          }}>
          <View
            style={[
              sy['flex-row'],
              sy['flex-1'],
              sy['items-center'],
              sy['gap-8'],
            ]}>
            {icon && <View>{icon}</View>}
            <Text style={[sy.mediumPlus, sy.truncate]}>{header}</Text>
          </View>
          {hasChildren ? (
            !options.switchChevrons ? (
              expand ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )
            ) : expand ? (
              <ExpandMoreIcon />
            ) : (
              <ExpandLessIcon />
            )
          ) : (
            <></>
          )}
        </Pressable>
        {expand && <Divider />}
        {expand && <>{children}</>}
      </View>
    </>
  );
};

export default Grouping;
