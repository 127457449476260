import React, {useCallback, useState, useEffect, useRef} from 'react';
import {View} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';

import sy from '~/styles';
import {service_request as serviceRequestApi} from '~/api/private';
import {Dialog, FormTextInput, Text, Link} from '~/components/controls';
import Format from '~/lib/format';
import {useDispatch, useSelector} from '~/lib/hooks';
import {hideClientMessagesDialog, serviceRequestUpdate} from '~/actions';

import TextsmsIcon from '~/images/md-icons/textsms/materialicons/24px.svg';
import SmsFailedIcon from '~/images/md-icons/sms_failed/materialicons/24px.svg';
import EmailIcon from '~/images/md-icons/email/materialicons/24px.svg';

/* eslint-disable no-control-regex */
const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
const phoneRegex = /^06[0-9]{8}/gi;

const StatusLink = ({reference_id, status, onSend}) => {
  switch (status) {
    case 'Delivered':
      return (
        <Text style={[sy.smallRegular, sy['text-lightgray']]}>Verzonden</Text>
      );

    case 'SentFailed':
      return (
        <Text style={[sy.smallRegular, {color: '#972727'}]}>
          Verzenden mislukt
        </Text>
      );

    default:
      return (
        <Link
          disabled={!!reference_id}
          textStyle={[sy.smallRegular, sy['no-underline'], {color: '#2907E3'}]}
          onPress={onSend}>
          Verzenden
        </Link>
      );
  }
};

const EditSmsDialog = ({reference_id, number, text, onDismiss, onChange}) => {
  const [smsNumber, setSmsNumber] = useState(number);
  const [smsText, setSmsText] = useState(text);
  const [phoneError, setPhoneError] = useState(false);

  return (
    <Dialog
      visible={true}
      title="SMS auto gereed"
      titleIcon={<TextsmsIcon fill="#4a4a49" />}
      onDismiss={onDismiss}
      buttons={[
        {
          text: 'Annuleren',
          onPress: onDismiss,
        },
        {
          text: 'OK',
          disabled: phoneError,
          onPress: () => {
            if (phoneError) {
              return;
            }

            onChange(smsNumber, smsText);
            onDismiss();
          },
        },
      ]}
      options={{
        noPaddingContent: true,
      }}>
      <View style={[sy['p-4'], sy['gap-4']]}>
        <div data-private>
          <FormTextInput
            value={smsNumber}
            onChangeText={setSmsNumber}
            onEndEditing={() => {
              const valid = !smsNumber || !!smsNumber.match(phoneRegex);
              setPhoneError(!valid);
            }}
            label="Mobiele telefoonnummer"
            hasError={phoneError}
            errorMessage={
              !smsNumber?.length
                ? 'Mobiele telefoonnummer is verplicht'
                : !smsNumber?.match(phoneRegex)
                ? 'Ongeldige waarde mobiele telefoonnummer'
                : null
            }
            inputProps={{
              maxLength: 10,
              keyboardType: 'numeric',
            }}
          />
        </div>
        <View style={[sy['gap-1']]}>
          <FormTextInput
            label="Tekst"
            value={smsText}
            disabled={!!reference_id}
            multiline={true}
            onChangeText={(text) => setSmsText(text.substring(0, 160))}
            inputProps={{
              multiline: true,
              maxLength: 160,
              numberOfLines: 3,
            }}
          />
          <Text style={[sy.smallRegular, sy['text-lightgray'], sy['pl-2']]}>
            {smsText.length} / 160
          </Text>
        </View>
      </View>
    </Dialog>
  );
};

const EditEmailDialog = ({email, body, onDismiss, onChange}) => {
  const [emailAddress, setEmailAddress] = useState(email);
  const [emailError, setEmailError] = useState(false);

  return (
    <Dialog
      visible={true}
      title="Email werkzaamheden"
      titleIcon={<EmailIcon fill="#4a4a49" />}
      onDismiss={onDismiss}
      buttons={[
        {
          text: 'Annuleren',
          onPress: onDismiss,
        },
        {
          text: 'OK',
          disabled: emailError,
          onPress: () => {
            if (emailError) {
              return;
            }

            onChange(emailAddress);
            onDismiss();
          },
        },
      ]}
      options={{
        noPaddingContent: true,
      }}>
      <View style={[sy['p-4'], sy['gap-4']]}>
        <div data-private>
          <FormTextInput
            label="E-mail"
            value={emailAddress}
            onChangeText={setEmailAddress}
            onEndEditing={() => {
              const valid = !emailAddress || !!emailAddress.match(emailRegex);
              setEmailError(!valid);
              if (valid) {
                setEmailAddress(emailAddress);
              }
            }}
            hasError={emailError}
            errorMessage="Email heeft een ongeldige waarde"
            inputProps={{
              keyboardType: 'email-address',
            }}
          />
        </div>
        <FormTextInput
          label="Tekst"
          value={body}
          disabled={true}
          multiline={true}
          inputProps={{
            multiline: true,
            numberOfLines: 9,
          }}
        />
      </View>
    </Dialog>
  );
};

const ClientMessagesDialog = () => {
  const dispatch = useDispatch();

  const {visible, request_id} =
    useSelector((state) => state.client_messages_dialog) ?? {};

  const [showEditSms, setShowEditSms] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [smsText, setSmsText] = useState(null);
  const [smsNumber, setSmsNumber] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);
  const [emailText, setEmailText] = useState(null);
  const [references, setReferences] = useState(null);
  const [statuses, setStatuses] = useState(null);

  const timerID = useRef(null);

  const onDismiss = useCallback(() => {
    dispatch(hideClientMessagesDialog());
  }, [dispatch]);

  useEffect(() => {
    const fetch = async () => {
      const {result} = await serviceRequestApi.get(request_id, dispatch);
      const {phone_number, email, license, company_name, email_enabled} =
        result[0];

      const approved_tasks = result[0].tasks
        .map((task) => {
          if (
            (result[0].fleet_request ?? result[0].rob)?.components[task.id]
              ?.status?.code === 'Approved'
          ) {
            return task.description;
          }

          return null;
        })
        .filter(Boolean);

      setSmsNumber(phone_number);
      setSmsText(
        `De werkzaamheden aan de ${Format.license(
          license,
        )} zijn gereed. Mvg ${company_name}`,
      );

      if (email_enabled) {
        setEmailAddress(email);
        setEmailText(
          `De werkzaamheden aan de ${Format.license(
            license,
          )} zijn gereed. Dit is er uitgevoerd:

      ${approved_tasks.join('\n')}

Graag tot ziens bij ${company_name}!`,
        );
      }
    };

    if (visible) {
      fetch();
    } else {
      if (timerID.current) {
        clearInterval(timerID.current);
      }

      setShowEditSms(false);
      setReferences(null);
      setStatuses(null);
    }
  }, [visible]);

  useEffect(() => {
    if (references) {
      if (timerID.current) {
        clearInterval(timerID.current);
      }

      timerID.current = setInterval(() => {
        serviceRequestApi
          .client_message_statuses(request_id, references)
          .then(({result: statuses}) =>
            setStatuses((prevState) => {
              if (!_.isEqual(prevState, statuses)) {
                requestAnimationFrame(() =>
                  dispatch(
                    serviceRequestUpdate({
                      request_id,
                    }),
                  ),
                );
              }

              return statuses;
            }),
          );
      }, 2000);
    }

    return () => {
      if (timerID.current) {
        clearInterval(timerID.current);
      }
    };
  }, [request_id, references]);

  if (showEditSms) {
    return (
      <EditSmsDialog
        reference_id={references?.sms}
        number={smsNumber}
        text={smsText}
        onDismiss={() => setShowEditSms(false)}
        onChange={(number, text) => {
          setSmsNumber(number);
          setSmsText(text);
        }}
      />
    );
  }

  if (showEditEmail) {
    return (
      <EditEmailDialog
        email={emailAddress}
        body={emailText}
        onDismiss={() => setShowEditEmail(false)}
        onChange={setEmailAddress}
      />
    );
  }

  return (
    <Dialog
      visible={visible}
      title="Berichten naar berijder"
      onDismiss={onDismiss}
      buttons={[
        {
          text: 'Sluiten',
          onPress: onDismiss,
        },
      ]}
      options={{
        noPaddingContent: true,
      }}>
      <View>
        {smsNumber?.length > 0 && (
          <View style={[sy['p-4'], sy['flex-row'], sy['gap-8']]}>
            {statuses?.sms === 'SentFailed' ? (
              <SmsFailedIcon fill="#972727" />
            ) : (
              <TextsmsIcon fill="#4a4a49" />
            )}
            <View>
              <Text>SMS auto gereed</Text>
              <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                Kosten {Format.price(0.09)}
              </Text>
              <View style={sy['flex-row']}>
                <StatusLink
                  reference_id={references?.sms}
                  status={statuses?.sms}
                  onSend={async () => {
                    const {reference_id} = await serviceRequestApi.sms(
                      request_id,
                      smsNumber,
                      smsText,
                    );

                    setReferences({
                      ...references,
                      sms: reference_id,
                    });
                  }}
                />
                <Text>&nbsp;&middot;&nbsp;</Text>
                <Link
                  textStyle={[
                    sy.smallRegular,
                    sy['no-underline'],
                    {color: '#2907E3'},
                  ]}
                  onPress={() => setShowEditSms(true)}>
                  {references?.sms ? 'Bekijken' : 'Bewerken'}
                </Link>
              </View>
            </View>
          </View>
        )}
        {emailAddress?.length > 0 && (
          <>
            {smsNumber?.length > 0 && <Divider />}
            <View style={[sy['p-4'], sy['flex-row'], sy['gap-8']]}>
              <EmailIcon fill="#4a4a49" />
              <View>
                <Text>Email werkzaamheden</Text>
                <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                  Gratis
                </Text>
                <View style={sy['flex-row']}>
                  <StatusLink
                    reference_id={references?.email}
                    status={references?.email ? 'Delivered' : null}
                    onSend={async () => {
                      const {reference_id} = await serviceRequestApi.email(
                        request_id,
                        emailAddress,
                      );

                      setReferences({
                        ...references,
                        email: reference_id,
                      });
                    }}
                  />
                  <Text>&nbsp;&middot;&nbsp;</Text>
                  <Link
                    textStyle={[
                      sy.smallRegular,
                      sy['no-underline'],
                      {color: '#2907E3'},
                    ]}
                    onPress={() => setShowEditEmail(true)}>
                    {references?.email ? 'Bekijken' : 'Bewerken'}
                  </Link>
                </View>
              </View>
            </View>
          </>
        )}
      </View>
    </Dialog>
  );
};

export default ClientMessagesDialog;
