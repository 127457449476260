import React, {useState, useRef} from 'react';
import {View, ScrollView} from 'react-native';
import _ from 'lodash';

import FormTextInput from '../FormTextInput';
import styles from './styles';
import {Menu, TouchableRipple, TextInput} from 'react-native-paper';

const Select = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });
  const menuRef = useRef(null);

  const onLayout = (event) => {
    setInputLayout(event.nativeEvent.layout);
  };

  const onOptionPress = (option) => {
    setIsOpen(false);

    if (props.onChange) {
      props.onChange(option);
    }
  };

  const selectedValue = _.isString(props.value)
    ? props.value
    : props.value?.value;

  const displayValue =
    props.options.find(
      (item) => item === selectedValue || item.value === selectedValue,
    )?.text || selectedValue;

  return (
    <View style={[styles.root, props.style]}>
      <Menu
        ref={menuRef}
        visible={isOpen}
        onDismiss={() => setIsOpen(false)}
        anchor={
          <TouchableRipple
            disabled={props.disabled}
            onPress={() => {
              setIsOpen(!isOpen);
              requestAnimationFrame(menuRef.current.show);
            }}
            onLayout={onLayout}>
            <FormTextInput
              disabled={props.disabled}
              value={displayValue || props.defaultText}
              label={props.label}
              style={{backgroundColor: 'rgb(240, 240, 240)'}}
              inputProps={{
                pointerEvents: 'none',
                left: props.icon && (
                  <TextInput.Icon icon={() => <>{props.icon}</>} />
                ),
                right: (
                  <TextInput.Icon
                    style={{backgroundColor: 'rgb(240, 240, 240)'}}
                    disabled={props.disabled}
                    icon="menu-down"
                    forceTextInputFocus={false}
                    onPress={() => {
                      setIsOpen(!isOpen);
                      requestAnimationFrame(menuRef.current.show);
                    }}
                  />
                ),
              }}
            />
          </TouchableRipple>
        }
        style={{
          maxWidth: inputLayout?.width,
          width: inputLayout?.width,
        }}>
        <ScrollView style={{maxHeight: 200}}>
          {props.options.map((option, index) => {
            const optionValue = _.isString(option) ? option : option.value;
            let optionText = _.isString(option) ? option : option.text;
            if (props.itemRenderer) {
              optionText = props.itemRenderer(option);
            }

            return (
              <Menu.Item
                contentStyle={{maxWidth: 350}}
                key={index}
                selected={optionValue === selectedValue}
                title={optionText}
                onPress={() => onOptionPress(option)}
              />
            );
          })}
        </ScrollView>
      </Menu>
    </View>
  );
};

export default Select;
