import {StyleSheet} from 'react-native';
const styles = {
  root: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    height: 93,
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderBottomColor: '#DCDCDC',
    borderBottomWidth: 1,
  },
};

export default StyleSheet.create(styles);
