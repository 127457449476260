import React, {useState, useEffect} from 'react';
import {FlatList} from 'react-native';
import moment from 'moment';

import sy from '~/styles';
import CalendarMonth from './Month';

const CELL_WIDTH = 45;

const Calendar = ({style, months, initialDate, date, minDate, onPress}) => {
  const [heights, setHeights] = useState([]);

  useEffect(() => {
    let month_heights = [];
    for (let index of months) {
      const month = moment(initialDate).add(index, 'month');
      const start = moment(month).startOf('month');
      const end = start.clone().endOf('month');
      const weeks = end.diff(start.clone().startOf('week'), 'week');

      const month_label_height = CELL_WIDTH;
      const height = month_label_height + (weeks + 1) * CELL_WIDTH;

      month_heights.push(height);
    }

    setHeights(month_heights);
  }, [initialDate, months]);

  if (heights.length > 0) {
    return (
      <FlatList
        style={[sy['pl-4'], {maxHeight: 400}, style]}
        data={months}
        renderItem={({item}) => (
          <CalendarMonth
            cellWidth={CELL_WIDTH}
            month={moment(initialDate).add(item, 'month')}
            date={date}
            minDate={minDate}
            onPress={onPress}
          />
        )}
        keyExtractor={(item) => item}
        initialScrollIndex={months.indexOf(0)}
        getItemLayout={(data, index) => {
          const length = heights[index];
          let offset = 0;
          for (let i = 0; i < index; i++) {
            offset += heights[i];
          }

          return {
            length,
            offset,
            index,
          };
        }}
      />
    );
  }

  return null;
};

export default React.memo(Calendar);
