export const REPLACEMENT_TYPE = {
  none: 'none',
  wait: 'wait',
  message: 'message',
  bike: 'bike',
  transport: 'transport',
  car: 'car',
};

export const REPLACEMENT_REASON = {
  REPAIRS: 'repairs',
  DAMAGE: 'damage',
};

export const REPLACEMENT_REASON_TITLE = {
  [REPLACEMENT_REASON.REPAIRS]: 'Reparatie & onderhoud',
  [REPLACEMENT_REASON.DAMAGE]: 'Schade',
};

export const FLEET_REPLACEMENT_REASON = {
  REPAIRS: 'repairs',
  DAMAGE: 'damage',
  WARRANTY: 'warranty',
};

export const FLEET_REPLACEMENT_REASON_TITLE = {
  [FLEET_REPLACEMENT_REASON.REPAIRS]: 'Reparatie & onderhoud',
  [FLEET_REPLACEMENT_REASON.DAMAGE]: 'Schade',
  [FLEET_REPLACEMENT_REASON.WARRANTY]: 'Garantie',
};

export const REPLACEMENT_TITLE = 'Vervangend vervoer';

export const ROB_REPLACEMENT_CODE = '9101';
export const ROB_REPLACEMENT_RENTAL_CHARGE_CODE = '9150';
export const ROB_REPLACEMENT_KM_SURCHARGE_CODE = '9154';
export const ROB_REPLACEMENT_FREE_KM_CODE = '9112';

export const ROB_REPLACEMENT_STAGE = {
  reservation: 'reservation',
  checkout: 'checkout',
  extension: 'extension',
  checkin: 'checkin',
};

export const ROB_REPLACEMENT_STAGE_ORDER = [
  ROB_REPLACEMENT_STAGE.reservation,
  ROB_REPLACEMENT_STAGE.checkout,
  ROB_REPLACEMENT_STAGE.extension,
  ROB_REPLACEMENT_STAGE.checkin,
];

export const ROB_REPLACEMENT_STAGE_TITLE = {
  [ROB_REPLACEMENT_STAGE.reservation]: 'Reservering',
  [ROB_REPLACEMENT_STAGE.checkout]: 'Uitgifte',
  [ROB_REPLACEMENT_STAGE.extension]: 'Verlenging',
  [ROB_REPLACEMENT_STAGE.checkin]: 'Afmelding',
};
