const flexbox = {
  'flex-row': {
    flexDirection: 'row',
  },
  'flex-row-reverse': {
    flexDirection: 'row-reverse',
  },
  'flex-col': {
    flexDirection: 'column',
  },
  'flex-col-reverse': {
    flexDirection: 'column-reverse',
  },
  'flex-1': {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '0%',
  },
  'flex-auto': {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
  },
  'flex-initial': {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: '0%',
  },
  'flex-none': {
    flex: 'none',
  },
  'flex-wrap': {
    flexWrap: 'wrap',
  },
  'shrink-0': {
    flexShrink: 0,
  },
  'justify-start': {
    justifyContent: 'flex-start',
  },
  'justify-end': {
    justifyContent: 'flex-end',
  },
  'justify-center': {
    justifyContent: 'center',
  },
  'justify-between': {
    justifyContent: 'space-between',
  },
  'justify-around': {
    justifyContent: 'space-around',
  },
  'justify-evenly': {
    justifyContent: 'space-evenly',
  },
  'items-start': {
    alignItems: 'flex-start',
  },
  'items-end': {
    alignItems: 'flex-end',
  },
  'items-center': {
    alignItems: 'center',
  },
  'items-baseline': {
    alignItems: 'baseline',
  },
  'items-stretch': {
    alignItems: 'stretch',
  },
  'self-center': {
    alignSelf: 'center',
  },
  'self-start': {
    alignSelf: 'flex-start',
  },
  'self-end': {
    alignSelf: 'flex-end',
  },
  'gap-0': {
    gap: 0,
  },
  'gap-1': {
    gap: 4,
  },
  'gap-2': {
    gap: 8,
  },
  'gap-3': {
    gap: 12,
  },
  'gap-4': {
    gap: 16,
  },
  'gap-5': {
    gap: 20,
  },
  'gap-6': {
    gap: 24,
  },
  'gap-8': {
    gap: 32,
  },
  'gap-10': {
    gap: 40,
  },
  'gap-12': {
    gap: 48,
  },
};

export default flexbox;
