import {StyleSheet} from 'react-native';
export default StyleSheet.create({
  root: {},
  selection: {
    borderTopRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#f2f2f2',
    paddingTop: 16,
    paddingBottom: 7,
    paddingHorizontal: 16,
    height: 48,
    fontFamily: 'Roboto_400Regular',
  },
  option: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomColor: '#c4c4c4',
    borderBottomWidth: 1,
  },
  last: {
    borderBottomWidth: 0,
  },
});
