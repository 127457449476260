import React, {useState} from 'react';
import {View} from 'react-native';
import moment from 'moment';

import styles from './styles';
import {Text, Pressable} from '~/components/controls';

const Slot = ({time, checked, onPress}) => (
  <Pressable onPress={() => onPress(time)}>
    <Text style={[styles.slot, styles.available, checked && styles.checked]}>
      {time.format('H:mm')}
    </Text>
  </Pressable>
);

const Time = (props) => {
  const {begin_checkin, end_checkin, onPress} = props;
  const [date, setDate] = useState(props.datetime);

  const onSlotChange = (time) => {
    const change = moment(date).utc();
    change.hours(time.hours());
    change.minutes(time.minutes());
    setDate(change);

    onPress(change);
  };

  const frames = [];
  let time = moment(begin_checkin, 'HH:mm:ss');
  const end = moment(end_checkin, 'HH:mm:ss');
  let count = 0;
  while (time.isSameOrBefore(end, 'minutes')) {
    const index = Math.floor(count / 4);
    if (!frames[index]) {
      frames[index] = [null, null, null, null];
    }

    const checked = moment(date).utc().format('HH:mm') === time.format('HH:mm');
    const slot = {
      time: time.clone(),
      checked,
    };

    frames[index][count % 4] = slot;

    time = time.add(30, 'minutes');
    count++;
  }

  return (
    <View style={{flex: 1, paddingVertical: 25}}>
      {frames.map((slots, index) => (
        <View key={index} style={styles.frame}>
          {slots.map((slot, index) => (
            <View key={index}>
              {slot ? (
                <Slot {...slot} onPress={onSlotChange} />
              ) : (
                <View style={styles.slot} />
              )}
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

export default Time;
