import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';

const kvk = {
  search: async (prefix) => {
    const result = await fetch(`${endpoints.kvk}/search`, {
      method: 'POST',
      body: JSON.stringify({
        prefix,
      }),
    });

    if (!result) {
      return {success: false, result: null};
    }

    const json = await result.json();
    return json;
  },
};

export default kvk;
