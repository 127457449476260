export default {
  'bg-inherit': {
    backgroundColor: 'inherit',
  },
  'bg-current': {
    backgroundColor: 'currentColor',
  },
  'bg-transparent': {
    backgroundColor: 'transparent',
  },
  'bg-white': {
    backgroundColor: '#FFFFFF',
  },
  'bg-gray95': {
    backgroundColor: '#F2F2F2',
  },
};
