import React, {useState} from 'react';
import {View} from 'react-native';
import _ from 'lodash';

import sy from '~/styles';
import {dealer as dealerApi} from '~/api/private';

import {Dialog, Text, Checkbox, Pressable} from '~/components/controls';

import MailIcon from '~/images/md-icons/mail/materialsymbolsoutlined/24px.svg';
import SmsIcon from '~/images/md-icons/sms/materialsymbolsoutlined/24px.svg';

const DealerRequestDialog = ({
  visible,
  request_id,
  phone_number,
  email,
  onDismiss,
  onRefresh,
}) => {
  const [smsChecked, setSmsChecked] = useState(false);

  return (
    <Dialog
      visible={visible}
      title="Voorstellen"
      onDismiss={onDismiss}
      buttons={[
        {
          text: 'Annuleren',
          onPress: onDismiss,
        },
        {
          text: 'Verzenden',
          onPress: async () => {
            const {success} = await dealerApi.request(
              request_id,
              smsChecked ? phone_number : null,
            );
            if (success) {
              onRefresh();
              onDismiss();
            }
          },
        },
      ]}
      options={{
        noPaddingContent: true,
      }}>
      <View>
        <View style={[sy['p-4'], sy['flex-row'], sy['gap-4']]}>
          <Checkbox checked={true} disabled={true} />
          <View style={[sy['flex-1']]}>
            <Text>E-mail</Text>
            <Text style={[sy.smallRegular, sy['text-lightgray']]}>{email}</Text>
          </View>
          <MailIcon fill="#4a4a49" />
        </View>
        {phone_number?.length > 0 && (
          <Pressable
            style={[sy['p-4'], sy['flex-row'], sy['gap-4'], sy['items-center']]}
            onPress={() => {
              setSmsChecked((prev) => !prev);
            }}>
            <Checkbox checked={smsChecked} disabledTimeout={0} />
            <View style={[sy['flex-1']]}>
              <Text>SMS</Text>
              <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                {phone_number}
              </Text>
            </View>
            <SmsIcon fill="#4a4a49" />
          </Pressable>
        )}
      </View>
    </Dialog>
  );
};

export default DealerRequestDialog;
