import React, {useState, useEffect} from 'react';

import {Text} from '~/components/controls';

const CountDownText = ({timeout, style, onTimeout}) => {
  const [seconds, setSeconds] = useState(timeout);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    if (onTimeout && seconds <= 0) {
      onTimeout();
    }
  }, [onTimeout, seconds]);

  return (
    <Text style={style}>
      {seconds} seconde{seconds === 1 ? '' : 'n'}
    </Text>
  );
};

export default CountDownText;
