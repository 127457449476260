import React, {useState, useCallback, useEffect} from 'react';
import {View, Platform, ScrollView} from 'react-native';
import {
  Gesture,
  GestureDetector,
  Directions,
  PointerType,
} from 'react-native-gesture-handler';
import {Divider} from 'react-native-paper';
import moment from 'moment';

import sy from '~/styles';
import {
  Dialog,
  Text,
  Select,
  FormNumberInput,
  FormTextInput,
  Link,
  PrimaryButton,
} from '~/components/controls';
import {
  service_request as serviceRequestApi,
  car as carApi,
} from '~/api/private';
import {useDispatch} from '~/lib/hooks';
import {ROB_READY_CODE_TEXTS, isRobTaskReady} from '~/types/rob';
import {STATUS_CODES, STATUS_DESCRIPTIONS} from '~/types/statuses';
import config from '~/configuration';
import {REQUEST_TYPES} from '~/types/request';
import withDimensions from '~/components/hoc/with-dimensions';

const SLA_LESSORS = [
  'AA Lease',
  'Alcredis Lease',
  'Alg Leasemij. Autoplanning',
  'Alphabet',
  'Arval BV',
  'Athlon Car Lease Nederland',
  'Autolease Midden Nederland BV',
  'Ayvens (vh ALD)',
  'Ayvens (vh ALD overig.labels)',
  'Ayvens (vh LeasePlan Ned. NV)',
  'Bahnkick LM',
  'Broekhuis Lease B.V.',
  'De Lease Maatschappij B.V.',
  'Driessen Autolease BV',
  'Hiltermann Lease B.V.',
  'MHC Mobility',
  'MisterGreen Lease B.V.',
  'MisterGreen Lease II B.V.',
  'MKB Lease B.V.',
  'Mobility Service Nederland BV',
  'Multilease B.V.',
  'Oostendorp Autolease',
  'Prolease B.V. ',
  'Rebel Lease B.V.',
  'Riemersma Leasing B.V.',
  'Superlease Holland BV',
  'Terberg Business Lease Group',
  'Wagenplan B.V.',
  'Wittebrug Lease',
];

const DIALOG_HEADER_IN_PIXELS = 65;
const DIALOG_FOOTER_IN_PIXELS = 51;

let flingTimer;

// eslint-disable-next-line import/no-anonymous-default-export
export default (Screen, requestIdExtractor) => {
  return withDimensions((props) => {
    const {navigation, dimensions} = props;
    const dispatch = useDispatch();

    const request_id = requestIdExtractor(props);
    const [showMobileDialog, setShowMobileDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showInfoDialog, setShowInfoDialog] = useState(null);
    const [showTiresDialog, setShowTiresDialog] = useState(null);
    const [carTypeId, setCarTypeId] = useState({});
    const [rdcVin, setRdcVin] = useState(null);
    const [carName, setCarName] = useState(null);
    const [fuelType, setFuelType] = useState(null);
    const [currentSlaSet, setCurrentSlaSet] = useState(null);
    const [slaSets, setSlaSets] = useState(null);

    const [startFlings, setStartFlings] = useState(null);
    const [upFlings, setUpFlings] = useState(0);
    const [downFlings, setDownFlings] = useState(0);
    const [rightFlings, setRightFlings] = useState(0);

    const resetFlings = () => {
      setUpFlings(0);
      setDownFlings(0);
      setRightFlings(0);
      setStartFlings(null);
      if (flingTimer) {
        clearTimeout(flingTimer);
        flingTimer = null;
      }
    };

    const upFlingGesture = Gesture.Fling()
      .direction(Directions.UP)
      .onStart((e) => {
        if (e.pointerType === PointerType.TOUCH) {
          if (startFlings === null) {
            setStartFlings(new Date().getTime());
          }

          setUpFlings((prev) => (prev += 1));
        } else {
          resetFlings();
        }
      });

    const downFlingGesture = Gesture.Fling()
      .direction(Directions.DOWN)
      .onStart((e) => {
        if (e.pointerType === PointerType.TOUCH) {
          setDownFlings((prev) => (prev += 1));
        } else {
          resetFlings();
        }
      });

    const rightFlingGesture = Gesture.Fling()
      .direction(Directions.RIGHT)
      .onStart((e) => {
        if (e.pointerType === PointerType.TOUCH) {
          setRightFlings((prev) => (prev += 1));
        } else {
          resetFlings();
        }
      });

    const leftFlingGesture = Gesture.Fling()
      .direction(Directions.LEFT)
      .onStart((e) => {
        if (e.pointerType === PointerType.TOUCH) {
          resetFlings();
        }
      });

    const composed = Gesture.Race(
      upFlingGesture,
      downFlingGesture,
      rightFlingGesture,
      leftFlingGesture,
    );

    const onTires = async () => {
      const {result} = await serviceRequestApi.get(request_id, dispatch);

      const tires_component = result[0].components.find(
        (item) => item.name === 'Banden',
      );

      setShowTiresDialog(tires_component ?? {});
    };

    const onInfo = async () => {
      const {result} = await serviceRequestApi.get(request_id, dispatch);

      const {result: car} = await carApi.type_id(result[0].license);

      const {result: dealer_sla_sets} = await serviceRequestApi.dealer_sla_sets(
        request_id,
      );

      setCurrentSlaSet(result[0].dealer_sla_set_id);
      setSlaSets(dealer_sla_sets);

      setShowInfoDialog(result[0]);
      setCarTypeId(car.carId);
      setRdcVin(car.rdc_vin);
      setCarName(car.name);
      setFuelType(car.fuel_type);
    };

    const keyListener = useCallback(
      async (e) => {
        if (request_id) {
          switch (e.keyCode) {
            case 66: // B
              if (e.ctrlKey && e.shiftKey) {
                onTires();
              }
              break;
            case 68: // D
              if (e.ctrlKey && e.shiftKey) {
                setShowDeleteDialog(true);
              }
              break;
            case 73: // I
              if (e.ctrlKey && e.shiftKey) {
                onInfo();
              }
              break;
            default:
              break;
          }
        }
      },
      [request_id, dispatch],
    );

    useEffect(() => {
      console.log('Flings', startFlings, upFlings, downFlings, rightFlings);

      if (
        upFlings === 3 &&
        downFlings === 3 &&
        rightFlings === 3 &&
        moment().isBefore(moment(startFlings).add(5, 'seconds'))
      ) {
        setShowMobileDialog(true);
        resetFlings();
      }
    }, [startFlings, upFlings, downFlings, rightFlings, onInfo, onTires]);

    useEffect(() => {
      if (startFlings) {
        flingTimer = setTimeout(resetFlings, 10000);
      }

      return () => {
        clearTimeout(flingTimer);
      };
    }, [startFlings]);

    useEffect(() => {
      if (Platform.OS === 'web') {
        document.addEventListener('keydown', keyListener);
      }
    }, [keyListener]);

    return (
      <GestureDetector gesture={composed} userSelect="auto" enableContextMenu>
        <>
          <Screen {...props} />
          {showMobileDialog && (
            <Dialog
              visible={true}
              title="Debug"
              onDismiss={() => setShowMobileDialog(false)}>
              <View style={[sy['p-4'], sy['gap-4']]}>
                <View style={[sy['flex-row'], sy['gap-4'], sy['items-center']]}>
                  <Text>Debug</Text>
                  <PrimaryButton
                    onPress={() => {
                      onInfo();
                      setShowMobileDialog(false);
                    }}>
                    Tonen
                  </PrimaryButton>
                </View>
                <Divider />
                <View style={[sy['flex-row'], sy['gap-4'], sy['items-center']]}>
                  <Text>Banden</Text>
                  <PrimaryButton
                    onPress={() => {
                      onTires();
                      setShowMobileDialog(false);
                    }}>
                    Tonen
                  </PrimaryButton>
                </View>
              </View>
            </Dialog>
          )}
          {showDeleteDialog && (
            <Dialog
              visible={true}
              title="Verwijderen"
              onDismiss={() => setShowDeleteDialog(false)}
              buttons={[
                {
                  text: 'Annuleren',
                  onPress: () => setShowDeleteDialog(false),
                },
                {
                  text: 'OK',
                  onPress: async () => {
                    const result = await serviceRequestApi.delete(request_id);
                    if (result.success) {
                      navigation.replace('Tabs', {screen: 'Calendar'});
                    } else {
                      alert('Verwijderen mislukt');
                    }

                    setShowDeleteDialog(false);
                  },
                },
              ]}
              options={{noPaddingContent: true}}>
              <View style={[sy['flex-row']]}>
                <Text style={[sy['p-4']]}>
                  Weet je zeker dat je deze werkopdracht wilt verwijderen?
                </Text>
              </View>
            </Dialog>
          )}
          {showInfoDialog && (
            <Dialog
              visible={true}
              title="Debug info"
              onDismiss={() => setShowInfoDialog(null)}
              buttons={[
                {
                  text: 'OK',
                  onPress: async () => setShowInfoDialog(null),
                },
              ]}
              options={{noPaddingContent: true}}>
              <ScrollView
                contentContainerStyle={{
                  maxHeight:
                    dimensions.window.height -
                    DIALOG_HEADER_IN_PIXELS -
                    DIALOG_FOOTER_IN_PIXELS,
                }}>
                {!config.production && (
                  <View>
                    <Text style={[sy['p-4']]}>Leasemaatschappij</Text>

                    <Select
                      style={[sy['px-4']]}
                      value={showInfoDialog.lessor}
                      options={SLA_LESSORS}
                      onChange={async (lessor) => {
                        const {success} = await serviceRequestApi.change_lessor(
                          request_id,
                          lessor,
                        );

                        if (success) {
                          window.location.reload();
                        } else {
                          alert('Leasemaatschappij wijzigen mislukt');
                        }
                      }}
                    />
                  </View>
                )}
                <View>
                  <Text style={[sy['p-4']]}>Status</Text>
                  <Select
                    style={[sy['px-4']]}
                    value={showInfoDialog.status}
                    options={[
                      STATUS_CODES.Cancelled,
                      STATUS_CODES.Draft,
                      STATUS_CODES.Reserved,
                      STATUS_CODES.CheckIn,
                      STATUS_CODES.Done,
                    ].map((key) => ({
                      text: STATUS_DESCRIPTIONS[key],
                      value: key,
                    }))}
                    onChange={async ({value}) => {
                      const {success} = await serviceRequestApi.change_status(
                        request_id,
                        value,
                      );

                      if (success) {
                        window.location.reload();
                      } else {
                        alert('Status wijzigen mislukt');
                      }
                    }}
                  />
                </View>
                <View style={[sy['p-4']]}>
                  <View style={sy['gap-2']}>
                    <FormNumberInput
                      style={[sy['flex-1']]}
                      label="TecDoc ID"
                      value={carTypeId}
                      digits={0}
                      onChangeNumber={(value) => {
                        setCarTypeId(value);
                      }}
                    />
                    <FormTextInput
                      style={[sy['flex-1']]}
                      label="RDC VIN nummer"
                      value={rdcVin}
                      digits={0}
                      onChangeText={(value) => {
                        setRdcVin(value);
                      }}
                    />
                    <FormTextInput
                      style={[sy['flex-1']]}
                      label="Naam auto"
                      value={carName}
                      digits={0}
                      onChangeText={(value) => {
                        setCarName(value);
                      }}
                    />
                    <FormTextInput
                      style={[sy['flex-1']]}
                      label="Brandstoftype"
                      value={fuelType}
                      digits={0}
                      onChangeText={(value) => {
                        setFuelType(value);
                      }}
                    />
                  </View>
                  <View style={[sy['items-end']]}>
                    <Link
                      onPress={async () => {
                        await carApi.update_type_id(
                          showInfoDialog.license,
                          carTypeId,
                          rdcVin,
                          carName,
                          fuelType,
                        );
                      }}>
                      Opslaan
                    </Link>
                  </View>
                </View>
                <View>
                  <Text style={[sy['p-4']]}>SLA set</Text>
                  <Select
                    style={[sy['px-4']]}
                    value={currentSlaSet}
                    options={slaSets.map((item, index) => ({
                      text: `${item.start_date}${
                        index === 0 ? ' (laatste)' : ''
                      }`,
                      value: item.id,
                    }))}
                    onChange={async ({value}) => {
                      const {success} = await serviceRequestApi.update_sla_set(
                        request_id,
                        value,
                      );

                      if (success) {
                        window.location.reload();
                      } else {
                        alert('SLA set wijzigen mislukt');
                      }
                    }}
                  />
                </View>
                {showInfoDialog.components.map((component) => {
                  const main_task = component.tasks?.find(
                    (item) => item.is_main_task,
                  );

                  const amount = component.tasks?.reduce(
                    (acc, item) => (acc += item.task_amount),
                    0,
                  );

                  const rob_component =
                    showInfoDialog.rob?.components[main_task?.id];

                  const rob_code_component = Object.values(
                    showInfoDialog.rob?.components ?? {},
                  ).find((x) => x.rob?.code === main_task?.rob_code);

                  const status = isRobTaskReady(
                    main_task,
                    amount,
                    component,
                    rob_component,
                    showInfoDialog.request_type === REQUEST_TYPES.FLEET_REQUEST,
                  );

                  const text = ROB_READY_CODE_TEXTS[status];

                  return (
                    <View
                      key={component.id}
                      style={[sy['p-4'], sy['flex-row']]}>
                      <Text>
                        {main_task?.rob_code ?? component.name}:&nbsp;
                      </Text>
                      <View>
                        <FormTextInput
                          label="Component name"
                          value={component.component_name}
                          onChangeText={async (name) =>
                            await serviceRequestApi.update_component_name(
                              request_id,
                              component.id,
                              name,
                            )
                          }
                        />
                        {main_task && (
                          <FormTextInput
                            label="Main task id"
                            value={main_task?.id}
                            onChangeText={async (new_id) => {
                              if (new_id.length === 36) {
                                await serviceRequestApi.update_rob_id(
                                  request_id,
                                  main_task.id,
                                  new_id,
                                );
                              }
                            }}
                          />
                        )}
                        {rob_code_component && (
                          <FormTextInput
                            label="ROB id"
                            value={rob_code_component.id}
                            disabled={true}
                          />
                        )}
                        <Text style={sy.smallRegular}>{text}</Text>
                      </View>
                    </View>
                  );
                })}
              </ScrollView>
            </Dialog>
          )}
          {showTiresDialog && (
            <Dialog
              visible={true}
              title="Banden Debug info"
              onDismiss={() => setShowTiresDialog(null)}
              buttons={[
                {
                  text: 'OK',
                  onPress: () => window.location.reload(),
                },
              ]}
              options={{noPaddingContent: true}}>
              <>
                <ScrollView
                  contentContainerStyle={{
                    maxHeight:
                      dimensions.window.height -
                      DIALOG_HEADER_IN_PIXELS -
                      DIALOG_FOOTER_IN_PIXELS,
                  }}>
                  {showTiresDialog?.tasks?.map((task) => {
                    return (
                      <View key={task.id} style={[sy['gap-4'], sy['p-4']]}>
                        <Text style={sy.mediumPlus}>{task.rob_code}</Text>
                        {task.parts?.map((part) => (
                          <View key={part.id} style={[sy['gap-2']]}>
                            <Text>{part.location}</Text>
                            {part.price > 0 && (
                              <>
                                <FormNumberInput
                                  label="Prijs band"
                                  value={part.price}
                                  onChangeNumber={async (value) => {
                                    part.price = value;

                                    await serviceRequestApi.update_part(part);
                                  }}
                                />
                                <FormNumberInput
                                  label="Korting"
                                  value={part.discount}
                                  onChangeNumber={async (value) => {
                                    part.discount = value;

                                    await serviceRequestApi.update_part(part);
                                  }}
                                />
                              </>
                            )}
                            {Object.entries(part.service_cost ?? {}).map(
                              ([key, value]) => (
                                <FormNumberInput
                                  key={key}
                                  label={key}
                                  value={value}
                                  onChangeNumber={async (value) => {
                                    part.service_cost[key] = value;

                                    await serviceRequestApi.update_part(part);
                                  }}
                                />
                              ),
                            )}
                          </View>
                        ))}
                      </View>
                    );
                  })}
                </ScrollView>
              </>
            </Dialog>
          )}
        </>
      </GestureDetector>
    );
  });
};
