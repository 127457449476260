export const actionTypes = {
  INIT: 'INIT',
  ROUTE_CHANGE: 'ROUTE_CHANGE',
  APP_IS_LOADING: 'APP_IS_LOADING',
  APP_DONE_LOADING: 'APP_DONE_LOADING',
  SHOW_LOGOUT_DIALOG: 'SHOW_LOGOUT_DIALOG',
  HIDE_LOGOUT_DIALOG: 'HIDE_LOGOUT_DIALOG',
  SERVICE_REQUEST_INSERT: 'SERVICE_REQUEST_INSERT',
  SERVICE_REQUEST_UPDATE: 'SERVICE_REQUEST_UPDATE',
  SERVICE_REQUEST_LAST_FETCH: 'SERVICE_REQUEST_LAST_FETCH',
  SET_SHOW_DRAWER: 'SET_SHOW_DRAWER',
  SET_CALENDAR_CTX: 'SET_CALENDAR_CTX',
  SET_USER_SETTINGS: 'SET_USER_SETTINGS',
  SHOW_TECRMI_DIALOG: 'SHOW_TECRMI_DIALOG',
  HIDE_TECRMI_DIALOG: 'HIDE_TECRMI_DIALOG',
  SHOW_SETTINGS_MODAL: 'SHOW_SETTINGS_MODAL',
  SHOW_HELP_MODAL: 'SHOW_HELP_MODAL',
  UPDATE_IMPORTANT_MESSAGE: 'UPDATE_IMPORTANT_MESSAGE',
  SHOW_REFRESH_IMPORTANT_DIALOG: 'SHOW_REFRESH_IMPORTANT_DIALOG',
  HIDE_REFRESH_IMPORTANT_DIALOG: 'HIDE_REFRESH_IMPORTANT_DIALOG',
  SHOW_REFRESH_DIALOG: 'SHOW_REFRESH_DIALOG',
  HIDE_REFRESH_DIALOG: 'HIDE_REFRESH_DIALOG',
  UPDATE_AFTER: 'UPDATE_AFTER',
  CLIENT_MESSAGES_DIALOG: 'CLIENT_MESSAGES_DIALOG',
  SET_MESSAGES: 'SET_MESSAGES',
  FETCH_MESSAGES: 'FETCH_MESSAGES',
};

export const routeChange = (value) => {
  return {type: actionTypes.ROUTE_CHANGE, value};
};

export const serviceRequestInsert = () => {
  return {
    type: actionTypes.SERVICE_REQUEST_INSERT,
  };
};

export const serviceRequestUpdate = (value) => {
  return {
    type: actionTypes.SERVICE_REQUEST_UPDATE,
    value,
  };
};

export const serviceRequestLastFetch = (value) => {
  return {
    type: actionTypes.SERVICE_REQUEST_LAST_FETCH,
    value,
  };
};

export const setAppIsLoading = () => {
  return {
    type: actionTypes.APP_IS_LOADING,
  };
};

export const setAppDoneLoading = () => {
  return {
    type: actionTypes.APP_DONE_LOADING,
  };
};

export const showLogoutDialog = () => {
  return {
    type: actionTypes.SHOW_LOGOUT_DIALOG,
    value: true,
  };
};

export const hideLogoutDialog = () => {
  return {
    type: actionTypes.SHOW_LOGOUT_DIALOG,
    value: false,
  };
};

export const setShowDrawer = (value) => {
  return {type: actionTypes.SET_SHOW_DRAWER, value};
};

export const setCalendarCtx = (value) => {
  return {type: actionTypes.SET_CALENDAR_CTX, value};
};

export const showTecrmiDialog = (value) => {
  return {type: actionTypes.SHOW_TECRMI_DIALOG, value};
};

export const hideTecrmiDialog = () => {
  return {type: actionTypes.HIDE_TECRMI_DIALOG};
};

export const setUserSettings = (value) => {
  return {type: actionTypes.SET_USER_SETTINGS, value};
};

export const showSettingsModal = () => {
  return {type: actionTypes.SHOW_SETTINGS_MODAL, value: true};
};

export const hideSettingsModal = () => {
  return {type: actionTypes.SHOW_SETTINGS_MODAL, value: false};
};

export const showHelpModal = (value) => {
  return {type: actionTypes.SHOW_HELP_MODAL, value: true};
};

export const hideHelpModal = () => {
  return {type: actionTypes.SHOW_HELP_MODAL, value: false};
};

export const updateImportantMessage = () => {
  return {type: actionTypes.UPDATE_IMPORTANT_MESSAGE};
};

export const setUpdateAfter = () => {
  return {type: actionTypes.UPDATE_AFTER};
};

export const showRefreshImportantDialog = () => {
  return {type: actionTypes.SHOW_REFRESH_IMPORTANT_DIALOG};
};

export const hideRefreshImportantDialog = () => {
  return {type: actionTypes.HIDE_REFRESH_IMPORTANT_DIALOG};
};

export const showRefreshDialog = () => {
  return {type: actionTypes.SHOW_REFRESH_DIALOG};
};

export const hideRefreshDialog = () => {
  return {type: actionTypes.HIDE_REFRESH_DIALOG};
};

export const showClientMessagesDialog = (request_id, phone_number, email) => {
  return {
    type: actionTypes.CLIENT_MESSAGES_DIALOG,
    value: {visible: true, request_id, phone_number, email},
  };
};

export const hideClientMessagesDialog = () => {
  return {type: actionTypes.CLIENT_MESSAGES_DIALOG, value: {visible: false}};
};

export const setMessages = (value) => {
  return {type: actionTypes.SET_MESSAGES, value};
};

export const fetchMessages = () => {
  return {type: actionTypes.FETCH_MESSAGES};
};
