import React, {useState, useEffect} from 'react';
import {View} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';

import {Dialog, Text, Select, Pressable} from '~/components/controls';
import Format from '~/lib/format';

import sy from '~/styles';

import {service_request as serviceRequestApi} from '~/api/private';

import HandshakeIcon from '~/images/md-icons/handshake/materialicons/24px.svg';
import DeleteIcon from '~/images/md-icons/delete/materialicons/24px.svg';

const is_fluid = (gen_art_nr, attributes) => {
  if (attributes?.option_id) {
    return false;
  }

  switch (gen_art_nr) {
    case 71: // Remvloeistof
    case 1893: // Ruitenreiniging
    case 3224: // Motorolie
    case 3225: // Versnellingsbakolie
    case 3356: // Koelvloeistof
    case 5040: // Ureum
    case 8561: // Versnellingsbakolie
      return true;

    default:
      return false;
  }
};

export default ({
  request_id,
  sla_part,
  price,
  itemMpId,
  attributes,
  specifications: part_specifications,
  task_part_id,
  onRemove,
  onChange,
  onDismiss,
}) => {
  const [specifications, setSpecifications] = useState([]);
  const [oilSpecifications, setOilSpecifications] = useState([]);
  const [viscosities, setViscosities] = useState([]);
  const [selected, setSelected] = useState(part_specifications ?? {});

  useEffect(() => {
    const fetch = async () => {
      const {result} = await serviceRequestApi.part_specifications(
        request_id,
        itemMpId,
      );

      const {engine_oil} = result ?? {};
      setSpecifications(result);

      if (
        engine_oil &&
        engine_oil.every((item) => item.qualifierColumnText?.length > 0)
      ) {
        const specifications = engine_oil.map(
          (item) => item.qualifierColumnText,
        );

        setOilSpecifications(specifications);
        setSelected((prevState) => ({
          ...prevState,
          oil: {
            ...prevState.oil,
            specification:
              specifications.length === 1
                ? specifications[0]
                : prevState.oil?.specification,
          },
        }));
      }
    };

    fetch();
  }, [request_id, itemMpId]);

  useEffect(() => {
    if (selected.oil?.specification) {
      const viscosities = specifications.engine_oil
        ?.filter(
          (item) => item.qualifierColumnText === selected.oil.specification,
        )
        .flatMap((item) => item.text.split('|'));

      if (viscosities) {
        setSelected((prevState) => ({
          ...prevState,
          oil: {
            ...prevState.oil,
            viscosity:
              viscosities.length === 1
                ? viscosities[0]
                : viscosities.includes(prevState.oil?.viscosity)
                ? prevState.oil?.viscosity
                : null,
          },
        }));
        setViscosities(viscosities);
      }
    }
  }, [specifications, selected.oil?.specification]);

  return (
    <Dialog
      visible={true}
      title={sla_part.description}
      titleIcon={
        <Pressable onPress={onRemove}>
          <DeleteIcon fill="#4A4A49" />
        </Pressable>
      }
      onDismiss={onDismiss}
      buttons={[
        {text: 'Annuleren', onPress: onDismiss},
        {
          text: 'OK',
          onPress: async () => {
            if (selected) {
              await serviceRequestApi.update_part_specifications(
                request_id,
                itemMpId,
                task_part_id,
                selected,
              );

              onChange(selected);
            } else {
              onDismiss();
            }
          },
        },
      ]}
      options={{noPaddingContent: true}}>
      <View>
        {oilSpecifications.length > 0 && (
          <>
            <View style={[sy['p-4'], sy['gap-4']]}>
              <Select
                label="Specificatie"
                value={selected.oil?.specification}
                options={oilSpecifications}
                disabled={oilSpecifications.length < 2}
                onChange={(value) => {
                  setSelected((prevState) => ({
                    ...prevState,
                    oil: {
                      ...prevState.oil,
                      specification: value,
                    },
                  }));
                }}
              />
              <Select
                label="Viscositeit"
                value={selected.oil?.viscosity}
                options={viscosities}
                disabled={viscosities.length < 2}
                onChange={(value) => {
                  setSelected((prevState) => ({
                    ...prevState,
                    oil: {
                      ...prevState.oil,
                      viscosity: value,
                    },
                  }));
                }}
              />
            </View>
            <Divider />
          </>
        )}
        <View style={[sy['p-4'], sy['flex-row'], sy['gap-4']]}>
          <HandshakeIcon fill="#4A4A49" />
          <View>
            <Text>
              {Format.price(price ?? sla_part.price)}&nbsp;per&nbsp;
              {is_fluid(sla_part.gen_art_nr, attributes) ? 'liter' : 'stuk'}
            </Text>
            <Text style={[sy.smallRegular, sy['text-lightgray']]}>
              {sla_part.lessor}
            </Text>
          </View>
        </View>
      </View>
    </Dialog>
  );
};
