import React from 'react';
import {View} from 'react-native';
import {useNavigation} from '@react-navigation/native';

import {Pressable} from '~/components/controls';

import AddIcon from '~/images/md-icons/add/materialicons/24px.svg';

const FloatingAddButton = () => {
  const navigation = useNavigation();

  return (
    <View style={{position: 'relative', width: '100%', height: 0}}>
      <Pressable
        style={{
          width: 56,
          height: 56,
          position: 'absolute',
          bottom: 16,
          right: 20,
          backgroundColor: '#181716',
          borderRadius: 90,
          alignItems: 'center',
          justifyContent: 'center',
          shadowOffset: {width: 0, height: 3},
          shadowOpacity: 0.5,
          shadowColor: '#181716',
          shadowRadius: 10,
          elevation: 3,
        }}
        onPress={() => navigation.navigate('Appointment')}>
        <AddIcon fill="#ffffff" />
      </Pressable>
    </View>
  );
};

export default FloatingAddButton;
