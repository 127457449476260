import React, {useState} from 'react';
import {View} from 'react-native';
import moment from 'moment';
import _ from 'lodash';

import {Pressable, Dialog, Text} from '~/components/controls';
import Format from '~/lib/format';

import sy from '~/styles';

import WarningAmberIcon from '~/images/md-icons/warning_amber/materialicons/24px.svg';

const KpiWarning = ({style, kpi}) => {
  const [showDialog, setShowDialog] = useState(false);

  if (!kpi) {
    return <></>;
  }

  return (
    <>
      <Pressable
        style={style}
        onPress={() => setShowDialog((prevState) => !prevState)}>
        <WarningAmberIcon fill="#FF6C00" height={18} width={18} />
      </Pressable>
      <Dialog
        title={kpi.lessor ? 'Interval niet bereikt' : 'Al toe aan vervanging?'}
        titleIcon={<WarningAmberIcon fill="#FF6C00" />}
        visible={showDialog}
        onDismiss={() => setShowDialog(false)}>
        <View style={[sy['py-4']]}>
          {kpi.lessor && (
            <>
              <Text>
                De vervangingstermijn van
                <> {Format.number(kpi.condition.months, 0)} </>
                maanden
                {kpi.condition.kilometrage ? (
                  <>
                    {' '}
                    of {Format.number(
                      kpi.condition.kilometrage,
                      0,
                      true,
                    )} km{' '}
                  </>
                ) : (
                  <> </>
                )}
                is nog niet bereikt.
              </Text>
              <Text>&nbsp;</Text>
            </>
          )}
          {kpi.is_ascription ? (
            <Text>
              Eerste toelating op {moment(kpi.datetime).format('D MMMM YYYY')}
              {_.isNumber(kpi.kilometrage_ago) ? (
                <> ({Format.number(kpi.kilometrage_ago, 0, true)} km geleden)</>
              ) : (
                <></>
              )}
              .
            </Text>
          ) : (
            <Text>
              Op {moment(kpi.datetime).format('D MMMM YYYY')} vervangen
              {_.isNumber(kpi.kilometrage_ago) ? (
                <> ({Format.number(kpi.kilometrage_ago, 0, true)} km geleden)</>
              ) : (
                <></>
              )}
              .
            </Text>
          )}
        </View>
      </Dialog>
    </>
  );
};

export default KpiWarning;
