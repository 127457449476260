import React from 'react';

import styles from './styles';
import Pressable from '../Pressable';
import Text from '../Text';

const Link = ({style, disabled, textStyle, children, onPress}) => {
  if (disabled) {
    return <Text style={[styles.disabled, textStyle]}>{children}</Text>;
  }

  return (
    <Pressable allowBubbling style={style} onPress={onPress}>
      <Text style={[styles.link, textStyle]}>{children}</Text>
    </Pressable>
  );
};

export default Link;
