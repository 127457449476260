import React from 'react';

import {styles} from './styles';
import Text from '../Text';

const DownloadLink = ({href, filename, children}) => {
  return (
    <a href={href} style={styles.anchor} target="_blank" rel="noreferrer">
      <Text style={styles.link}>{children}</Text>
    </a>
  );
};

export default DownloadLink;
