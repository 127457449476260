import React from 'react';
import {View, Text} from 'react-native';

import sy from '~/styles';
import {Pressable} from '~/components/controls';
import Format from '~/lib/format';

export default ({quantity, onChange}) => (
  <Pressable style={[sy['pr-4']]} disabled={!onChange} onPress={onChange}>
    <View
      style={[
        sy['bg-gray95'],
        {
          width: 24,
          height: 24,
          borderRadius: 2,
          alignItems: 'center',
          justifyContent: 'center',
        },
      ]}>
      <Text style={sy.xSmallMedium}>
        {Format.number(quantity, 2, false, {
          minimumFractionDigits: 0,
        })}
      </Text>
    </View>
  </Pressable>
);
