import React, {useState, useRef, useCallback} from 'react';
import {View, ActivityIndicator, ScrollView, TextInput} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';

import sy from '~/styles';
import {
  Text,
  Checkbox,
  Pressable,
  Modal,
  PrimaryButton,
  Dialog,
} from '~/components/controls';

import CloseIcon from '~/images/md-icons/close/materialicons/24px.svg';
import SearchIcon from '~/images/md-icons/search/materialicons/24px.svg';

const HighlightText = ({text, highlight}) => {
  const position = text.toLowerCase().indexOf(highlight.toLowerCase());

  return (
    <>
      {position === -1 ? (
        <Text>{text}</Text>
      ) : (
        <>
          {position > 0 && <Text>{text.substring(0, position)}</Text>}
          <Text style={[{lineHeight: 16}, sy.mediumBold]}>
            {text.substring(position, position + highlight.length)}
          </Text>
          {position + highlight.length < text.length && (
            <Text>{text.substring(position + highlight.length)}</Text>
          )}
        </>
      )}
    </>
  );
};

const SearchRobCodeModal = ({codes, onSearch, onDismiss, onAdd}) => {
  const [selection, setSelection] = useState([]);
  const [results, setResults] = useState([]);
  const [highlighSearchText, setHighlightSearchText] = useState('');
  const [prefix, setPrefix] = useState('');
  const [searching, setSearching] = useState(false);
  const [showCancelAddDialog, setShowCancelAddDialog] = useState(false);

  const searchTextRef = useRef(null);

  const onClose = () => {
    if (selection.length) {
      setShowCancelAddDialog(true);
    } else {
      onDismiss();
    }
  };

  const onPrefixChange = useCallback(
    _.debounce(async (value) => {
      if (value.length < 1) {
        setResults([]);
        return;
      }

      setSearching(true);
      try {
        const result = await onSearch(value);

        if (searchTextRef.current?.value === value) {
          setResults(result);
        }
      } finally {
        setSearching(false);
      }
    }, 500),
    [],
  );

  return (
    <>
      <Modal visible={true} onDismiss={onDismiss}>
        <View
          style={[
            {height: 64},
            sy['flex-row'],
            sy['border-b'],
            sy['border-gray'],
            sy['items-center'],
          ]}>
          <Pressable style={[sy['p-4'], sy['pr-8']]} onPress={onClose}>
            <CloseIcon />
          </Pressable>

          <View style={[sy['flex-1'], sy['justify-center'], sy['mr-14']]}>
            <Text style={[sy['text-center']]}>ROB activiteit toevoegen</Text>
          </View>
        </View>
        <View
          style={[
            sy['flex-row'],
            sy['p-2'],
            sy['m-4'],
            sy['items-center'],
            {
              borderRadius: 10,
              backgroundColor: '#EBEBEB',
            },
          ]}>
          <SearchIcon
            fill={prefix.length ? '#4A4A49' : '#828282'}
            width={18}
            height={18}
          />
          <TextInput
            ref={(ref) => {
              searchTextRef.current = ref;
              ref?.focus();
            }}
            style={[
              sy.regular,
              sy['flex-1'],
              sy['ml-8'],
              {
                color: prefix?.length > 0 ? '#4A4A49' : '#828282',
              },
            ]}
            placeholder="Zoeken"
            value={prefix}
            onChangeText={(value) => {
              onPrefixChange.cancel();
              onPrefixChange(value);
              setHighlightSearchText(value);
              setPrefix(value);
            }}
          />
        </View>
        <Divider />
        <ScrollView style={sy['bg-gray95']}>
          {searching ? (
            <View style={[sy['p-4']]}>
              <ActivityIndicator size="large" color="#231fda" />
            </View>
          ) : (
            results.map((result, index, arr) => (
              <View style={sy['bg-white']} key={result.rob_code}>
                <View style={[sy['flex-row'], sy['p-4'], sy['gap-8']]}>
                  <Pressable
                    disabled={codes.includes(result.rob_code)}
                    onPress={() => {
                      if (
                        selection.some(
                          (item) => item.rob_code === result.rob_code,
                        )
                      ) {
                        setSelection(
                          selection.filter(
                            (item) => item.rob_code !== result.rob_code,
                          ),
                        );
                      } else {
                        setSelection([...new Set([...selection, result])]);
                      }
                    }}>
                    <Checkbox
                      style={{width: 'auto'}}
                      disabled={codes.includes(result.rob_code)}
                      checked={
                        selection.some(
                          (item) => item.rob_code === result.rob_code,
                        ) || codes.includes(result.rob_code)
                      }
                    />
                  </Pressable>
                  <View style={[sy['flex-1']]}>
                    <View style={{display: 'block'}}>
                      <HighlightText
                        text={`${result.rob_code} · ${result.description}`}
                        highlight={highlighSearchText}
                      />
                    </View>
                  </View>
                </View>
                {index < arr.length - 1 && (
                  <Divider style={[sy['bg-gray95'], sy['ml-18']]} />
                )}
              </View>
            ))
          )}
        </ScrollView>
        <Divider />
        <View style={[sy['p-4'], sy['flex-row'], sy['justify-end']]}>
          <PrimaryButton
            disabled={!selection?.length}
            onPress={() => onAdd(selection)}>
            Toevoegen
            {selection?.length > 0 ? <>&nbsp;({selection?.length})</> : <></>}
          </PrimaryButton>
        </View>
      </Modal>
      <Dialog
        visible={showCancelAddDialog}
        title="Selectie niet toevoegen?"
        onDismiss={() => setShowCancelAddDialog(false)}
        buttons={[
          {
            text: 'Annuleren',
            onPress: () => setShowCancelAddDialog(false),
          },
          {
            text: 'Niet toevoegen',
            onPress: onDismiss,
          },
        ]}>
        <View style={[sy['py-4']]}>
          <Text>
            Weet je zeker dat je dit venster wil sluiten zonder de selectie toe
            te voegen?
          </Text>
        </View>
      </Dialog>
    </>
  );
};

export default SearchRobCodeModal;
