import trace from './lib/trace';

import envSpecific from './env-configuration';
import appVersion from './app-version';

const config = {
  version: appVersion,
  fetchHandlerProbe: 'https://bahnkick-fetch-handler',
  ...envSpecific,
};

trace('Loaded configuration', config);

export default config;
