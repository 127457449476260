import React, {useContext, useEffect} from 'react';
import {useNavigation} from '@react-navigation/native';

import AuthContext from '../context/AuthContext';

const withProtectedStack = (StackScreen) => {
  return (props) => {
    const {authenticated} = useContext(AuthContext);
    const navigation = useNavigation();

    useEffect(() => {
      if (authenticated === null) {
        return;
      }

      if (!authenticated) {
        navigation.navigate('SignIn');
      }
    }, [authenticated, navigation]);

    return <StackScreen {...props} />;
  };
};

export default withProtectedStack;
