import React, {useState, useEffect, useCallback, useRef} from 'react';
import {View} from 'react-native';
import _ from 'lodash';

import CheckCircleIcon from '../../../images/md-icons/check_circle/materialicons/24px.svg';
import AddTaskIcon from '../../../images/md-icons/add_task/materialicons/24px.svg';
import CheckboxIcon from '../../../images/md-icons/check_box/materialicons/24px.svg';
import CheckboxOutlineIcon from '../../../images/md-icons/check_box_outline_blank/materialicons/24px.svg';

import Pressable from '../Pressable';
import styles from './styles';

const Checkbox = (props) => {
  const [checked, setChecked] = useState(props.checked);
  const [disabled, setDisabled] = useState(props.disabled);
  const timerID = useRef(null);

  useEffect(
    () => () => {
      if (timerID.current) {
        clearTimeout(timerID.current);
      }
    },
    [],
  );

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  useEffect(() => {
    clearTimeout(timerID.current);
    setDisabled(props.disabled);
  }, [props.disabled]);

  const onPress = useCallback(
    _.debounce(
      async () => {
        if (disabled) {
          return;
        }

        const value = !checked;

        setDisabled(true);
        timerID.current = setTimeout(
          () => setDisabled(false),
          _.isNumber(props.disabledTimeout) ? props.disabledTimeout : 2500,
        );

        let prevent_change = false;
        if (props.onPress) {
          prevent_change = await props.onPress(value);
        }

        if (!prevent_change) {
          setChecked(value);
        }
      },
      1000,
      {leading: true, trailing: false},
    ),
    [disabled, props.onPress, checked],
  );

  let checkedIcon = <CheckboxIcon fill="#231fda" />;
  let uncheckedIcon = <CheckboxOutlineIcon fill="#4a4a49" />;
  if (props.type === 'add-task') {
    checkedIcon = <CheckCircleIcon />;
    uncheckedIcon = <AddTaskIcon />;
  }

  return (
    <Pressable
      allowBubbling
      disabled={disabled}
      onPress={onPress}
      style={[{opacity: 1}, props.containerStyle]}>
      <View style={[styles.root, props.style, disabled && styles.disabled]}>
        {checked ? checkedIcon : uncheckedIcon}
      </View>
    </Pressable>
  );
};

export default Checkbox;
