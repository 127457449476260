import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import {View} from 'react-native';
import _ from 'lodash';
import {HelperText, TextInput} from 'react-native-paper';

import Format from '../../../lib/format';
import FocusManagerContext from '../../context/FocusManagerContext';

const FormNumberInput = ({
  style,
  value,
  digits = 2,
  label,
  required,
  errorMessage,
  inputProps,
  disabled,
  onChangeNumber,
  onEndEditing,
  onSubmit,
  onFocus,
}) => {
  const inputRef = useRef();
  const {setFocus} = useContext(FocusManagerContext);

  const [displayValue, setDisplayValue] = useState(
    _.isString(value) ? value : Format.number(value, digits),
  );

  useEffect(() => {
    setDisplayValue(_.isString(value) ? value : Format.number(value, digits));
  }, [value, digits]);

  const [error, setError] = useState('');

  const _onChangeText = (text) => {
    setDisplayValue(text);
    if (!text.length && required) {
      setError(errorMessage);
      return;
    }

    setError(null);
  };

  const onChange = useCallback(async () => {
    const normalized = displayValue.replace(/,/g, '.');

    let number = null;
    if (normalized.length) {
      number = digits === 0 ? parseInt(normalized) : parseFloat(normalized);
    }

    if (
      onChangeNumber &&
      Format.number(number, digits) !== Format.number(value, digits)
    ) {
      const changed = await onChangeNumber(number);
      number = changed ?? number;
    }

    return number;
  }, [displayValue, value, digits, onChangeNumber]);

  const onBlur = useCallback(async () => {
    const number = await onChange();
    setDisplayValue(Format.number(number, digits));

    if (onEndEditing) {
      onEndEditing(number);
    }

    setFocus(null);
  }, [digits, onChange, setFocus, onEndEditing]);

  const _onFocus = useCallback(() => {
    setFocus(inputRef.current);
    if (onFocus) {
      onFocus();
    }
  }, [setFocus]);

  const onKeyPress = async (e) => {
    if (onSubmit) {
      switch (e.key) {
        case 'Enter':
          const number = await onChange();
          onSubmit(number);
          break;
        default:
          break;
      }
    }
  };

  return (
    <View style={[disabled && {backgroundColor: 'rgb(240, 240, 240)'}, style]}>
      <TextInput
        ref={inputRef}
        dense={true}
        selectTextOnFocus={true}
        label={label}
        value={displayValue}
        onChangeText={(text) => _onChangeText(text)}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        onFocus={_onFocus}
        onEndEditing={onEndEditing}
        keyboardType={digits === 0 ? 'numeric' : 'decimal-pad'}
        error={!!error}
        disabled={disabled}
        {...inputProps}
      />
      <HelperText type="error" visible={!!error}>
        {error}
      </HelperText>
      {disabled && (
        <View
          style={[
            {
              backgroundColor: 'rgba(0, 0, 0, 0.26)',
              height: 2,
              zIndex: 1,
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
            },
          ]}></View>
      )}
    </View>
  );
};

export default FormNumberInput;
