import React, {useRef, useEffect} from 'react';
import {View, Animated} from 'react-native';

import sy from '~/styles';
import {Pressable} from '~/components/controls';

const Drawer = (props) => {
  const translateAnim = useRef(new Animated.Value(0)).current;
  const opacityAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(translateAnim, {
      toValue: 100,
      duration: 250,
      useNativeDriver: true,
    }).start();

    Animated.timing(opacityAnim, {
      toValue: 1,
      duration: 250,
      useNativeDriver: true,
    }).start();
  }, [translateAnim, opacityAnim]);

  return (
    <>
      <Animated.View
        style={[
          {
            position: 'fixed',
            left: 0,
            right: 'initial',
            boxShadow:
              '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
            zIndex: 6,
            borderColor: 'rgba(0, 0, 0, 0.12)',
            height: '100%',
            backgroundColor: '#fff',
            transform: [
              {
                translateX: translateAnim.interpolate({
                  inputRange: [0, 100],
                  outputRange: ['-100%', '0%'],
                }),
              },
            ],
          },
          props.style,
        ]}>
        {props.children}
      </Animated.View>
      <Pressable onPress={props.onClose}>
        <Animated.View
          style={[
            {
              position: 'fixed',
              left: 0,
              top: 0,
              height: '100%',
              width: '100%',
              zIndex: 5,
              backgroundColor: 'rgba(0, 0, 0, 0.32)',
              opacity: opacityAnim,
            },
          ]}></Animated.View>
      </Pressable>
    </>
  );
};

export default Drawer;

export const DrawerHeader = (props) => {
  return <View style={[{minHeight: 64}, sy['p-4']]}>{props.children}</View>;
};

export const DrawerTitle = (props) => {
  return <View>{props.children}</View>;
};

export const DrawerSubtitle = (props) => {
  return <View>{props.children}</View>;
};

export const DrawerContent = (props) => {
  return <View style={[sy['flex-1']]}>{props.children}</View>;
};
