import AsyncStorage from '@react-native-async-storage/async-storage';
import * as WebBrowser from 'expo-web-browser';
import {
  makeRedirectUri,
  AuthRequest,
  resolveDiscoveryAsync,
} from 'expo-auth-session';
import jwt_decode from 'jwt-decode';
import qs from 'qs';

import trace from './trace';
import {fetch} from './fetch';

WebBrowser.maybeCompleteAuthSession();

const config = {
  /*
   */
  discoveryUrl:
    'https://bahnkick.b2clogin.com/bahnkick.onmicrosoft.com/B2C_1A_PARTNER_SIGN_IN_CUSTOMPOLICY/v2.0/',
  clientId: 'aaaf62cf-32b9-4939-acaa-b37ac26b2eeb',
  redirectUri: makeRedirectUri(),
  scopes: [
    'aaaf62cf-32b9-4939-acaa-b37ac26b2eeb',
    'openid',
    'profile',
    'email',
    'offline_access',
  ],
  responseType: 'code id_token token',
};

class Authentication {
  constructor() {
    this.listeners = [];

    navigator.serviceWorker.addEventListener('message', async (event) => {
      const {action} = event.data;
      const port = event.ports[0];

      if (action === 'getAuthTokenHeader') {
        const token = await this.getAutoRefreshAccessToken();

        port.postMessage({
          token,
        });
      } else {
        console.error('Unknown event', event);
        port.postMessage({
          error: 'Unknown request',
        });
      }
    });
  }

  addListener(listener) {
    this.listeners.push(listener);
  }

  async getUserID() {
    const accessToken = await this.getAccessToken();
    if (!accessToken) {
      return null;
    }

    const decoded = jwt_decode(accessToken);
    return decoded.sub;
  }

  async getAccessToken() {
    if (document.cookie?.indexOf('accessToken') >= 0) {
      const cookies = document.cookie.split(';').reduce((acc, item) => {
        const [key, value] = item.split('=');

        document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';

        acc[key] = value;
        return acc;
      }, {});

      const {accessToken, refreshToken} = cookies;
      await AsyncStorage.setItem(
        'tokens',
        JSON.stringify({accessToken, refreshToken}),
      );

      return accessToken;
    }

    const stored = await AsyncStorage.getItem('tokens');
    if (!stored) {
      return null;
    }

    const tokens = JSON.parse(stored);
    return tokens?.accessToken;
  }

  async getAutoRefreshAccessToken() {
    let token = await this.getAccessToken();

    if (token) {
      try {
        const decoded = jwt_decode(token);
        if (new Date().getTime() > decoded?.exp * 1000) {
          const refreshed = await this.refresh();
          if (refreshed) {
            token = await this.getAccessToken();
          }
        }
      } catch {}
    }

    return token;
  }

  async getRefreshToken() {
    const stored = await AsyncStorage.getItem('tokens');
    if (!stored) {
      return null;
    }

    const tokens = JSON.parse(stored);
    return (tokens && tokens.refreshToken) || null;
  }

  async getIdToken() {
    const stored = await AsyncStorage.getItem('tokens');
    if (!stored) {
      return null;
    }

    const tokens = JSON.parse(stored);
    return (tokens && tokens.idToken) || null;
  }

  async setTokens(accessToken, refreshToken, idToken) {
    await AsyncStorage.setItem(
      'tokens',
      JSON.stringify({accessToken, refreshToken, idToken}),
    );

    // To prevent race conditions (specifically Safari)
    setTimeout(() => {
      this.listeners.forEach((listener) => {
        listener();
      });
    }, 500);
  }

  async loginInit() {
    const {discoveryUrl, clientId, redirectUri, ...rest} = config;
    this.discovery = await resolveDiscoveryAsync(discoveryUrl);
    this.authRequest = new AuthRequest({
      clientId,
      redirectUri,
      ...rest,
    });

    this.loginurl = await this.authRequest.makeAuthUrlAsync(this.discovery);
  }

  async login() {
    try {
      const {clientId, redirectUri} = config;
      const result = await this.authRequest.promptAsync(this.discovery, {
        url: this.loginurl,
      });

      if (result.type === 'success') {
        const {code, id_token: idToken} = result.params;

        const scope = [
          'aaaf62cf-32b9-4939-acaa-b37ac26b2eeb',
          'offline_access',
        ].join(' ');
        const body = qs.stringify({
          client_id: clientId,
          redirect_uri: redirectUri,
          scope,
          code,
          grant_type: 'authorization_code',
          code_verifier: this.authRequest.codeVerifier,
        });
        const tokenResponse = await fetch(this.discovery.tokenEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body,
        });

        const {access_token: accessToken, refresh_token: refreshToken} =
          await tokenResponse.json();

        trace('Received tokens', accessToken, refreshToken, idToken);

        await this.setTokens(accessToken, refreshToken, idToken);

        return true;
      }
    } catch (e) {
      console.error(e);
    }

    return false;
  }

  async logout() {
    try {
      // Delay load the user api, in order to avoid circle ref's
      const {user: userApi} = require('../api/private');
      await userApi.logout();
    } catch (e) {
      trace(e);
    } finally {
      await AsyncStorage.removeItem('tokens');

      this.listeners.forEach((listener) => {
        listener();
      });
    }
  }

  async refresh() {
    trace('Refreshing tokens');

    const refresh_token = await this.getRefreshToken();
    if (!refresh_token) {
      trace('No valid refresh token found.');
      return false;
    }

    const {clientId, redirectUri, discoveryUrl} = config;
    const scope = [
      'aaaf62cf-32b9-4939-acaa-b37ac26b2eeb',
      'offline_access',
    ].join(' ');
    const {tokenEndpoint} = await resolveDiscoveryAsync(discoveryUrl);

    const body = qs.stringify({
      clientId,
      redirectUri,
      scope,
      refresh_token,
      grant_type: 'refresh_token',
    });

    const tokenResponse = await fetch(tokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body,
    });
    const result = await tokenResponse.json();

    await this.setTokens(result.access_token, result.refresh_token);
    trace('Refreshed tokens', result);

    return true;
  }
}

const auth = new Authentication();
export default auth;
