import React, {useState, useEffect} from 'react';
import {View, ScrollView, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import {Text, Link, Dialog} from '~/components/controls';
import {articles as articlesApi} from '~/api/private';
import Format from '~/lib/format';
import withDimensions from '~/components/hoc/with-dimensions';

import ImageIcon from '~/images/md-icons/image/materialicons/24px.svg';
import OpenInNewIcon from '~/images/md-icons/open_in_new/materialicons/24px.svg';

const DIALOG_HEADER_IN_PIXELS = 64;
const DIALOG_FOOTER_IN_PIXELS = 50;

const DetailsDialog = withDimensions(
  ({
    dimensions,
    visible,
    article_id,
    license,
    dealer_id,
    description,
    onDismiss,
  }) => {
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState(null);

    useEffect(() => {
      const fetch = async () => {
        const {success, result} = await articlesApi.details(
          license,
          dealer_id,
          article_id,
        );

        if (success) {
          setDetails(result);
        }

        setLoading(false);
      };
      if (visible && !details) {
        fetch();
      }
    }, [visible, details]);

    const {
      id,
      articleNo,
      eanCodes,
      productAttributes,
      images,
      brutoPrice,
      nettoPrice,
    } = details ?? {};

    const eanCode = eanCodes?.[0];
    const alfaCode =
      details?.alfaCode && (articleNo ?? '') !== details?.alfaCode
        ? details?.alfaCode
        : null;

    const margin = brutoPrice - nettoPrice;

    const attributes = productAttributes
      ?.filter((item) => item.id === 'Eigenschap')
      .map((item) => item.value);

    const mount_position = productAttributes
      ?.filter((item) => item.id === 'installation-location')
      .map((item) => item.value)
      .join(' ');

    return (
      <Dialog
        title={description}
        visible={visible}
        onDismiss={onDismiss}
        options={{noPaddingContent: true}}
        buttons={[
          {
            text: 'Sluiten',
            onPress: onDismiss,
          },
        ]}>
        {loading ? (
          <View style={[sy['p-4']]}>
            <ActivityIndicator size="large" color="#231fda" />
          </View>
        ) : (
          <ScrollView
            contentContainerStyle={{
              maxHeight:
                dimensions.window.height -
                DIALOG_HEADER_IN_PIXELS -
                DIALOG_FOOTER_IN_PIXELS,
            }}>
            <View style={[sy['py-4'], sy['gap-4']]}>
              {!!articleNo && (
                <>
                  <View
                    style={[
                      sy['px-6'],
                      sy['flex-row'],
                      sy['justify-between'],
                      sy['items-center'],
                    ]}>
                    <Text>Fabrikant id</Text>
                    <Text style={sy.smallRegular}>{articleNo}</Text>
                  </View>
                  <Divider style={sy['bg-gray95']} />
                </>
              )}
              <View
                style={[
                  sy['px-6'],
                  sy['flex-row'],
                  sy['justify-between'],
                  sy['items-center'],
                ]}>
                <Text>Artikel id</Text>
                <Text style={sy.smallRegular}>{id}</Text>
              </View>
              <Divider style={sy['bg-gray95']} />
              {!!eanCode && (
                <>
                  <View
                    style={[
                      sy['px-6'],
                      sy['flex-row'],
                      sy['justify-between'],
                      sy['items-center'],
                    ]}>
                    <Text>EAN</Text>
                    <Text style={sy.smallRegular}>{eanCode}</Text>
                  </View>
                  <Divider style={sy['bg-gray95']} />
                </>
              )}
              {!!alfaCode && (
                <>
                  <View
                    style={[
                      sy['px-6'],
                      sy['flex-row'],
                      sy['justify-between'],
                      sy['items-center'],
                    ]}>
                    <Text>Alfa code</Text>
                    <Text style={sy.smallRegular}>{alfaCode}</Text>
                  </View>
                  <Divider style={sy['bg-gray95']} />
                </>
              )}
              {!!mount_position && (
                <>
                  <View
                    style={[
                      sy['px-6'],
                      sy['flex-row'],
                      sy['gap-12'],
                      sy['justify-between'],
                      sy['items-center'],
                    ]}>
                    <Text>Inbouwplaats</Text>
                    <Text style={[sy.smallRegular, sy['text-right']]}>
                      {mount_position[0].toUpperCase()}
                      {mount_position.substring(1)}
                    </Text>
                  </View>
                  <Divider style={sy['bg-gray95']} />
                </>
              )}
              {attributes?.length > 0 && (
                <>
                  <View style={[sy['px-6']]}>
                    {attributes.map((item, index) => (
                      <View key={index}>
                        <Text style={sy.smallRegular}>{item}</Text>
                      </View>
                    ))}
                  </View>
                  <Divider style={sy['bg-gray95']} />
                </>
              )}
              {!!images?.[0] && (
                <>
                  <View style={[sy['px-6']]}>
                    <a href={images[0]} target="_blank" rel="noreferrer">
                      <View
                        style={[
                          sy['flex-1'],
                          sy['flex-row'],
                          sy['justify-between'],
                        ]}>
                        <View style={[sy['flex-row'], sy['gap-8']]}>
                          <ImageIcon fill="#4a4a49" />
                          <Text style={sy.underline}>Afbeelding</Text>
                        </View>
                        <OpenInNewIcon fill="#4a4a49" />
                      </View>
                    </a>
                  </View>
                  <Divider style={sy['bg-gray95']} />
                </>
              )}
              <View
                style={[
                  sy['px-6'],
                  sy['flex-row'],
                  sy['justify-between'],
                  sy['items-center'],
                ]}>
                <Text>Brutoprijs</Text>
                <Text style={sy.smallRegular}>{Format.price(brutoPrice)}</Text>
              </View>
              <Divider style={sy['bg-gray95']} />
              <View
                style={[
                  sy['px-6'],
                  sy['flex-row'],
                  sy['justify-between'],
                  sy['items-center'],
                ]}>
                <Text>Nettoprijs</Text>
                <Text style={sy.smallRegular}>{Format.price(nettoPrice)}</Text>
              </View>
              <Divider style={sy['bg-gray95']} />
              <View
                style={[
                  sy['px-6'],
                  sy['flex-row'],
                  sy['justify-between'],
                  sy['items-center'],
                ]}>
                <Text>Brutomarge</Text>
                <Text style={sy.smallRegular}>
                  {Format.price(margin)}&nbsp;(
                  {Format.number((margin / brutoPrice) * 100)}%)
                </Text>
              </View>
            </View>
          </ScrollView>
        )}
      </Dialog>
    );
  },
);

const FourceDetailsLink = ({license, dealer_id, article}) => {
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);

  const onLinkPress = async () => {
    setShowDetailsDialog(true);
  };

  return (
    <>
      <Link textStyle={[sy.small, sy['text-lightgray']]} onPress={onLinkPress}>
        Fource&nbsp;&middot;&nbsp;{article.number}
      </Link>
      <DetailsDialog
        visible={showDetailsDialog}
        article_id={article.id}
        license={license}
        dealer_id={dealer_id}
        description={article.description}
        onDismiss={() => setShowDetailsDialog(false)}
      />
    </>
  );
};

export default FourceDetailsLink;
