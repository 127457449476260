import config from '~/configuration';

export const apiRoot = config.publicApiRoot;

const endpoints = {
  dealer: `${apiRoot}/dealer`,
  address: `${apiRoot}/address`,
  kvk: `${apiRoot}/kvk`,
};

export default endpoints;
