import React from 'react';
import {ActivityIndicator} from 'react-native';

import sy from '~/styles';
import {Pressable, Text} from '~/components/controls';

import CheckIcon from '~/images/md-icons/check/materialicons/24px.svg';

const Chip = ({children, active, loading, onPress}) => (
  <Pressable
    style={[
      sy['bg-gray95'],
      {borderRadius: 20, cursor: 'pointer'},
      sy['flex-row'],
      sy['items-center'],
      sy['px-4'],
      sy['py-2'],
      loading || active ? sy['pl-2'] : null,
      active
        ? {
            backgroundColor: '#c4c4c4',
          }
        : null,
    ]}
    disabled={loading}
    onPress={onPress}>
    {loading ? (
      <ActivityIndicator size="small" color="#231fda" />
    ) : (
      active && <CheckIcon height={18} width={18} fill="#4A4A49" />
    )}
    <Text
      style={[
        {color: '#828282'},
        sy.smallRegular,
        sy['whitespace-nowrap'],
        loading || active ? sy['pl-1'] : null,
        active ? {color: '#4A4A49'} : null,
      ]}>
      {children}
    </Text>
  </Pressable>
);

export default React.memo(Chip);
