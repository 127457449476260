import React from 'react';
import {View} from 'react-native';

import sy from '~/styles';
import {Text, Menu} from '~/components/controls';

import PriorityIcon from '~/images/md-icons/priority/materialicons/24px.svg';

export default ({lessor, onDismiss}) => {
  return (
    <Menu
      anchor={<PriorityIcon width={18} height={18} fill="#4A4A49" />}
      style={{marginTop: 0}}
      contentStyle={{backgroundColor: '#6D6D6D'}}
      onDismiss={onDismiss}>
      <View style={[sy['px-2'], sy['flex-row'], sy['gap-2']]}>
        <PriorityIcon width={18} height={18} fill="#FFFFFF" />
        <Text
          style={[sy.smallMedium, {color: '#FFFFFF', whiteSpace: 'nowrap'}]}>
          Voorkeur {lessor}
        </Text>
      </View>
    </Menu>
  );
};
