import {StaticDispatch} from '~/components/hoc/with-session-state';
import {
  serviceRequestUpdate,
  serviceRequestInsert,
  updateImportantMessage,
  setUpdateAfter,
  showRefreshImportantDialog,
  showRefreshDialog,
} from '~/actions';

const handler = ({
  alerts,
  errors,
  request_id,
  status,
  reason,
  rob_id,
  rob_status,
  rob_invoicing_status,
}) => {
  console.log(
    'Notification received',
    alerts,
    errors,
    request_id,
    status,
    reason,
    rob_id,
    rob_status,
    rob_invoicing_status,
  );

  if (request_id) {
    if (status === 'Draft' || status === 'Reserved') {
      StaticDispatch(serviceRequestInsert());
    } else {
      StaticDispatch(
        serviceRequestUpdate({
          request_id,
          status,
          reason,
          rob_id,
          rob_status,
          rob_invoicing_status,
        }),
      );
    }
  }

  if (alerts) {
    for (const alert of alerts) {
      if (alert.type === 'REFRESH_IMPORTANT') {
        StaticDispatch(setUpdateAfter());
        StaticDispatch(showRefreshImportantDialog());
      }

      if (alert.type === 'REFRESH') {
        StaticDispatch(showRefreshDialog());
      }

      if (alert.type === 'IMPORTANT') {
        StaticDispatch(updateImportantMessage());
      }
    }
  }
};

export default handler;
