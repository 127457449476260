export default {
  buttonView: {
    borderRadius: 8,
    padding: 12,
    alignItems: 'center',
  },
  transparentButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#dcdcdc',
  },
};
