import {StyleSheet} from 'react-native';
export default StyleSheet.create({
  root: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabled: {
    opacity: 0.5,
  },
});
