import 'intl';
import 'intl/locale-data/jsonp/nl-NL';
import 'moment/locale/nl';

import {StatusBar} from 'expo-status-bar';
import React, {useState, useEffect, useContext, useCallback} from 'react';
import {View, Platform, ActivityIndicator} from 'react-native';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import {
  configureFonts,
  MD2LightTheme as DefaultTheme,
  Provider as PaperProvider,
  Divider,
} from 'react-native-paper';
import 'react-native-gesture-handler';

import moment from 'moment';

import {
  useFonts,
  Roboto_100Thin,
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
  Roboto_900Black,
} from '@expo-google-fonts/roboto';

import configuration from './configuration';
import {user as userApi} from './api/private';
import authentication from './lib/authentication';
import Notifications from './lib/notifications';
import {useDispatch, useSelector} from './lib/hooks';
import {
  routeChange,
  setAppDoneLoading,
  hideLogoutDialog,
  hideTecrmiDialog,
  setUserSettings,
  hideSettingsModal,
  hideHelpModal,
  setCalendarCtx,
  hideRefreshImportantDialog,
  hideRefreshDialog,
} from './actions';
import sy from './styles';

import AuthContext from './components/context/AuthContext';
import UserContext from './components/context/UserContext';
import {
  Modal,
  Text,
  Dialog,
  Checkbox,
  CountDownText,
} from './components/controls';
import {SideDrawer} from './components/shared';
import {SettingsModal, HelpModal} from './components/shared/Modals';
import {ClientMessagesDialog} from './components/shared/Dialogs';
import withSessionState from './components/hoc/with-session-state';

import {TabsFlow} from './flows';
import AppointmentScreen from './screens/appointment';
import RequestScreen from './screens/request';
import AddTasksScreen from './screens/add_tasks';
import ChatScreen from './screens/chat';
import ReplacementScreen from './screens/replacement';
import FourceScreen from './screens/fource';
import SignInScreen from './screens/signin';

import ReportIcon from './images/md-icons/report/materialicons/24px.svg';
import UpdateIcon from './images/md-icons/update/materialicons/24px.svg';

import LogRocket from 'logrocket';

LogRocket.init(configuration.LOG_ROCKET_KEY, {});

moment.locale('nl');

const withAuthContext = (App) => {
  return (props) => {
    const [auth, setAuth] = useState({authenticated: null});

    useEffect(() => {
      const check = async () => {
        const accessToken = await authentication.getAccessToken();
        setAuth({authenticated: !!accessToken});
      };

      authentication.addListener(async () => {
        await check();
      });

      check();
    }, []);

    return (
      <AuthContext.Provider value={auth}>
        <App {...props} />
      </AuthContext.Provider>
    );
  };
};

const withUserContext = (App) => {
  return (props) => {
    const dispatch = useDispatch();
    const {authenticated} = useContext(AuthContext);
    const [user, setUser] = useState(null);

    const fetch = useCallback(async () => {
      if (!authenticated) {
        return;
      }

      try {
        const me = await userApi.me();

        if (!me) {
          throw new Error('Not able to retrieve user from api.');
        }

        const {user_settings, ...rest} = me;

        setUser(rest);
        dispatch(setUserSettings(user_settings?.partner ?? {}));
        dispatch(setCalendarCtx(null));

        LogRocket.identify(me.user_id, me.user_settings.logrocket);
      } catch {
        setUser(null);
        await authentication.logout();
        dispatch(setAppDoneLoading());
      }
    }, [authenticated, dispatch]);

    useEffect(() => {
      fetch();
    }, [fetch]);

    return (
      <UserContext.Provider value={{me: user, update: fetch}}>
        <App {...props} />
      </UserContext.Provider>
    );
  };
};

const prefix = Linking.makeUrl('/');
const routes = {
  screens: {
    Tabs: {
      path: '',
      screens: {
        Search: 'Search/:status?',
        Calendar: 'Calendar',
      },
    },
    Request: 'Request/:request_id',
    Replacement: 'Replacement/:request_id',
    Chat: 'Chat/:request_id',
  },
};

const linking = {
  prefixes: [prefix],
  config: routes,
};

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#231fda',
    background: '#ffffff',
    text: '#49494a',
  },
  fonts: configureFonts({
    config: {
      web: {
        regular: {
          fontFamily: 'Roboto_400Regular',
          fontWeight: '400',
        },
        medium: {
          fontFamily: 'Roboto_500Medium',
          fontWeight: '500',
        },
      },
      native: {
        regular: {
          fontFamily: 'Roboto_400Regular',
          fontWeight: '400',
        },
        medium: {
          fontFamily: 'Roboto_500Medium',
          fontWeight: '500',
        },
      },
    },
    isV3: false,
  }),
};

const withShortcutsContext = (App) => {
  return (props) => {
    const keyListener = useCallback((e) => {
      switch (e.keyCode) {
        default:
          break;
      }
    }, []);

    useEffect(() => {
      if (Platform.OS === 'web') {
        document.addEventListener('keydown', keyListener);
      }
    }, [keyListener]);

    return <App {...props} />;
  };
};

const Stack = createStackNavigator();

const App = withAuthContext(
  withUserContext(
    withSessionState(
      withShortcutsContext(() => {
        const dispatch = useDispatch();
        const {me} = useContext(UserContext);

        const loading = useSelector((state) => state.app_loading);
        const show_logout_dialog = useSelector(
          (state) => state.show_logout_dialog,
        );
        const show_drawer = useSelector((state) => state.show_drawer);
        const show_tecrmi_dialog = useSelector(
          (state) => state.show_tecrmi_dialog,
        );
        const user_settings = useSelector((state) => state.user_settings);
        const show_settings_modal = useSelector(
          (state) => state.show_settings_modal,
        );
        const show_help_modal = useSelector((state) => state.show_help_modal);
        const show_refresh_important_dialog = useSelector(
          (state) => state.show_refresh_important_dialog,
        );
        const show_refresh_dialog = useSelector(
          (state) => state.show_refresh_dialog,
        );
        const update_after = useSelector((state) => state.update_after);

        const [fontsLoaded] = useFonts({
          Roboto_100Thin,
          Roboto_300Light,
          Roboto_400Regular,
          Roboto_500Medium,
          Roboto_700Bold,
          Roboto_900Black,
        });

        useEffect(() => {
          Notifications.initialize();
          return () => {
            Notifications.kill();
          };
        }, []);

        useEffect(() => {
          if (update_after > 0) {
            const timeout = setTimeout(() => {
              window.location.reload();
            }, update_after - new Date().getTime());

            return () => clearTimeout(timeout);
          }
        }, [update_after]);

        useEffect(() => {
          if (fontsLoaded) {
            window.electronApi?.appLoaded();
          }
        }, [fontsLoaded]);

        if (!fontsLoaded) {
          return <View />;
        }

        return (
          <>
            <PaperProvider theme={theme}>
              <StatusBar />
              <NavigationContainer
                linking={linking}
                fallback={<Text>Loading...</Text>}
                documentTitle={{
                  formatter: () => `Bahnkick Partner`,
                }}
                onStateChange={(state) => {
                  const {routes} = state;
                  if (routes && routes.length) {
                    const route = routes[routes.length - 1];
                    dispatch(
                      routeChange({
                        name: route.name,
                        params: route.params,
                      }),
                    );
                  }
                }}>
                <Stack.Navigator
                  initialRouteName="Tabs"
                  screenOptions={{
                    headerShown: false,
                    cardStyle: {
                      backgroundColor: 'rgba(74, 74, 72, 0.5)',
                      height: '100%',
                    },
                  }}>
                  <Stack.Screen
                    navigationKey={me?.user_id}
                    name="Tabs"
                    component={TabsFlow}
                    initialParams={{
                      initialRouteName:
                        user_settings?.initial_tab ?? 'Messages',
                    }}
                  />
                  <Stack.Screen
                    name="Appointment"
                    component={AppointmentScreen}
                  />
                  <Stack.Screen name="Request" component={RequestScreen} />
                  <Stack.Screen name="AddTasks" component={AddTasksScreen} />
                  <Stack.Screen name="Chat" component={ChatScreen} />
                  <Stack.Screen
                    name="Replacement"
                    component={ReplacementScreen}
                  />
                  <Stack.Screen name="Fource" component={FourceScreen} />
                  <Stack.Screen name="SignIn" component={SignInScreen} />
                </Stack.Navigator>
              </NavigationContainer>
              <>
                {show_drawer && <SideDrawer />}
                <Dialog
                  visible={show_logout_dialog}
                  onDismiss={() => dispatch(hideLogoutDialog())}
                  buttons={[
                    {
                      text: 'Annuleren',
                      onPress: () => dispatch(hideLogoutDialog()),
                    },
                    {
                      text: 'OK',
                      onPress: async () => {
                        await authentication.logout();
                        dispatch(hideLogoutDialog());
                      },
                    },
                  ]}
                  options={{noPaddingContent: false, hideDividers: true}}>
                  <View style={[sy['flex-row']]}>
                    <Text style={[sy['p-4']]}>
                      Weet je zeker dat je wilt uitloggen?
                    </Text>
                  </View>
                </Dialog>
                {show_tecrmi_dialog && (
                  <Dialog
                    visible={true}
                    title="TecRMI info ophalen?"
                    options={{noPaddingContent: true}}
                    buttons={[
                      {
                        text: 'Annuleren',
                        onPress: () => {
                          show_tecrmi_dialog.reject();
                          dispatch(hideTecrmiDialog());
                        },
                      },
                      {
                        text: 'OK',
                        onPress: async () => {
                          if (user_settings.tecrmi_info_dialog_never_show) {
                            await userApi.update_settings({
                              ...user_settings,
                              tecrmi_info_dialog_never_show: true,
                            });
                          }

                          show_tecrmi_dialog.resolve();
                          dispatch(hideTecrmiDialog());
                        },
                      },
                    ]}>
                    <View style={[sy['p-4'], sy['flex-row'], sy['gap-4']]}>
                      <View>
                        <ReportIcon fill="#4a4a49" />
                      </View>
                      <Text>
                        Door deze keuze wordt service info van TecRMI opgehaald
                        (indien beschikbaar) en ga je akkoord met doorbelasting
                        van de gebruikskosten.
                      </Text>
                    </View>
                    <Divider />
                    <View style={[sy['p-4']]}>
                      <View style={[sy['flex-row'], sy['gap-4']]}>
                        <Checkbox
                          disabledTimeout={0}
                          onPress={(value) =>
                            dispatch(
                              setUserSettings({
                                tecrmi_info_dialog_never_show: value,
                              }),
                            )
                          }
                        />
                        <Text>Niet meer tonen</Text>
                      </View>
                    </View>
                  </Dialog>
                )}
                {show_refresh_important_dialog && (
                  <Dialog
                    visible={true}
                    title="Belangrijke update"
                    titleIcon={<UpdateIcon fill="#2907E3" />}
                    options={{noPaddingContent: true}}
                    buttons={[
                      {
                        text: 'Nu bijwerken',
                        onPress: () => window.location.reload(),
                      },
                      {
                        text: 'OK',
                        onPress: () => dispatch(hideRefreshImportantDialog()),
                      },
                    ]}>
                    <View style={[sy['py-4'], sy['px-6'], sy['gap-4']]}>
                      <Text>
                        Er is een belangrijke update voor de Bahnkick Partner
                        app.
                      </Text>
                      <Text>
                        De app wordt bijgewerkt en herstart in&nbsp;
                        <CountDownText
                          timeout={Math.round(
                            (update_after - new Date().getTime()) / 1000,
                          )}
                        />
                        ...
                      </Text>
                    </View>
                  </Dialog>
                )}
                {show_refresh_dialog && (
                  <Dialog
                    visible={true}
                    title="Belangrijke update"
                    titleIcon={<UpdateIcon fill="#2907E3" />}
                    options={{noPaddingContent: true}}
                    buttons={[
                      {
                        text: 'Later',
                        onPress: () => dispatch(hideRefreshDialog()),
                      },
                      {
                        text: 'Bijwerken',
                        onPress: () => window.location.reload(),
                      },
                    ]}>
                    <View style={[sy['py-4'], sy['px-6'], sy['gap-4']]}>
                      <Text>
                        Er is een belangrijke update voor de Bahnkick Partner
                        app.
                      </Text>
                      <Text>
                        Je kunt de app nu bijwerken of op een later moment. Bij
                        een herstart volgt de update automatisch.
                      </Text>
                    </View>
                  </Dialog>
                )}
                <ClientMessagesDialog />
                {loading && (
                  <Modal
                    visible={true}
                    style={{backgroundColor: 'rgba(255, 255, 255, 0.5)'}}
                    contentContainerStyle={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'transparent',
                    }}>
                    <ActivityIndicator size="large" color="#231fda" />
                  </Modal>
                )}
                <SettingsModal
                  visible={show_settings_modal}
                  onDismiss={() => dispatch(hideSettingsModal())}
                />
                <HelpModal
                  visible={show_help_modal}
                  onDismiss={() => dispatch(hideHelpModal())}
                />
              </>
            </PaperProvider>
          </>
        );
      }),
    ),
  ),
);

export default App;
