import React, {useState, useEffect} from 'react';
import {ActivityIndicator, View} from 'react-native';
import {Divider, Dialog as PaperDialog, Portal} from 'react-native-paper';
import _ from 'lodash';

import sy from '~/styles';

import {Text, ActionButton} from '~/components/controls';

const Dialog = ({
  style,
  visible,
  dismissable,
  onDismiss,
  title,
  titleIcon,
  titleStyle,
  children,
  initialValue: _initialValue,
  buttons,
  options = {
    hideDividers: false,
    showFooterLoading: false,
    noPaddingContent: false,
  },
}) => {
  const [initialValue, setInitialValue] = useState(_initialValue);

  useEffect(() => {
    // Only update the initial value if the dialog is hidden.
    // This prevents updates to the initial value when the dialog is shown, which is not what we want.
    if (!visible) {
      setInitialValue(_initialValue);
    }
  }, [visible, _initialValue]);

  let footer = (
    <ActionButton onPress={() => onDismiss({initialValue})}>OK</ActionButton>
  );
  if (buttons) {
    footer = buttons.map((button, index) => (
      <ActionButton
        key={index}
        onPress={() => button.onPress({initialValue})}
        disabled={button.disabled}>
        {button.text}
      </ActionButton>
    ));
  }

  return (
    <Portal>
      <PaperDialog
        style={style}
        visible={visible}
        dismissable={onDismiss ? dismissable : false}
        onDismiss={() => onDismiss({initialValue})}>
        {title && (
          <>
            <View
              style={[
                sy['flex-row'],
                sy['justify-between'],
                sy['items-center'],
                {marginLeft: 24, marginRight: 24, minHeight: 64},
                titleStyle,
              ]}>
              {_.isString(title) ? (
                <Text style={[sy.largePlus]}>{title}</Text>
              ) : (
                title
              )}
              {titleIcon}
            </View>
            {!options.hideDividers && <Divider />}
          </>
        )}
        <PaperDialog.Content
          style={[
            {paddingBottom: 0},
            options.noPaddingContent ? {paddingHorizontal: 0} : null,
          ]}>
          {children}
        </PaperDialog.Content>
        {!options.hideDividers && <Divider />}
        <PaperDialog.Actions style={sy['gap-2']}>
          {options.showFooterLoading ? (
            <ActivityIndicator size="large" color="#231fda" />
          ) : (
            footer
          )}
        </PaperDialog.Actions>
      </PaperDialog>
    </Portal>
  );
};

export default Dialog;
