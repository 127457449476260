import React, {useState, useEffect, useContext, useRef} from 'react';
import {View} from 'react-native';
import {HelperText, TextInput} from 'react-native-paper';

import FocusManagerContext from '../../context/FocusManagerContext';

const FormTextInput = (props) => {
  const {
    inputRef,
    style,
    value,
    label,
    inputProps,
    required,
    errorMessage,
    hasError,
    disabled,
    onChangeText,
    onEndEditing,
    onSubmit,
  } = props;

  const [displayText, setDisplayText] = useState(value);

  let internalInputRef = useRef(null);
  const {setFocus} = useContext(FocusManagerContext);

  const [error, setError] = useState('');

  const _onChangeText = (text) => {
    let displayText = text;
    if (onChangeText) {
      displayText = onChangeText(text) ?? text;
    }

    setDisplayText(text);

    if (!text.length && required) {
      setError(errorMessage);
      return;
    }

    setError(null);
  };

  useEffect(() => {
    if (hasError) {
      setError(errorMessage);
    } else {
      setError(null);
    }
  }, [hasError, errorMessage]);

  useEffect(() => {
    setDisplayText(value);
  }, [value]);

  const onFocus = () => {
    setFocus(internalInputRef.current);

    if (props.onFocus) {
      props.onFocus();
    }
  };

  const onBlur = () => {
    if (onEndEditing) {
      onEndEditing(displayText);
    }

    setFocus(null);

    if (props.onBlur) {
      props.onBlur();
    }
  };

  const onKeyPress = (e) => {
    if (onChangeText) {
      switch (e.key) {
        case 'Enter':
          if (onSubmit) {
            onSubmit(internalInputRef.current.state.value);
          } else if (internalInputRef.current?.state?.value) {
            onChangeText(internalInputRef.current.state.value);
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <View style={[disabled && {backgroundColor: 'rgb(240, 240, 240)'}, style]}>
      <TextInput
        ref={(ref) => {
          internalInputRef.current = ref;
          inputRef && (inputRef.current = ref);
        }}
        selectTextOnFocus={true}
        dense={true}
        label={label}
        value={displayText || ''}
        onChangeText={(text) => _onChangeText(text)}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        onFocus={onFocus}
        onEndEditing={onEndEditing}
        error={!!error}
        disabled={disabled}
        {...inputProps}
      />
      {!!error && (
        <HelperText type="error" visible={!!error}>
          {error}
        </HelperText>
      )}
      {disabled && (
        <View
          style={[
            {
              backgroundColor: 'rgba(0, 0, 0, 0.26)',
              height: 2,
              zIndex: 1,
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
            },
          ]}></View>
      )}
    </View>
  );
};

export default FormTextInput;
