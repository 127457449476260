import React from 'react';

import AcUnitIcon from '~/images/md-icons/ac_unit/materialicons/24px.svg';
import SunnyIcon from '~/images/md-icons/sunny/materialicons/24px.svg';
import SunnySnowingIcon from '~/images/md-icons/sunny_snowing/materialicons/24px.svg';

export default ({indicator, size = 18}) => (
  <>
    {indicator === 'Summer' && (
      <SunnyIcon width={size} height={size} fill="#FFB800" />
    )}
    {indicator === 'Winter' && (
      <AcUnitIcon width={size} height={size} fill="#49B3FF" />
    )}
    {indicator === 'AllSeason' && (
      <SunnySnowingIcon width={size} height={size} fill="#997A29" />
    )}
  </>
);
