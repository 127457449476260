import React from 'react';
import {isRobLeniencyTask} from '~/types/rob';

import MoreVertIcon from '~/images/md-icons/more_vert/materialicons/24px.svg';
import VerifiedUserIcon from '~/images/md-icons/verified_user/materialicons/24px.svg';
import DiscountIcon from '~/images/md-icons/discount/materialicons/24px.svg';
import CarCrashIcon from '~/images/md-icons/car_crash/materialicons/24px.svg';

export default ({value, ...rest}) => {
  let icon = <MoreVertIcon {...rest} />;

  if (value.warranty === 'Warranty') {
    icon = <VerifiedUserIcon fill="#299976" {...rest} />;
  } else if (isRobLeniencyTask(value.warranty)) {
    icon = <DiscountIcon fill="#FF6C00" {...rest} />;
  } else if (value.is_damage) {
    icon = <CarCrashIcon fill="#FF6C00" {...rest} />;
  }

  return icon;
};
