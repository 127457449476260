import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';
import _ from 'lodash';

const rob = {
  get: async (service_request_id) => {
    const result = await fetch(
      `${endpoints.rob}/request/${service_request_id}`,
    );

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  post: async (request) => {
    const result = await fetch(`${endpoints.rob}/request`, {
      method: 'POST',
      body: JSON.stringify(request),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  contract: async (license, request_id) => {
    const result = await fetch(`${endpoints.rob}/contract/${license}`, {
      method: 'POST',
      body: JSON.stringify({request_id}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  contract_by_dealer: async (license, dealer_id) => {
    const result = await fetch(`${endpoints.rob}/contract/${license}`, {
      method: 'POST',
      body: JSON.stringify({dealer_id}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  update_contract: async (license, request_id) => {
    const result = await fetch(`${endpoints.rob}/contract/${license}`, {
      method: 'POST',
      body: JSON.stringify({request_id, update: true}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  requests: async () => {
    const result = await fetch(`${endpoints.rob}/requests`, {
      method: 'POST',
    });

    if (!result) {
      return [];
    }

    const json = await result.json();
    return json;
  },
  delete_request: async (id) => {
    const result = await fetch(`${endpoints.rob}/request/${id}`, {
      method: 'DELETE',
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  component: async (license, request_id, rob_code) => {
    const result = await fetch(`${endpoints.rob}/components/${license}`, {
      method: 'POST',
      body: JSON.stringify({request_id, rob_codes: [rob_code]}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  all_components: async (request_id, rob_codes = null) => {
    const result = await fetch(`${endpoints.rob}/components`, {
      method: 'POST',
      body: JSON.stringify({request_id, rob_codes}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  components: async (license, request_id, rob_codes = null) => {
    const result = await fetch(`${endpoints.rob}/components/${license}`, {
      method: 'POST',
      body: JSON.stringify({request_id, rob_codes}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  rental_classes: async () => {
    const result = await fetch(`${endpoints.rob}/rentalclasses`);

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  request: async (request_id, partial_vin, current_mileage) => {
    const result = await fetch(`${endpoints.rob}/request/${request_id}`, {
      method: 'POST',
      body: JSON.stringify({
        partial_vin,
        current_mileage,
      }),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  update_request: async (request_id) => {
    const result = await fetch(`${endpoints.rob}/request/${request_id}`, {
      method: 'PATCH',
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  invoice: async (request_id, invoiceNumber) => {
    const result = await fetch(
      `${endpoints.rob}/request/${request_id}/invoice`,
      {
        method: 'POST',
        body: JSON.stringify({invoiceNumber}),
      },
    );

    if (!result) {
      return {
        success: false,
      };
    }

    const json = await result.json();
    return json;
  },
  request_replacement_vehicle: async (request_id, replacement) => {
    const result = await fetch(
      `${endpoints.rob}/${request_id}/replacement_vehicle`,
      {
        method: 'POST',
        body: JSON.stringify({...replacement}),
      },
    );

    if (!result) {
      return {success: false};
    }

    const json = await result.json();
    return json;
  },
  replacement_vehicle: async (request_id) => {
    const result = await fetch(
      `${endpoints.rob}/${request_id}/replacement_vehicle`,
    );

    if (!result) {
      return {success: false};
    }

    const json = await result.json();
    return json;
  },
  cancel_replacement_vehicle: async (request_id) => {
    const result = await fetch(
      `${endpoints.rob}/${request_id}/replacement_vehicle`,
      {
        method: 'DELETE',
      },
    );

    if (!result) {
      return {success: false};
    }

    const json = await result.json();
    return json;
  },
  change_invoice_status: async (request_id, invoiceStatus) => {
    const result = await fetch(
      `${endpoints.rob}/request/${request_id}/invoice`,
      {
        method: 'PATCH',
        body: JSON.stringify({invoiceStatus}),
      },
    );

    if (!result) {
      return {
        success: false,
      };
    }

    const json = await result.json();
    return json;
  },
  activate_task: async (request_id, rob_id, task_id) => {
    const result = await fetch(
      `${endpoints.rob}/${rob_id}/component/${task_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({request_id}),
      },
    );

    if (!result) {
      return {
        success: false,
      };
    }

    const json = await result.json();
    return json;
  },
  fetch_tire: async (request_id, tire_id) => {
    const result = await fetch(`${endpoints.rob}/tire/${tire_id}`, {
      method: 'POST',
      body: JSON.stringify({request_id}),
    });

    if (!result) {
      return {
        success: false,
      };
    }

    const json = await result.json();
    return json;
  },
  lessors: async () => {
    const result = await fetch(`${endpoints.rob}/lessors`);

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  task_price_check: async (lessor, rob_code, vin_number, hourly_rate) => {
    const result = await fetch(`${endpoints.rob}/component/price`, {
      method: 'POST',
      body: JSON.stringify({lessor, rob_code, vin_number, hourly_rate}),
    });

    if (!result) {
      return {
        success: false,
      };
    }

    const json = await result.json();
    return json;
  },
  articles: async (lessor, rob_code) => {
    const result = await fetch(`${endpoints.rob}/articles`, {
      method: 'POST',
      body: JSON.stringify({lessor, rob_code}),
    });

    if (!result) {
      return {
        success: false,
      };
    }

    const json = await result.json();
    return json;
  },
  invoice_pdf: async (request_id) => {
    const result = await fetch(`${endpoints.rob}/${request_id}/invoice/pdf`);

    if (!result) {
      return {
        success: false,
      };
    }

    const blob = await result.blob();
    return new Blob([blob], {type: 'application/pdf'});
  },
};

export default rob;
