import React from 'react';

import {ROB_TASK_STATUS} from '~/types/rob';
import ThumbUpIcon from '~/images/md-icons/thumb_up/materialicons/24px.svg';
import ThumbDownIcon from '~/images/md-icons/thumb_down/materialicons/24px.svg';
import CallIcon from '~/images/md-icons/call/materialicons/24px.svg';
import PhoneCallbackIcon from '~/images/md-icons/phone_callback/materialicons/24px.svg';
import EventAvailableIcon from '~/images/md-icons/event_available/materialicons/24px.svg';

export default ({statuses, ...rest}) => {
  let icon = null;

  if (
    statuses.includes(ROB_TASK_STATUS.Approved) ||
    statuses.includes(ROB_TASK_STATUS.CancellationApproved)
  ) {
    icon = <ThumbUpIcon fill="#299976" {...rest} />;
  }
  if (
    statuses.includes(ROB_TASK_STATUS.Denied) ||
    statuses.includes(ROB_TASK_STATUS.CancellationDenied)
  ) {
    icon = <ThumbDownIcon fill="#972727" {...rest} />;
  } else if (statuses.includes(ROB_TASK_STATUS.CallLessor)) {
    icon = <CallIcon fill="#972727" {...rest} />;
  } else if (statuses.includes(ROB_TASK_STATUS.LessorWillCall)) {
    icon = <PhoneCallbackIcon fill="#972727" {...rest} />;
  }

  if (statuses.includes(ROB_TASK_STATUS.ProvisionallyApproved)) {
    icon = <EventAvailableIcon fill="#299976" {...rest} />;
  }

  return icon;
};
