import {useContext} from 'react';
import _ from 'lodash';

import SessionStateContext from '../components/context/SessionStateContext';
import DispatchContext from '../components/context/DispatchContext';

export const useSelector = (selector) => {
  const sessionState = useContext(SessionStateContext);
  return selector(sessionState);
};

let dispatchFunc = _.noop;
const createDispatchFunc = (dispatch) => {
  if (dispatch && dispatchFunc === _.noop) {
    dispatchFunc = (action) => {
      if (_.isFunction(action)) {
        const exec = async () => {
          await action(dispatch);
        };

        exec();
      } else {
        dispatch(action);
      }
    };
  }

  return dispatchFunc;
};

export const useDispatch = () => {
  const dispatch = useContext(DispatchContext);
  return createDispatchFunc(dispatch);
};
