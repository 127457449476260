import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {View} from 'react-native';
import {useIsFocused} from '@react-navigation/native';
import moment from 'moment';
import {RecyclerListView, DataProvider, LayoutProvider} from 'recyclerlistview';
import _ from 'lodash';

import styles from './styles';
import sy from '~/styles';
import {Text, FloatingAddButton} from '~/components/controls';
import {DealerHeader} from '~/components/shared';
import {DateText, Day, Tile} from '~/components/modules/Calendar';
import {service_request as serviceRequestApi} from '~/api/private';
import UserContext from '~/components/context/UserContext';
import {useDispatch, useSelector} from '~/lib/hooks';
import {throttle} from '~/lib/utils';
import {
  serviceRequestLastFetch,
  setAppIsLoading,
  setAppDoneLoading,
  setCalendarCtx,
} from '~/actions';
import withDimensions from '~/components/hoc/with-dimensions';

const SECTION_TITLE_HEIGHT = 48;
const TILE_HEIGHT = 93;
const NO_REQUESTS_HEIGHT = 57;

const expensiveFetch = throttle(async () => {
  const {result} = await serviceRequestApi.requests();
  return result;
});

class List extends React.Component {
  constructor(props) {
    super(props);
    this.ref = null;
  }

  render() {
    const {saveOffset, forwardRef, ...rest} = this.props;

    return (
      <RecyclerListView
        ref={(ref) => {
          forwardRef.current = ref;
          this.ref = ref;
        }}
        {...rest}
      />
    );
  }

  componentWillUnmount() {
    this.props.saveOffset.current = this.ref.getCurrentScrollOffset();
  }
}

const Calendar = ({dimensions}) => {
  const isFocused = useIsFocused();

  const dispatch = useDispatch();

  const {window} = dimensions;
  const {height} = window;
  const width = Math.min(window.width, 412);
  const breadth = width / 7;

  const [visibleIndex, setVisibleIndex] = useState(null);
  const [listOffset, setListOffset] = useState(null);
  const [, setCounter] = useState(0);

  const requests = useRef([]);
  const dates = useRef([]);
  const sections = useRef(null);

  const listViewRef = useRef(null);
  const saveOffset = useRef(null);
  const weekListViewRef = useRef(null);
  const weekDataProvider = useRef(new DataProvider((r1, r2) => r1 !== r2));
  const listDataProvider = useRef(new DataProvider((r1, r2) => r1 !== r2));

  const {me} = useContext(UserContext);

  const forceFetch = useSelector((state) => state.serviceRequestInsert);
  const forceRefresh = useSelector((state) => state.serviceRequestUpdate);
  const lastFetch = useSelector((state) => state.serviceRequestLastFetch);

  const calendar_ctx = useRef();
  calendar_ctx.current = useSelector((state) => state.calendar_ctx);

  const layoutProvider = useMemo(() => {
    const provider = new LayoutProvider(
      (index) => index,
      (index, dim) => {
        if (!sections.current?.[index]) {
          dim.width = 0;
          dim.height = 0;
          return;
        }

        const {is_header, is_empty} = sections.current[index];

        const height =
          (is_header ? SECTION_TITLE_HEIGHT : 0) +
          (is_empty ? NO_REQUESTS_HEIGHT : TILE_HEIGHT);

        dim.width = width;
        dim.height = height;
      },
    );

    provider.shouldRefreshWithAnchoring = false;
    return provider;
  }, [width]);

  const weekLayoutProvider = useMemo(
    () =>
      new LayoutProvider(
        (index) => index,
        (type, dim) => {
          dim.width = breadth;
          dim.height = breadth * 2;
        },
      ),
    [breadth],
  );

  const forceRerender = useCallback(() => {
    setCounter((counter) => counter + 1);

    weekListViewRef.current?.forceRerender();
    listViewRef.current?.forceRerender();
  }, []);

  const createSections = useCallback(() => {
    dates.current = [];
    let data = [];

    const start = moment.min(
      moment(requests.current?.[0]?.date ?? new Date(), 'YYYY-MM-DD').startOf(
        'week',
      ),
      moment().add(-1, 'months').startOf('week'),
    );
    const end = moment.max(
      moment(
        requests.current?.[requests.current.length - 1]?.date ?? new Date(),
        'YYYY-MM-DD',
      ).endOf('week'),
      moment().add(1, 'months').endOf('week'),
    );

    while (start.isSameOrBefore(end, 'date')) {
      const key = start.format('YYYY-MM-DD');

      const section = requests.current?.filter((item) => item.date === key);
      if (section.length > 0) {
        section[0] = {
          ...section[0],
          is_header: true,
        };

        data = [...data, ...section];
      } else {
        data = [
          ...data,
          {
            date: key,
            is_header: true,
            is_empty: true,
          },
        ];
      }

      dates.current.push(moment(key, 'YYYY-MM-DD'));

      start.add(1, 'day');
    }

    sections.current = data;

    listDataProvider.current = listDataProvider.current.cloneWithRows(
      sections.current,
    );
    weekDataProvider.current = weekDataProvider.current.cloneWithRows(
      dates.current,
    );
  }, []);

  useEffect(() => {
    dispatch(setAppIsLoading());

    const fetch = async () => {
      requests.current = await expensiveFetch();
      createSections();

      const key = moment().format('YYYY-MM-DD');

      let todayOffset = null;
      if (_.isNumber(calendar_ctx?.current?.offset)) {
        todayOffset = calendar_ctx.current?.offset;
      } else {
        todayOffset = 0;

        const todayIndex = sections.current.findIndex(
          (request) => request.is_header && request.date === key,
        );

        for (let i = 0; i < todayIndex; i++) {
          const {is_header, is_empty} = sections.current[i];
          todayOffset +=
            (is_header ? SECTION_TITLE_HEIGHT : 0) +
            (is_empty ? NO_REQUESTS_HEIGHT : TILE_HEIGHT);
        }
      }

      setListOffset(todayOffset);

      const dateIndex = dates.current.findIndex(
        (date) => date.format('YYYY-MM-DD') === key,
      );
      setVisibleIndex(dateIndex);

      dispatch(setAppDoneLoading());
      forceRerender();
    };

    fetch();

    return () => {
      dispatch(setAppDoneLoading());
    };
  }, [me, dispatch, calendar_ctx, forceRerender, createSections]);

  useEffect(() => {
    const fetch = async () => {
      requests.current = await expensiveFetch();
      createSections();

      forceRerender();
    };

    fetch();
  }, [calendar_ctx, isFocused, forceFetch, createSections, forceRerender]);

  useEffect(() => {
    let changed = false;

    const data = requests.current ?? [];
    for (const request of data) {
      const request_id = request.id;

      const last_fetch_date = moment(lastFetch[request_id] ?? new Date(0));
      const update_date = moment(
        forceRefresh[request_id]?.timestamp ?? new Date(0),
      );

      if (update_date.isAfter(last_fetch_date, 'seconds')) {
        const {status, rob_status, rob_invoicing_status} =
          forceRefresh[request_id];

        if (status) {
          request.status = status;
          request.rob_status = rob_status;
          request.rob_invoicing_status = rob_invoicing_status;

          // Update the last fetch date.
          // Conceptiually not very elegant, better to really do a fetch. But for now this is acceptable.
          dispatch(serviceRequestLastFetch(request_id));
          changed = true;

          console.log(
            'Refreshing service_request',
            request,
            last_fetch_date,
            update_date,
          );
        }
      }
    }

    if (changed) {
      requests.current = data;
      createSections();

      forceRerender();
    }
  }, [
    requests,
    forceRefresh,
    lastFetch,
    dispatch,
    forceRerender,
    createSections,
  ]);

  useEffect(() => {
    if (visibleIndex === null) {
      return;
    }

    const layoutManager =
      weekListViewRef.current?._virtualRenderer.getLayoutManager();
    if (layoutManager) {
      weekListViewRef.current?.scrollToIndex(visibleIndex - (visibleIndex % 7));
      forceRerender();
    }
  }, [visibleIndex, forceRerender]);

  useEffect(() => {
    return () => {
      if (saveOffset.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dispatch(setCalendarCtx(saveOffset.current));
      }
    };
  }, [saveOffset, dispatch]);

  const onScroll = useCallback(
    (e) => {
      const {contentOffset, contentSize} = e.nativeEvent;
      const index =
        contentOffset.y < contentSize.height - height
          ? listViewRef.current?.findApproxFirstVisibleIndex()
          : listDataProvider.current?.getAllData().length - 1;

      const key = sections.current[index]?.date;
      const dateIndex = dates.current.findIndex(
        (date) => date.format('YYYY-MM-DD') === key,
      );
      setVisibleIndex(dateIndex);
    },
    [height],
  );

  const onWeekDayRenderer = useCallback(
    (dataIndex, date) => (
      <View style={{flex: 1}}>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            width: breadth,
            height: breadth,
          }}>
          <Text
            style={[
              sy.smallPlus,
              {color: '#828282', textTransform: 'capitalize'},
            ]}>
            {date.format('dd')[0]}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            width: breadth,
            height: breadth,
          }}>
          <Day
            selected={visibleIndex === dataIndex}
            selectedDealers={me?.dealer_selection}
            date={date.clone()}
            onChangeDate={() => {
              const key = date.format('YYYY-MM-DD');
              const listIndex = sections.current.findIndex(
                (request) => request.is_header && request.date === key,
              );

              listViewRef.current.scrollToIndex(listIndex, false);
            }}
          />
        </View>
      </View>
    ),
    [me, visibleIndex, breadth],
  );

  const onSectionRenderer = useCallback((type, data) => {
    const {is_header, is_empty, ...order} = data;
    return (
      <>
        {is_header && (
          <View style={[styles.sectionHeader, {height: SECTION_TITLE_HEIGHT}]}>
            <DateText date={moment(order.date, 'YYYY-MM-DD')} />
          </View>
        )}
        {is_empty ? (
          <View style={{height: NO_REQUESTS_HEIGHT}}>
            <Text
              style={{
                color: '#828282',
                backgroundColor: '#ffffff',
                padding: 16,
                borderBottomColor: '#DCDCDC',
                borderBottomWidth: 1,
              }}>
              Geen aanvragen
            </Text>
          </View>
        ) : (
          <Tile style={{height: TILE_HEIGHT}} key={order.id} {...order} />
        )}
      </>
    );
  }, []);

  return (
    <>
      <View style={sy.mainView}>
        {sections.current?.length > 0 && (
          <>
            <View style={[sy.headerView, styles.headerView]}>
              <View style={{paddingHorizontal: 16}}>
                <DealerHeader
                  titleStyle={{textTransform: 'capitalize'}}
                  title={
                    visibleIndex
                      ? dates.current[visibleIndex]?.format('MMMM')
                      : ''
                  }
                />
              </View>
              <View>
                <View
                  style={{
                    flexDirection: 'row',
                    minHeight: breadth * 2,
                  }}>
                  <RecyclerListView
                    ref={weekListViewRef}
                    dataProvider={weekDataProvider.current}
                    layoutProvider={weekLayoutProvider}
                    rowRenderer={onWeekDayRenderer}
                    isHorizontal={true}
                    initialRenderIndex={visibleIndex - (visibleIndex % 7)}
                  />
                </View>
              </View>
            </View>
            <View style={[sy.contentView, styles.contentView]}>
              <List
                key={listOffset}
                forwardRef={listViewRef}
                saveOffset={saveOffset}
                dataProvider={listDataProvider.current}
                layoutProvider={layoutProvider}
                rowRenderer={onSectionRenderer}
                scrollViewProps={{showsVerticalScrollIndicator: false}}
                scrollThrottle={100}
                onScroll={onScroll}
                renderAheadOffset={height / SECTION_TITLE_HEIGHT}
                initialOffset={listOffset}
              />
            </View>

            <FloatingAddButton />
          </>
        )}
      </View>
    </>
  );
};

export default withDimensions(Calendar);
