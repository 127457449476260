import React, {useState} from 'react';
import {View} from 'react-native';
import _ from 'lodash';
import {useNavigation} from '@react-navigation/native';

import {Pressable, Dialog, Link, Text} from '~/components/controls';

import sy from '~/styles';

import {EXPIRED_STATUS} from '~/types/statuses';

import WarningIcon from '~/images/md-icons/warning/materialicons/24px.svg';
import WarningAmberIcon from '~/images/md-icons/warning_amber/materialicons/24px.svg';

const ExpiredWarning = ({style, type, count}) => {
  const navigation = useNavigation();
  const [showDialog, setShowDialog] = useState(false);

  if (!count) {
    return <></>;
  }

  return (
    <>
      <Pressable
        style={style}
        onPress={() => setShowDialog((prevState) => !prevState)}>
        {type === EXPIRED_STATUS.EXPIRED_30_DAYS ? (
          <WarningAmberIcon fill="#FF6C00" />
        ) : (
          <WarningIcon fill="#972727" />
        )}
      </Pressable>
      <Dialog
        title={
          type === EXPIRED_STATUS.EXPIRED_30_DAYS
            ? 'Ouder dan 30 dagen'
            : 'Ouder dan 60 dagen'
        }
        titleIcon={
          type === EXPIRED_STATUS.EXPIRED_30_DAYS ? (
            <WarningAmberIcon fill="#FF6C00" />
          ) : (
            <WarningIcon fill="#972727" />
          )
        }
        visible={showDialog}
        onDismiss={() => setShowDialog(false)}>
        <View style={[sy['py-4'], sy['gap-4']]}>
          {type === EXPIRED_STATUS.EXPIRED_30_DAYS ? (
            <>
              <Link
                onPress={() =>
                  navigation.navigate('Search', {
                    status: EXPIRED_STATUS.EXPIRED_30_DAYS,
                    type: 'EXPIRED',
                  })
                }>
                Er zijn opdrachten die tenminste 30 dagen niet gewijzigd zijn.
              </Link>
              <Text>
                Na 60 dagen zonder opvolging is het verzenden van nieuwe
                opdrachten naar ROB-Net niet meer mogelijk.
              </Text>
            </>
          ) : (
            <>
              <Text>
                Het verzenden van nieuwe opdrachten naar ROB-Net is momenteel
                niet mogelijk.
              </Text>
              <Text>
                Opvolging van opdrachten die tenminste 60 dagen niet gewijzigd
                zijn noodzakelijk.
              </Text>
              <Link
                onPress={() =>
                  navigation.navigate('Search', {
                    status: EXPIRED_STATUS.EXPIRED_60_DAYS,
                    type: 'EXPIRED',
                  })
                }>
                Check oude opdrachten
              </Link>
            </>
          )}
        </View>
      </Dialog>
    </>
  );
};

export default ExpiredWarning;
