import React, {useState, useCallback, useRef, useEffect} from 'react';
import {View, ActivityIndicator, ScrollView, TextInput} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';

import sy from '~/styles';
import {
  Pressable,
  Text,
  Modal,
  Checkbox,
  PrimaryButton,
  Dialog,
} from '~/components/controls';
import Format from '~/lib/format';

import CloseIcon from '~/images/md-icons/close/materialicons/24px.svg';
import SearchIcon from '~/images/md-icons/search/materialicons/24px.svg';

const HighlightText = ({text, highlight}) => {
  const position = text.toLowerCase().indexOf(highlight.toLowerCase());

  return (
    <>
      {position === -1 ? (
        <Text>{text}</Text>
      ) : (
        <>
          {position > 0 && <Text>{text.substring(0, position)}</Text>}
          <Text style={sy.mediumBold}>
            {text.substring(position, position + highlight.length)}
          </Text>
          {position + highlight.length < text.length && (
            <Text>{text.substring(position + highlight.length)}</Text>
          )}
        </>
      )}
    </>
  );
};

const SearchWorkModal = ({
  works,
  visible,
  onPrefixChange,
  onAdd,
  onDismiss,
}) => {
  const [prefix, setPrefix] = useState('');
  const [selectedWorks, setSelectedWorks] = useState([]);

  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [highlighSearchText, setHighlightSearchText] = useState('');
  const [showCancelAddDialog, setShowCancelAddDialog] = useState(false);

  const searchTextRef = useRef(null);

  useEffect(() => {
    setTimeout(() => searchTextRef.current.focus(), 100);
  }, []);

  const onClose = () => {
    if (selectedWorks.length) {
      setShowCancelAddDialog(true);
    } else {
      onDismiss();
    }
  };

  const onSearchWork = useCallback(
    _.debounce(async (value) => {
      if (value.length < 3) {
        setResults([]);
        return;
      }

      setSearching(true);
      try {
        const {result, success} = await onPrefixChange(value);

        if (searchTextRef.current?.value === value && success) {
          setResults(result);
          return;
        }
      } finally {
        setSearching(false);
      }

      setResults([]);
    }, 500),
    [onPrefixChange],
  );

  return (
    <>
      <Modal visible={visible} onDismiss={onDismiss}>
        <View
          style={[
            {height: 64},
            sy['flex-row'],
            sy['border-b'],
            sy['border-gray'],
            sy['items-center'],
          ]}>
          <Pressable style={[sy['p-4']]} onPress={onClose}>
            <CloseIcon />
          </Pressable>
          <View style={[sy['flex-1'], sy['justify-center'], sy['mr-14']]}>
            <Text style={[sy['text-center']]}>TecRMI taak toevoegen</Text>
          </View>
        </View>
        <View
          style={[
            sy['flex-row'],
            sy['p-2'],
            sy['m-4'],
            sy['items-center'],
            {
              borderRadius: 10,
              backgroundColor: '#EBEBEB',
            },
          ]}>
          <SearchIcon
            fill={prefix.length ? '#4A4A49' : '#828282'}
            width={18}
            height={18}
          />
          <TextInput
            ref={(ref) => {
              searchTextRef.current = ref;
              ref?.focus();
            }}
            style={[
              sy.regular,
              sy['flex-1'],
              sy['ml-8'],
              {
                color: prefix?.length > 0 ? '#4A4A49' : '#828282',
              },
            ]}
            placeholder="Zoeken"
            value={prefix}
            onChangeText={(value) => {
              onSearchWork.cancel();
              onSearchWork(value);
              setHighlightSearchText(value);
              setPrefix(value);
            }}
          />
        </View>
        <Divider />
        <ScrollView style={[sy['bg-gray95']]}>
          {searching ? (
            <View style={[sy['p-4']]}>
              <ActivityIndicator size="large" color="#231fda" />
            </View>
          ) : (
            results.map((result) => (
              <View
                key={result.work_id}
                style={[sy['bg-white'], sy['flex-row'], sy['items-center']]}>
                <Pressable
                  style={[sy['p-4'], sy['pr-8']]}
                  disabled={works.some(
                    ({work_id}) => work_id === result.work_id,
                  )}
                  onPress={() => {
                    if (
                      selectedWorks.some(
                        (item) => item.work_id === result.work_id,
                      )
                    ) {
                      setSelectedWorks(
                        selectedWorks.filter(
                          (item) => item.work_id !== result.work_id,
                        ),
                      );
                    } else {
                      setSelectedWorks([...(selectedWorks ?? []), result]);
                    }
                  }}>
                  <Checkbox
                    style={{width: 'auto'}}
                    disabled={works.some(
                      ({work_id}) => work_id === result.work_id,
                    )}
                    checked={[...(works ?? []), ...selectedWorks].some(
                      (item) => item.work_id === result.work_id,
                    )}
                  />
                </Pressable>
                <View
                  style={[
                    sy['flex-row'],
                    sy['py-4'],
                    sy['border-b'],
                    sy['border-lightgray'],
                    sy['flex-1'],
                    sy['pr-4'],
                    sy['gap-4'],
                  ]}>
                  <View style={[sy['flex-1']]}>
                    <View style={{display: 'inline'}}>
                      <HighlightText
                        text={result.work_text}
                        highlight={highlighSearchText}
                      />
                    </View>
                    {result.qualifier && result.qualifier !== 'Standaard' && (
                      <Text style={[sy.small, sy['text-gray']]}>
                        {result.qualifier}
                      </Text>
                    )}
                    {result.add_text && result.add_text !== 'Standaard' && (
                      <Text style={[sy.small, sy['text-gray']]}>
                        {result.add_text}
                      </Text>
                    )}
                  </View>
                  <View>
                    <Text>{Format.number(result.work_time, 2)}&nbsp;u</Text>
                  </View>
                </View>
              </View>
            ))
          )}
        </ScrollView>
        <Divider />
        <View style={[sy['p-4'], sy['flex-row'], sy['justify-end']]}>
          <PrimaryButton
            disabled={!selectedWorks?.length}
            onPress={() => onAdd(selectedWorks)}>
            Toevoegen
            {selectedWorks?.length > 0 ? (
              <>&nbsp;({selectedWorks?.length})</>
            ) : (
              <></>
            )}
          </PrimaryButton>
        </View>
      </Modal>
      <Dialog
        visible={showCancelAddDialog}
        title="Selectie niet toevoegen?"
        onDismiss={() => setShowCancelAddDialog(false)}
        buttons={[
          {
            text: 'Annuleren',
            onPress: () => setShowCancelAddDialog(false),
          },
          {
            text: 'Niet toevoegen',
            onPress: onDismiss,
          },
        ]}>
        <View style={[sy['py-4']]}>
          <Text>
            Weet je zeker dat je dit venster wil sluiten zonder de selectie toe
            te voegen?
          </Text>
        </View>
      </Dialog>
    </>
  );
};

export default SearchWorkModal;
