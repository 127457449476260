import _ from 'lodash';

function price(input) {
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(input);
}

function number(input, digits = 0, useGrouping = false, options = {}) {
  if (_.isNull(input) || _.isUndefined(input) || _.isNaN(input)) {
    return '';
  }

  return new Intl.NumberFormat('nl-NL', {
    style: 'decimal',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    useGrouping,
    ...options,
  }).format(input);
}

function license(value) {
  if (!value) {
    return value;
  }

  return value
    .match(/[a-zA-Z]+|[0-9]+/g)
    .join('|') // This character was chosen randomly because it doesn't really matter as long it's not alphanumeric.
    .match(/[a-zA-Z]{2}(?=[a-zA-Z]{2})|[0-9]{2}(?=[0-9]{2})|[a-zA-Z0-9]+/g) // Also split 4 consecutive characters.
    .join('-')
    .toUpperCase();
}

function address(value) {
  if (typeof value === 'string') {
    return value;
  }

  return `${value.postalCode} ${value.houseNumber} ${value.annex || ''}`.trim();
}

function round(value, precision = 2) {
  const factor = Math.pow(10, precision);
  return Math.round(value * factor) / factor;
}

export default {
  price,
  number,
  license,
  address,
  round,
};
