import React, {useState, useCallback, useEffect, useContext} from 'react';
import {View} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import UserContext from '~/components/context/UserContext';
import {useDispatch, useSelector} from '~/lib/hooks';
import {user as userApi} from '~/api/private';
import {setUserSettings} from '~/actions';
import {SOURCES} from '~/types/sources';

import {
  Modal,
  Pressable,
  Text,
  Link,
  RadioButton,
  ActionButton,
  Grouping,
  Switch,
} from '~/components/controls';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import withDimensions from '~/components/hoc/with-dimensions';

import ChevronLeftIcon from '~/images/md-icons/chevron_left/materialicons/24px.svg';
import SettingsIcon from '~/images/md-icons/settings/materialicons/24px.svg';
import LocalShippingIcon from '~/images/md-icons/local_shipping/materialicons/24px.svg';
import NotificationsIcon from '~/images/md-icons/notifications/materialicons/24px.svg';
import SettingsApplicationsIcon from '~/images/md-icons/settings_applications/materialicons/24px.svg';

const InitialTabActionsheet = ({
  user_settings,
  visible,
  onDismiss,
  onRefresh,
}) => (
  <Actionsheet visible={visible} onDismiss={onDismiss}>
    <Title>
      <Text style={[sy.largePlus, {lineHeight: 30}]}>Startpagina</Text>
    </Title>
    <Divider />
    <Content style={{padding: 0}}>
      <Pressable
        style={[sy['p-4'], sy['flex-row'], sy['items-center'], sy['gap-8']]}
        onPress={async () => {
          await userApi.update_settings({
            ...user_settings,
            initial_tab: 'Messages',
          });

          onRefresh();
        }}>
        <RadioButton checked={user_settings.initial_tab === 'Messages'} />
        <Text>Berichten</Text>
      </Pressable>
      <Divider style={[sy['ml-18'], sy['bg-gray95']]} />
      <Pressable
        style={[sy['p-4'], sy['flex-row'], sy['items-center'], sy['gap-8']]}
        onPress={async () => {
          await userApi.update_settings({
            ...user_settings,
            initial_tab: 'Search',
          });

          onRefresh();
        }}>
        <RadioButton checked={user_settings.initial_tab === 'Search'} />
        <Text>Zoeken</Text>
      </Pressable>
      <Divider style={[sy['ml-18'], sy['bg-gray95']]} />
      <Pressable
        style={[sy['p-4'], sy['flex-row'], sy['items-center'], sy['gap-8']]}
        onPress={async () => {
          await userApi.update_settings({
            ...user_settings,
            initial_tab: 'Calendar',
          });

          onRefresh();
        }}>
        <RadioButton checked={user_settings.initial_tab === 'Calendar'} />
        <Text>Kalender</Text>
      </Pressable>
    </Content>
    <Divider />
    <Actions>
      <ActionButton onPress={onDismiss}>OK</ActionButton>
    </Actions>
  </Actionsheet>
);

const TITLE_HEIGHT_IN_PX = 71;
const ACTIONS_HEIGHT_IN_PX = 51;

const DefaultDealerActionsheet = withDimensions(
  ({dimensions, dealers, visible, onDismiss, onRefresh}) => (
    <Actionsheet visible={visible} onDismiss={onDismiss}>
      <Title>
        <Text style={[sy.largePlus, {lineHeight: 30}]}>Standaardvestiging</Text>
      </Title>
      <Divider />
      <Content
        style={{
          padding: 0,
          maxHeight:
            dimensions.window.height -
            TITLE_HEIGHT_IN_PX -
            ACTIONS_HEIGHT_IN_PX,
        }}>
        {dealers
          .sort((a, b) => a.city.localeCompare(b.city))
          .map((dealer, index, arr) => (
            <React.Fragment key={dealer.id}>
              <Pressable
                style={[
                  sy['p-4'],
                  sy['flex-row'],
                  sy['items-center'],
                  sy['gap-4'],
                ]}
                onPress={async () => {
                  await userApi.update({default_dealer: dealer.id});
                  onRefresh();
                }}>
                <RadioButton checked={dealer.is_default} />
                <Text>{dealer.city}</Text>
              </Pressable>
              {index < arr.length - 1 && (
                <Divider style={[sy['bg-gray95'], sy['mx-4']]} />
              )}
            </React.Fragment>
          ))}
      </Content>
      <Divider />
      <Actions>
        <ActionButton onPress={onDismiss}>OK</ActionButton>
      </Actions>
    </Actionsheet>
  ),
);

const SettingsModal = ({visible, onDismiss}) => {
  const dispatch = useDispatch();
  const {me, update: refreshMe} = useContext(UserContext);
  const [articleSources, setArticleSources] = useState([SOURCES.Fource]);

  useEffect(() => {
    const {dealers} = me ?? {};

    if (dealers?.some((dealer) => !!dealer.package_access?.aag?.token)) {
      setArticleSources((prevState) =>
        prevState.includes(SOURCES.PartsPoint)
          ? prevState
          : [...prevState, SOURCES.PartsPoint],
      );
    }
  }, [me]);

  const refresh = useCallback(() => {
    const fetch = async () => {
      const {user_settings} = await userApi.me();
      dispatch(setUserSettings(user_settings?.partner ?? {}));
    };

    fetch();
  }, [dispatch]);

  const [showInitialTabActionsheet, setShowInitialTabActionsheet] =
    useState(false);
  const [showDefaultDealerActionsheet, setShowDefaultDealerActionsheet] =
    useState(false);

  const user_settings = useSelector((state) => state.user_settings) ?? {};
  const default_dealer = me?.dealers?.find((dealer) => dealer.is_default);

  return (
    <>
      <InitialTabActionsheet
        user_settings={user_settings}
        visible={showInitialTabActionsheet}
        onDismiss={() => setShowInitialTabActionsheet(false)}
        onRefresh={refresh}
      />
      <DefaultDealerActionsheet
        dealers={me?.dealers ?? []}
        visible={showDefaultDealerActionsheet}
        onDismiss={() => setShowDefaultDealerActionsheet(false)}
        onRefresh={() => {
          refreshMe();
        }}
      />
      <Modal visible={visible} onDismiss={onDismiss}>
        <View
          style={[
            {height: 64},
            sy['flex-row'],
            sy['items-center'],
            sy['gap-8'],
          ]}>
          <Pressable style={[sy['p-4']]} onPress={onDismiss}>
            <ChevronLeftIcon fill="#4a4a49" />
          </Pressable>
          <View
            style={[sy['flex-1'], sy['items-center'], sy['justify-center']]}>
            <Text>Instellingen</Text>
          </View>
          <View style={[sy['p-4']]}>
            <SettingsIcon fill="#4a4a49" />
          </View>
        </View>
        <View style={[sy['flex-1'], sy['bg-gray95']]}>
          <Grouping
            icon={<SettingsApplicationsIcon fill="#4a4a49" />}
            header="Algemeen"
            initial={{expand: true}}>
            <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
              <Text>Startpagina</Text>
              <Link onPress={() => setShowInitialTabActionsheet(true)}>
                {user_settings.initial_tab === 'Messages' ? (
                  <>Berichten</>
                ) : user_settings.initial_tab === 'Calendar' ? (
                  <>Kalender</>
                ) : (
                  <>Zoeken</>
                )}
              </Link>
            </View>
            <Divider style={sy['bg-gray95']} />
            <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
              <Text>Standaardvestiging</Text>
              <Link onPress={() => setShowDefaultDealerActionsheet(true)}>
                {default_dealer?.city ?? 'Keuze maken'}
              </Link>
            </View>
          </Grouping>
          <Grouping
            icon={<LocalShippingIcon fill="#4a4a49" />}
            header="Artikelen"
            initial={{expand: true}}>
            <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
              <View>
                <Text>Fource</Text>
              </View>
              <Switch
                disabled={true}
                value={articleSources.includes(SOURCES.Fource)}
              />
            </View>
            <Divider style={sy['bg-gray95']} />
            <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
              <View>
                <Text>PartsPoint</Text>
              </View>
              <Switch
                disabled={true}
                value={articleSources.includes(SOURCES.PartsPoint)}
              />
            </View>
          </Grouping>

          <Grouping
            icon={<NotificationsIcon fill="#4a4a49" />}
            header="Meldingen"
            initial={{expand: true}}>
            <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
              <View>
                <Text>Doorbelasting kosten TecRMI</Text>
                <Text style={[sy.small, sy['text-lightgray']]}>
                  Pop-up tonen
                </Text>
              </View>
              <Switch
                onValueChange={async () => {
                  await userApi.update_settings({
                    ...user_settings,
                    tecrmi_info_dialog_never_show:
                      !user_settings.tecrmi_info_dialog_never_show,
                  });

                  refresh();
                }}
                value={!user_settings.tecrmi_info_dialog_never_show}
              />
            </View>
            <Divider style={sy['bg-gray95']} />
            <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
              <View>
                <Text>Notificatie onderdeel apart toegevoegd</Text>
                <Text style={[sy.small, sy['text-lightgray']]}>
                  Pop-up tonen
                </Text>
              </View>
              <Switch
                onValueChange={async () => {
                  await userApi.update_settings({
                    ...user_settings,
                    never_show_parts_split_dialog:
                      !user_settings.never_show_parts_split_dialog,
                  });

                  refresh();
                }}
                value={!user_settings.never_show_parts_split_dialog}
              />
            </View>
            <Divider style={sy['bg-gray95']} />
            <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
              <View>
                <Text>Vraag tonen extra services onderhoud</Text>
                <Text style={[sy.small, sy['text-lightgray']]}>
                  Pop-up tonen
                </Text>
              </View>
              <Switch
                onValueChange={async () => {
                  await userApi.update_settings({
                    ...user_settings,
                    never_show_maintenance_extras_dialog:
                      !user_settings.never_show_maintenance_extras_dialog,
                  });

                  refresh();
                }}
                value={!user_settings.never_show_maintenance_extras_dialog}
              />
            </View>
            <Divider style={sy['bg-gray95']} />
            <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
              <View>
                <Text>Bevestigen laten vervallen activiteit</Text>
                <Text style={[sy.small, sy['text-lightgray']]}>
                  Pop-up tonen
                </Text>
              </View>
              <Switch
                onValueChange={async () => {
                  await userApi.update_settings({
                    ...user_settings,
                    never_show_rob_task_cancel_dialog:
                      !user_settings.never_show_rob_task_cancel_dialog,
                  });

                  refresh();
                }}
                value={!user_settings.never_show_rob_task_cancel_dialog}
              />
            </View>
          </Grouping>
        </View>
      </Modal>
    </>
  );
};

export default SettingsModal;
