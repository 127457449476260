import React from 'react';
import {ScrollView} from 'react-native';
import {
  Dialog as PaperDialog,
  Modal as PaperModal,
  Portal,
} from 'react-native-paper';

import sy from '~/styles';
import styles from './styles';
import withDimensions from '~/components/hoc/with-dimensions';

export const Title = ({style, children}) => (
  <PaperDialog.Title
    style={[
      {display: 'flex', minHeight: 30},
      sy['mx-4'],
      sy['my-5'],
      sy['flex'],
      sy['items-center'],
      style,
    ]}>
    {children}
  </PaperDialog.Title>
);

export const Content = withDimensions(({dimensions, style, children}) => {
  return (
    <PaperDialog.Content style={style}>
      <ScrollView contentContainerStyle={{maxHeight: dimensions.window.height}}>
        {children}
      </ScrollView>
    </PaperDialog.Content>
  );
});

export const Actions = ({style, children}) => (
  <PaperDialog.Actions style={[sy['gap-2'], style]}>
    {children}
  </PaperDialog.Actions>
);

const Actionsheet = ({
  dimensions,
  dismissable = true,
  visible,
  style,
  children,
  onDismiss,
}) => (
  <Portal>
    <PaperModal
      contentContainerStyle={[
        styles.actionsheet,
        style,
        {maxHeight: dimensions.window.height},
      ]}
      style={[sy['justify-end'], style, {margin: 0}]}
      visible={visible}
      dismissable={dismissable}
      onDismiss={dismissable ? onDismiss : null}>
      <ScrollView>{children}</ScrollView>
    </PaperModal>
  </Portal>
);
export default withDimensions(Actionsheet);
