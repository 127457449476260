import React, {useEffect} from 'react';
import {View} from 'react-native';

import authentication from '~/lib/authentication';
import sy from '~/styles';
import {PrimaryButton} from '~/components/controls';
import {useDispatch} from '~/lib/hooks';
import {setAppDoneLoading} from '~/actions';
import Logo from './logo.svg';
import LoginIcon from '~/images/md-icons/login/materialicons/24px.svg';

const SignIn = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAppDoneLoading());
    authentication.loginInit();
  }, [dispatch]);

  const onLogin = async () => {
    const success = await authentication.login();
    if (success) {
      window.location.href = '/';
    }
  };

  return (
    <View style={[sy.mainView, sy['items-center']]}>
      <Logo />
      <PrimaryButton
        icon={<LoginIcon fill="#ffffff" width={18} height={18} />}
        onPress={onLogin}>
        Inloggen
      </PrimaryButton>
    </View>
  );
};

export default SignIn;
