import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import _ from 'lodash';

import sy from '~/styles';
import {dealer as dealerApi} from '~/api/private';
import {Dialog, Select} from '~/components/controls';

const DealerUserDialog = ({
  visible,
  dealer_id,
  assigned_user_id,
  onDismiss,
  onOK,
}) => {
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState({value: assigned_user_id});

  useEffect(() => {
    const fetch = async () => {
      const result = await dealerApi.users(dealer_id);
      setUsers(result.map((item) => ({text: item.name, value: item.id})));
    };

    fetch();
  }, [dealer_id]);

  return (
    <Dialog
      visible={visible}
      title="Gebruiker toewijzen"
      onDismiss={onDismiss}
      buttons={[
        {
          text: 'Annuleren',
          onPress: onDismiss,
        },
        {
          text: 'OK',
          disabled: !selected,
          onPress: () => onOK(selected.value),
        },
      ]}>
      <View style={[sy['py-4'], sy['gap-4']]}>
        <Select
          label="Gebruiker"
          value={selected?.value}
          options={users}
          onChange={setSelected}
        />
      </View>
    </Dialog>
  );
};

export default DealerUserDialog;
