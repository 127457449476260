import React, {useEffect, useState} from 'react';
import _ from 'lodash';

import {service_request as serviceRequestApi} from '~/api/private';

const SlaArticleText = ({
  lessor,
  description,
  attributes,
  specifications,
  request_id,
  itemMpId,
}) => {
  const [viscosity, setViscosity] = useState(specifications?.oil?.viscosity);

  useEffect(() => {
    const fetch = async () => {
      const {result} = await serviceRequestApi.part_specifications(
        request_id,
        itemMpId,
      );

      const {engine_oil} = result ?? {};

      if (
        engine_oil?.length === 1 &&
        engine_oil[0].text.split('|').length === 1
      ) {
        setViscosity(result.engine_oil[0].text);
      }
    };

    if (!viscosity && request_id && itemMpId) {
      fetch();
    }
  }, [request_id, itemMpId, viscosity]);

  return (
    <>
      SLA
      {description && lessor ? (
        <>&nbsp;&middot;&nbsp;{attributes?.option_id ? lessor : description}</>
      ) : null}
      {viscosity && (
        <>
          &nbsp;&middot;&nbsp;
          {viscosity}
        </>
      )}
    </>
  );
};

export default SlaArticleText;
