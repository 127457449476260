import React, {useState} from 'react';

import FormTextInput from '../FormTextInput';

const phoneRegex = /^06[0-9]{8}/gi;

export default ({label, value, onChange}) => {
  const [phone_number, setPhoneNumber] = useState(value);
  const [phoneError, setPhoneError] = useState(false);

  return (
    <FormTextInput
      value={phone_number}
      onChangeText={setPhoneNumber}
      onEndEditing={() => {
        const valid = !phone_number || !!phone_number.match(phoneRegex);
        setPhoneError(!valid);
        if (valid) {
          onChange(phone_number);
        }
      }}
      label={label}
      hasError={phoneError}
      errorMessage={
        !phone_number?.length
          ? 'Mobiele telefoonnummer is verplicht'
          : !phone_number?.match(phoneRegex)
          ? 'Ongeldige waarde mobiele telefoonnummer'
          : null
      }
      digits={0}
      inputProps={{
        maxLength: 10,
        keyboardType: 'numeric',
      }}
    />
  );
};
