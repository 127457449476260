import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  frame: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingVertical: 10,
  },
  slot: {
    width: 50,
    height: 36,
    textAlign: 'center',
    lineHeight: 36,
    borderWidth: 1,
    borderColor: 'transparent',
  },
  available: {
    color: '#299976',
    borderColor: '#299976',
  },
  checked: {
    backgroundColor: '#231fda',
    borderColor: '#231fda',
    color: '#fff',
  },
});
