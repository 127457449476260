export default {
  footerView: {
    flexShrink: 0,
    flexGrow: 0,
    paddingTop: 12,
    marginBottom: 12,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopWidth: 1,
    borderColor: '#dcdcdc',
    flexDirection: 'row',
  },
  footerText: {},
  footerButton: {
    flexBasis: 170,
    flexShrink: 1,
    flexGrow: 0,
    marginLeft: 8,
    minWidth: 80,
  },
};
