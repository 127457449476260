import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr';

import config from '../../configuration';
import authentication from '../authentication';
import trace from '../trace';
import handler from './handler';

const initialize = async () => {
  trace('Initializing notifications');
  const user_id = await authentication.getUserID();

  if (!user_id) {
    setTimeout(initialize, 60 * 1000); // User id not found, retry after 1 minute.
    return;
  }

  const connection = new HubConnectionBuilder()
    .withUrl(config.signalRHubUrl + '/service_request/notification', {
      headers: {
        'x-ms-client-principal-id': user_id,
      },
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

  connection.on('notification', (notification) => {
    try {
      trace('Notification received', notification);

      handler(notification);
    } catch (e) {
      console.log(e);
    }
  });
  connection.onclose(() => console.log('disconnected'));

  console.log('connecting...');
  await connection.start();
};

export default {
  initialize,
  kill: () => {
    // Notifications kill method stub
  },
  register: async () => {
    await initialize();
  },
  requestPermissions: async () => {
    try {
      const result = await Notification.requestPermission();
      trace('Permission result', result);
    } catch {}
  },
};
