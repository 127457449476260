import React, {useState, useEffect} from 'react';
import {Dimensions} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const withDimensions = (Component) => {
  return (props) => {
    const {top} = useSafeAreaInsets();
    const [dimensions, setDimensions] = useState({
      window: {
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height - top,
      },
      screen: Dimensions.get('screen'),
    });

    useEffect(() => {
      const subscription = Dimensions.addEventListener(
        'change',
        ({window, screen}) => {
          setDimensions({
            window: {
              width: window.width,
              height: window.height - top,
            },
            screen,
          });
        },
      );
      return () => subscription?.remove();
    });

    return <Component {...props} dimensions={dimensions} />;
  };
};

export default withDimensions;
