import React, {useState, useEffect} from 'react';
import {View, ScrollView} from 'react-native';
import {Divider} from 'react-native-paper';

import {
  rob as robApi,
  service_request as serviceRequestApi,
} from '~/api/private';

import sy from '~/styles';
import {
  Text,
  Pressable,
  RadioButton,
  ActionButton,
} from '~/components/controls';
import Actionsheet, {Title, Actions} from '~/components/controls/Actionsheet';
import {ROB_REPLACEMENT_RENTAL_CHARGE_CODE} from '~/types/replacement';
import withDimensions from '~/components/hoc/with-dimensions';
import Format from '~/lib/format';

const HEADER_HEIGHT_IN_PX = 72;
const FOOTER_HEIGHT_IN_PX = 52;

export default withDimensions(
  ({
    visible,
    dimensions,
    request_id,
    rental_class,
    contract_rental_class,
    rental_car_class,
    fuel,
    onChange,
    onClose,
  }) => {
    const [selected, setSelected] = useState(rental_class);
    const [rental_classes, setRentalClasses] = useState(null);
    const [sla, setSLA] = useState(null);

    useEffect(() => {
      const fetch = async () => {
        const {result: rental_classes} = await robApi.rental_classes();
        setRentalClasses(rental_classes);

        const {result: sla} = await serviceRequestApi.sla(request_id);
        setSLA(
          sla?.replacement_vehicle
            ?.filter(
              (item) => item.rob_code === ROB_REPLACEMENT_RENTAL_CHARGE_CODE,
            )
            .reduce((acc, item) => {
              if ((item.fuel ?? fuel) === fuel) {
                acc[`${item.rental_class}`] = item.amount;
              }

              return acc;
            }, {}),
        );
      };

      if (request_id) {
        fetch();
      }
    }, [request_id, fuel]);

    useEffect(() => {
      setSelected(rental_class);
    }, [visible, rental_class]);

    return (
      <Actionsheet visible={visible} onDismiss={onClose}>
        <Title>Huurklasse</Title>
        <Divider />
        <ScrollView
          contentContainerStyle={{
            maxHeight:
              dimensions.window.height -
              HEADER_HEIGHT_IN_PX -
              FOOTER_HEIGHT_IN_PX,
          }}>
          {rental_classes
            ?.sort((a, b) => {
              if (sla?.[a.code] && !sla?.[b.code]) {
                return -1;
              }

              if (!sla?.[a.code] && sla?.[b.code]) {
                return 1;
              }

              if (sla?.[a.code] && sla?.[b.code]) {
                return sla?.[a.code] - sla?.[b.code];
              }

              return a.code - b.code;
            })
            .map((item) => (
              <View key={item.code} style={[sy['flex-row']]}>
                <Pressable
                  onPress={() => {
                    setSelected(item.code);
                  }}>
                  <View style={sy['p-4']}>
                    <RadioButton checked={item.code === selected} />
                  </View>
                </Pressable>
                <View
                  style={[
                    sy['py-4'],
                    sy['pr-4'],
                    sy['border-b'],
                    sy['border-lightgray'],
                    sy['flex-1'],
                    sy['flex-row'],
                    sy['justify-between'],
                  ]}>
                  <View>
                    <Text>
                      {item.code}
                      {item.code === contract_rental_class &&
                      item.code === rental_car_class ? (
                        <>&nbsp;&middot;&nbsp;Contract&nbsp;&amp;&nbsp;inzet</>
                      ) : item.code === contract_rental_class ? (
                        <>&nbsp;&middot;&nbsp;Contract</>
                      ) : item.code === rental_car_class ? (
                        <>&nbsp;&middot;&nbsp;Inzet</>
                      ) : (
                        <></>
                      )}
                    </Text>
                    <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                      {item.description[0].toUpperCase()}
                      {item.description.slice(1)}
                    </Text>
                  </View>
                  <Text style={sy.smallRegular}>
                    {sla?.[item.code] ? (
                      <>{Format.price(sla?.[item.code])}</>
                    ) : (
                      <>Geen SLA</>
                    )}
                  </Text>
                </View>
              </View>
            ))}
        </ScrollView>
        <Divider />
        <Actions>
          <ActionButton onPress={onClose}>Annuleren</ActionButton>
          <ActionButton
            onPress={() => {
              onChange(selected);
            }}>
            OK
          </ActionButton>
        </Actions>
      </Actionsheet>
    );
  },
);
