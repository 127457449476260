import React from 'react';
import {Button} from 'react-native-paper';

const ActionButton = ({style, children, disabled, onPress}) => {
  return (
    <Button
      style={style}
      labelStyle={{marginHorizontal: 8}}
      disabled={disabled}
      onPress={onPress}>
      {children}
    </Button>
  );
};

export default ActionButton;
