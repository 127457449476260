import React, {useState} from 'react';
import {View} from 'react-native';
import _ from 'lodash';

import {
  Dialog,
  FormTextInput,
  FormNumberInput,
  Checkbox,
  Text,
  Pressable,
} from '~/components/controls';
import {dealer as dealerApi} from '~/api/private';
import {SOURCES} from '~/types/sources';

import sy from '~/styles';

import DeleteIcon from '~/images/md-icons/delete/materialicons/24px.svg';

export default ({
  dealer_id,
  edit_article,
  item_mp_id,
  onDismiss,
  onAdd,
  onEdited,
  onDeleted,
}) => {
  const [article, setArticle] = useState(edit_article ?? {});

  return (
    <Dialog
      visible={true}
      title={
        edit_article ? (
          <View
            style={[
              sy['flex-row'],
              sy['justify-between'],
              sy['items-center'],
              {
                display: 'flex',
              },
            ]}>
            <Text style={[sy.large, {fontSize: 20, lineHeight: 30}]}>
              Artikel bewerken
            </Text>
          </View>
        ) : (
          'Artikel invoeren'
        )
      }
      titleIcon={
        edit_article ? (
          <Pressable
            onPress={async () => {
              let success = true;

              const {reference} = edit_article;
              if (reference) {
                ({success} = await dealerApi.delete_part({
                  id: reference,
                }));
              }

              if (success) {
                onDeleted();
              }
            }}>
            <DeleteIcon fill="#4A4A49" />
          </Pressable>
        ) : null
      }
      onDismiss={onDismiss}
      buttons={[
        {text: 'Annuleren', onPress: onDismiss},
        {
          text: 'OK',
          onPress: async () => {
            if (!article.description || !_.isNumber(article.price)) {
              return;
            }

            let success = true;
            let part_id = null;

            if (edit_article) {
              const {reference} = edit_article;
              if (reference) {
                ({success} = await dealerApi.update_part({
                  ...article,
                  id: reference,
                }));
              }

              if (success) {
                onEdited({
                  ...article,
                });
              }
            } else {
              let reference = null;
              if (article.is_dealer_part) {
                ({success, part_id} = await dealerApi.add_part({
                  ...article,
                  item_mp_id,
                  dealer_id,
                }));

                reference = part_id;
              }

              if (success) {
                onAdd({
                  ...article,
                  reference,
                  source: SOURCES.Custom,
                });
              }
            }
          },
        },
      ]}>
      <View style={[{paddingTop: 24}, sy['gap-4']]}>
        <FormTextInput
          label="Naam"
          value={article.description}
          onChangeText={(value) =>
            setArticle({
              ...article,
              description: value,
            })
          }
        />
        <FormTextInput
          label="Nummer"
          value={article.number}
          onChangeText={(value) =>
            setArticle({
              ...article,
              number: value,
            })
          }
        />
        <FormNumberInput
          label="Brutoprijs"
          value={article.price}
          digits={2}
          onChangeNumber={(value) =>
            setArticle({
              ...article,
              price: value,
            })
          }
        />
        {!edit_article && (
          <Pressable
            style={[sy['flex-row'], sy['items-center'], sy['pb-4']]}
            onPress={() => {
              setArticle({
                ...article,
                is_dealer_part: !article.is_dealer_part,
              });
            }}>
            <Checkbox checked={article.is_dealer_part} />
            <Text style={[sy['pl-4']]}>Opslaan voor hergebruik</Text>
          </Pressable>
        )}
      </View>
    </Dialog>
  );
};
