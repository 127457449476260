import React from 'react';
import {Text} from 'react-native';

import globalStyles from '../../../styles';

export default (props) => {
  const {style, disabled, children, ...rest} = props;
  return (
    <Text
      style={[
        globalStyles.regular,
        {color: '#4A4A49', display: 'inline'},
        style,
        disabled && {opacity: 0.4},
      ]}
      {...rest}>
      {children}
    </Text>
  );
};
