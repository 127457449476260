import config from '../../configuration';

const apiRoot = config.privateApiRoot;

const endpoints = {
  dealer: `${apiRoot}/dealer`,
  user: `${apiRoot}/user`,
  service_request: `${apiRoot}/service_request`,
  rob: `${apiRoot}/rob`,
  car: `${apiRoot}/car`,
  article: `${apiRoot}/article`,
  articles: `${apiRoot}/articles`,
  fleet: `${apiRoot}/fleet`,
};

export default endpoints;
