import React, {useState} from 'react';

import FormTextInput from '../FormTextInput';

/* eslint-disable no-control-regex */
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;

export default ({label, value, style, onChange}) => {
  const [email, setEmail] = useState(value);
  const [emailError, setEmailError] = useState(false);

  return (
    <FormTextInput
      label={label}
      value={email}
      onChangeText={setEmail}
      onEndEditing={() => {
        const valid = !email || !!email.match(emailRegex);
        setEmailError(!valid);
        if (valid) {
          onChange(email);
        }
      }}
      style={style}
      hasError={emailError}
      errorMessage="Email heeft een ongeldige waarde"
      inputProps={{
        keyboardType: 'email-address',
      }}
    />
  );
};
