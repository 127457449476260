import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import {View, ActivityIndicator, ScrollView, TextInput} from 'react-native';
import {Divider} from 'react-native-paper';
import {HeaderBackButton} from '@react-navigation/elements';
import _ from 'lodash';

import sy from '~/styles';
import withProtectedScreen from '~/components/hoc/with-protected-screen';
import {useDispatch} from '~/lib/hooks';
import {setAppDoneLoading, setAppIsLoading} from '~/actions';
import {
  articles as articlesApi,
  service_request as serviceRequestApi,
} from '~/api/private';
import {
  Pressable,
  Text,
  Grouping,
  Link,
  Checkbox,
  FourceDetailsLink,
} from '~/components/controls';
import Format from '~/lib/format';

import ChevronLeftIcon from '~/images/md-icons/chevron_left/materialicons/24px.svg';
import ArrowBackIcon from '~/images/md-icons/arrow_back/materialicons/24px.svg';

const GROUPING_ORDER = ['Motor', 'Chassis', 'Bodywork', 'Other'];

const GROUPING_TRANSLATION = {
  Motor: 'Motor',
  Chassis: 'Onderstel',
  Bodywork: 'Carrosserie',
  Other: 'Overig',
};

const SCREEN = {
  Categories: 'Categories',
  Articles: 'Articles',
};

const Fource = ({navigation, route}) => {
  const request_id = route.params?.request_id;
  const license = route.params?.license;
  const dealer_id = route.params?.dealer_id;
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [screen, setScreen] = useState(SCREEN.Categories);
  const [category, setCategory] = useState(null);
  const [articles, setArticles] = useState(null);
  const [selected, setSelected] = useState({});
  const [search, setSearch] = useState(null);
  const [searchText, setSearchText] = useState('');

  const searchTextRef = useRef(null);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          backImage={() => (search ? <ArrowBackIcon /> : <ChevronLeftIcon />)}
          onPress={async () => {
            if (search) {
              setSearch(false);
            } else {
              switch (screen) {
                default:
                case SCREEN.Categories: {
                  dispatch(setAppIsLoading());

                  const articles = Object.values(selected);
                  if (articles.length > 0) {
                    try {
                      const {success} = await serviceRequestApi.add_articles(
                        request_id,
                        articles,
                      );

                      if (!success) {
                        return;
                      }
                    } finally {
                      dispatch(setAppDoneLoading());
                    }
                  }

                  if (navigation.canGoBack()) {
                    navigation.goBack();
                  } else {
                    navigation.replace('Request', {
                      request_id,
                    });
                  }
                  break;
                }

                case SCREEN.Articles: {
                  setScreen(SCREEN.Categories);
                  setCategory(null);
                  break;
                }
              }
            }
          }}
        />
      ),
      headerStatusBarHeight: 0,
      headerTitleAlign: search ? 'left' : 'center',
      headerTitle: () =>
        search ? (
          <TextInput
            ref={(ref) => {
              searchTextRef.current = ref;
              ref?.focus();
            }}
            style={[
              sy.regular,
              sy['flex-1'],
              {
                minWidth: 250,
                color: searchText.length > 0 ? '#4A4A49' : '#828282',
              },
            ]}
            placeholder={'Zoek op nummer, naam en/of merk'}
            onChangeText={(value) => {
              setSearchText(value);
            }}
          />
        ) : (
          <View style={{alignItems: 'center'}}>
            <Text style={sy.largePlus}>
              Fource&nbsp;&middot;&nbsp;{Format.license(license)}
            </Text>
          </View>
        ),
      headerRight: () => <></>,
    });
  }, [
    navigation,
    loading,
    request_id,
    license,
    screen,
    search,
    searchText,
    dispatch,
    selected,
  ]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      const {result: categories} = await articlesApi.categories(license);
      setCategories(
        categories.reduce((acc, category) => {
          if (!acc[category.groupName]) {
            acc[category.groupName] = [];
          }

          acc[category.groupName].push(category);
          return acc;
        }, {}),
      );

      setLoading(false);
    };

    fetch();
  }, [license]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      const {result: articles} = await articlesApi.list(license, category);
      setArticles(articles);

      setLoading(false);
    };

    if (category) {
      setScreen(SCREEN.Articles);
      fetch();
    } else {
      setArticles(null);
    }
  }, [category, license]);

  const doSearch = useCallback(
    _.debounce(
      async (text) => {
        setLoading(true);
        const {result: articles} = await articlesApi.search(license, text);
        setArticles(articles);
        setLoading(false);
      },
      250,
      {leading: false, trailing: true},
    ),
    [license],
  );

  useEffect(() => {
    if (searchText.length > 2) {
      doSearch.cancel();
      doSearch(searchText);
    } else {
      setArticles(null);
    }
  }, [doSearch, searchText]);

  return (
    <View style={sy.mainView}>
      {search ? (
        <>
          {loading ? (
            <ActivityIndicator size="large" color="#231fda" />
          ) : (
            <ScrollView>
              {articles?.map((article, index, arr) => (
                <React.Fragment key={article.id}>
                  <View
                    key={article.id}
                    style={[sy['p-4'], sy['flex-row'], sy['gap-8']]}>
                    <Checkbox
                      disabledTimeout={0}
                      checked={selected[article.id]}
                      onPress={() => {
                        setSelected((prevState) => {
                          let newState = {...prevState};
                          if (newState[article.id]) {
                            delete newState[article.id];
                          } else {
                            newState[article.id] = article;
                          }

                          return {...newState};
                        });
                      }}
                    />
                    <View
                      style={[
                        sy['flex-1'],
                        sy['flex-row'],
                        sy['justify-between'],
                      ]}>
                      <View>
                        <Text>{article.description}</Text>
                        <Text style={[sy.smallRegular, sy['text-lightgray']]}>
                          {article.source}&nbsp;&middot;&nbsp;
                          {article.number}
                        </Text>
                      </View>
                      <View style={[sy['items-end']]}>
                        <Text style={sy.smallRegular}>
                          {Format.price(article.price)}
                        </Text>
                        <Text style={[sy.small, sy['text-lightgray']]}>
                          {Format.number(article.margin * 100)}%
                        </Text>
                      </View>
                    </View>
                  </View>
                  {index + 1 < arr.length && (
                    <Divider style={[sy['bg-gray95'], sy['ml-18']]} />
                  )}
                </React.Fragment>
              ))}
            </ScrollView>
          )}
        </>
      ) : (
        <>
          {screen === SCREEN.Categories && (
            <>
              {loading ? (
                <ActivityIndicator size="large" color="#231fda" />
              ) : (
                <>
                  <ScrollView>
                    {Object.keys(categories)
                      .sort(
                        (a, b) =>
                          GROUPING_ORDER.indexOf(a) - GROUPING_ORDER.indexOf(b),
                      )
                      .map((key, index) => (
                        <Grouping
                          key={key}
                          header={GROUPING_TRANSLATION[key] ?? key}
                          initial={{expand: false}}
                          options={{topMost: index === 0}}>
                          {categories[key].map((category, index, arr) => (
                            <React.Fragment key={category.categoryId}>
                              <Pressable
                                style={[sy['p-4']]}
                                onPress={() => setCategory(category)}>
                                <Text>{category.name}</Text>
                              </Pressable>
                              {index + 1 < arr.length && (
                                <Divider style={[sy['bg-gray95']]} />
                              )}
                            </React.Fragment>
                          ))}
                        </Grouping>
                      ))}
                  </ScrollView>
                  <View
                    style={[sy['flex-row'], sy['p-4'], sy['justify-center']]}>
                    <Text>Niet gevonden?&nbsp;</Text>
                    <Link
                      onPress={() => {
                        setArticles(null);
                        setSearchText('');
                        setSearch(true);
                      }}
                      textStyle={{textDecoration: 'none', color: '#231fda'}}>
                      Zonder voertuigfilter zoeken
                    </Link>
                  </View>
                </>
              )}
            </>
          )}
          {screen === SCREEN.Articles && (
            <>
              <View style={[sy['p-4']]}>
                <Text style={sy.mediumPlus}>{category.name}</Text>
              </View>
              <Divider style={sy['bg-gray95']} />
              {loading ? (
                <ActivityIndicator size="large" color="#231fda" />
              ) : (
                <ScrollView>
                  {articles?.map((article, index, arr) => (
                    <React.Fragment key={article.id}>
                      <View
                        key={article.id}
                        style={[sy['p-4'], sy['flex-row'], sy['gap-8']]}>
                        <Checkbox
                          disabledTimeout={0}
                          checked={selected[article.id]}
                          onPress={() => {
                            setSelected((prevState) => {
                              let newState = {...prevState};
                              if (newState[article.id]) {
                                delete newState[article.id];
                              } else {
                                newState[article.id] = article;
                              }

                              return {...newState};
                            });
                          }}
                        />
                        <View
                          style={[
                            sy['flex-1'],
                            sy['flex-row'],
                            sy['justify-between'],
                          ]}>
                          <View>
                            <Text>{article.description}</Text>
                            <FourceDetailsLink
                              license={license}
                              dealer_id={dealer_id}
                              article={article}
                            />
                          </View>
                          <View style={[sy['items-end']]}>
                            <Text style={sy.smallRegular}>
                              {Format.price(article.price)}
                            </Text>
                            <Text style={[sy.small, sy['text-lightgray']]}>
                              {Format.number(article.margin * 100)}%
                            </Text>
                          </View>
                        </View>
                      </View>
                      {index + 1 < arr.length && (
                        <Divider style={[sy['bg-gray95'], sy['ml-18']]} />
                      )}
                    </React.Fragment>
                  ))}
                </ScrollView>
              )}
            </>
          )}
        </>
      )}
    </View>
  );
};

export default withProtectedScreen(Fource);
