import React from 'react';
import {View} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';

import {Modal, Pressable, Text, ExternalLink} from '~/components/controls';
import CloseIcon from '~/images/md-icons/close/materialicons/24px.svg';
import FeedbackIcon from '~/images/md-icons/feedback/materialicons/24px.svg';
import OpenInNewIcon from '~/images/md-icons/open_in_new/materialicons/24px.svg';
import QrCode from './qr_code.svg';

const HelpModal = ({visible, onDismiss}) => {
  return (
    <Modal visible={visible} onDismiss={onDismiss}>
      <View
        style={[{height: 64}, sy['flex-row'], sy['items-center'], sy['gap-8']]}>
        <Pressable style={[sy['p-4']]} onPress={onDismiss}>
          <CloseIcon fill="#4a4a49" />
        </Pressable>
        <View style={[sy['flex-1'], sy['items-center'], sy['justify-center']]}>
          <Text>Help & feedback</Text>
        </View>
        <View style={[sy['p-4']]}>
          <FeedbackIcon fill="#4a4a49" />
        </View>
      </View>
      <Divider />
      <View style={[sy['flex-1']]}>
        <View style={[sy['p-4'], sy['flex-row'], sy['justify-between']]}>
          <Text>Neem contact op</Text>
          <View style={[sy['flex-row'], sy['gap-4']]}>
            <ExternalLink href="https://wa.me/message/U62E3DXBTCIYL1">
              Whatsapp Web
            </ExternalLink>
            <OpenInNewIcon fill="#4a4a49" />
          </View>
        </View>
        <Divider style={sy['bg-gray95']} />
        <View style={[sy['p-4'], sy['items-center']]}>
          <QrCode />
          <View style={[sy['p-4'], {maxWidth: 320}]}>
            <Text style={sy['text-center']}>
              Scan deze code om een WhatsApp-chat met ons te beginnen.
            </Text>
          </View>
        </View>
        <Divider style={sy['bg-gray95']} />
        <ExternalLink
          href="https://bahnkick.blob.core.windows.net/signin/20240716_Bahnkick Gebruiksvoorwaarden (EULA).pdf"
          textStyle={[sy['no-underline']]}>
          <View
            style={[
              {display: 'flex'},
              sy['p-4'],
              sy['flex-row'],
              sy['justify-between'],
            ]}>
            <Text>Bahnkick gebruiksvoorwaarden</Text>
            <OpenInNewIcon fill="#4a4a49" />
          </View>
        </ExternalLink>
        <Divider style={sy['bg-gray95']} />
      </View>
    </Modal>
  );
};

export default HelpModal;
