import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';

const car = {
  fetch: async (license) => {
    const result = await fetch(`${endpoints.car}/${license}`);

    if (!result || result.status !== 200) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  vinLookup: async (license, vin_part) => {
    const result = await fetch(`${endpoints.car}/${license}/vin`, {
      method: 'POST',
      body: JSON.stringify({vin_part}),
    });

    if (!result || result.status !== 200) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  change_attributes: async (license, attributes) => {
    const result = await fetch(`${endpoints.car}/${license}`, {
      method: 'PATCH',
      body: JSON.stringify({attributes}),
    });

    if (!result || result.status !== 200) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },
  type_id: async (license) => {
    const result = await fetch(`${endpoints.car}/${license}/type_id`);

    if (!result || result.status !== 200) {
      return {
        success: false,
        result: null,
      };
    }

    const json = await result.json();
    return json;
  },

  update_type_id: async (license, carId, rdcVin, carName, fuelType) => {
    const result = await fetch(`${endpoints.car}/${license}/type_id`, {
      method: 'PATCH',
      body: JSON.stringify({carId, rdcVin, carName, fuelType}),
    });

    if (!result || result.status !== 200) {
      return {
        success: false,
      };
    }

    const json = await result.json();
    return json;
  },
};

export default car;
