import React from 'react';
import {View} from 'react-native';
import {Switch as RNSwitch} from 'react-native-paper';

import sy from '~/styles';

const Switch = ({disabled, ...props}) => {
  return (
    <View
      style={[disabled && {opacity: 0.4}, sy['flex-row'], sy['items-center']]}>
      <RNSwitch
        color="rgb(41, 7, 227)"
        trackColor={{
          true: 'rgba(41, 7, 227, 0.2)',
          false: 'rgb(147, 147, 147)',
        }}
        disabled={disabled}
        {...props}
      />
    </View>
  );
};

export default Switch;
