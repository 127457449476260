import {StyleSheet} from 'react-native';

const styles = {
  headerView: {
    paddingTop: 16,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 12,
  },
  contentView: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
    position: 'relative',
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    backgroundColor: '#f2f2f2',
  },
  sectionHeader: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    height: 48,
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    borderBottomColor: '#DCDCDC',
    borderBottomWidth: 1,
  },
};

export default StyleSheet.create(styles);
