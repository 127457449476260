import React from 'react';

import Text from '../Text';

export default ({type, ...rest}) => {
  const TITLE = {
    none: 'Geen keuze',
    wait: 'Wachten op locatie',
    message: 'Bericht ontvangen',
    bike: 'Leenfiets',
    transport: 'Haal- en brengservice',
    car: 'Huurauto',
  };

  return <Text {...rest}>{TITLE[type] ?? 'Vervangend vervoer'}</Text>;
};
