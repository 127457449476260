import moment from 'moment';
import {isObject} from 'lodash';

export const toDisplayTime = (input) => {
  let time = input;
  if (/[+-]{1}\d{2}$/.test(time)) {
    time += ':00';
  }

  return moment(time, 'HH:mm:ssZ').format('HH:mm');
};

export const throttle = (func, wait = 1000) => {
  let lastArgs;
  let lastThis;
  let result;
  let lastCallTime;
  let lastInvokeTime = 0;
  let pending;

  if (typeof func !== 'function') {
    throw new TypeError('Expected a function');
  }

  wait = +wait || 0;

  async function invokeFunc(time) {
    const args = lastArgs;
    const thisArg = lastThis;

    lastArgs = lastThis = undefined;
    lastInvokeTime = time;

    pending = new Promise((resolve) => func.apply(thisArg, args).then(resolve));
    result = await pending;
    pending = null;

    return result;
  }

  function shouldInvoke(time) {
    const timeSinceLastCall = time - lastCallTime;
    const timeSinceLastInvoke = time - lastInvokeTime;

    return (
      lastCallTime === undefined ||
      timeSinceLastCall >= wait ||
      timeSinceLastCall < 0 ||
      timeSinceLastInvoke >= wait
    );
  }

  async function throttle(...args) {
    const time = Date.now();
    const isInvoking = shouldInvoke(time);

    lastArgs = args;
    lastThis = this;
    lastCallTime = time;

    if (isInvoking) {
      return await invokeFunc(lastCallTime);
    } else if (result) {
      return result;
    } else if (pending) {
      return await pending;
    }

    return result;
  }

  return throttle;
};
