export default {
  headerView: {
    flexGrow: 0,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  headerText: {
    fontSize: 24,
    fontFamily: 'Roboto_700Bold',
  },
  modalHeaderView: {
    flexGrow: 0,
    flexShrink: 0,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
  },
};
