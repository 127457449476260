import React from 'react';
import _ from 'lodash';

const ArticleText = ({source, number}) => {
  return (
    <>
      {source}
      {!_.isNil(number) && (
        <>
          {!_.isNil(source) ? <>&nbsp;&middot;&nbsp;</> : null}
          <>{number}</>
        </>
      )}
    </>
  );
};

export default ArticleText;
