import React from 'react';
import {ImageBackground, View} from 'react-native';

import styles from './styles';

import {apiRoot as publicApiRoot} from '../../../api/public/endpoints';

export const DealerPersonaSize = {
  size32: 32,
  size40: 40,
  size48: 48,
  size56: 56,
};

const DealerPersona = ({dealer_id, size = DealerPersonaSize.size32}) => {
  const height = size;
  const width = size;

  return (
    <View style={styles.root}>
      <View style={[styles.disc, {width, height}]}>
        <ImageBackground
          style={{width, height}}
          source={{
            uri: `${publicApiRoot}/dealer/photo/${dealer_id}`,
          }}
        />
      </View>
    </View>
  );
};

export default DealerPersona;
