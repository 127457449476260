export const REQUEST_TYPES = {
  ROB_REQUEST: 'ROB_REQUEST',
  ROB_REPLACEMENT_VEHICLE: 'ROB_REPLACEMENT_VEHICLE',
  FLEET_REQUEST: 'FLEET_REQUEST',
  FLEET_REPLACEMENT_VEHICLE: 'FLEET_REPLACEMENT_VEHICLE',
  SERVICE_REQUEST: 'SERVICE_REQUEST',
};

export const OVERVIEW_REQUEST_TYPES = {
  EXPIRED: 'EXPIRED', // Only used in the overview screen.
  REQUEST: 'REQUEST',
  REPLACEMENT_VEHICLE: 'REPLACEMENT_VEHICLE',
};

export const IsReplacementVehicle = (request_type) => {
  return (
    request_type === REQUEST_TYPES.ROB_REPLACEMENT_VEHICLE ||
    request_type === REQUEST_TYPES.FLEET_REPLACEMENT_VEHICLE
  );
};
