import trace from '../lib/trace';

const wrappedFetch = async (url, options) => {
  const start = Date.now();
  const result = await fetch(url, options);
  const diff = Date.now() - start;
  trace(url, result.status, `${diff}ms`);

  return result;
};

export {wrappedFetch as fetch};
