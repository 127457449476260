import React, {useRef, useCallback} from 'react';
import {Pressable} from 'react-native';

import AddAPhotoIcon from '~/images/md-icons/add_a_photo/materialicons/24px.svg';

const Camera = ({style, onPhotoTaken}) => {
  const fileRef = useRef(null);

  const handleFile = useCallback(
    (e) => {
      e.persist();
      const fileList = e.target.files;

      if (fileList.length) {
        const imageFile = fileList[0];

        if (imageFile.type.indexOf('image/') !== 0) {
          console.log('File is not an image file', imageFile);
          return;
        }

        const reader = new FileReader();
        reader.onload = function (event) {
          onPhotoTaken({data: event.target.result});

          e.target.value = '';
        };
        reader.readAsDataURL(imageFile);
      }
    },
    [onPhotoTaken],
  );

  return (
    <Pressable style={style} onPress={() => fileRef.current?.click()}>
      <AddAPhotoIcon fill="#4a4a49" />
      <input
        ref={fileRef}
        style={{display: 'none'}}
        type="file"
        accept="image/*"
        onInput={handleFile}
      />
    </Pressable>
  );
};

export default Camera;
