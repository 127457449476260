import React, {useState, useEffect, useRef} from 'react';
import {View} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';

import sy from '~/styles';
import {
  service_request as serviceRequestApi,
  dealer as dealerApi,
} from '~/api/private';
import {Dialog, FormTextInput, Text, Link} from '~/components/controls';
import {serviceRequestUpdate} from '~/actions';
import {useDispatch} from '~/lib/hooks';

import TextsmsIcon from '~/images/md-icons/textsms/materialicons/24px.svg';
import SmsFailedIcon from '~/images/md-icons/sms_failed/materialicons/24px.svg';
import EmailIcon from '~/images/md-icons/email/materialicons/24px.svg';

const phoneRegex = /^06[0-9]{8}/gi;

const StatusLink = ({reference_id, status, onSend}) => {
  switch (status) {
    case 'Delivered':
      return (
        <Text style={[sy.smallRegular, sy['text-lightgray']]}>Verzonden</Text>
      );

    case 'SentFailed':
      return (
        <Text style={[sy.smallRegular, {color: '#972727'}]}>
          Verzenden mislukt
        </Text>
      );

    default:
      return (
        <Link
          disabled={!!reference_id}
          textStyle={[sy.smallRegular, sy['no-underline'], {color: '#2907E3'}]}
          onPress={onSend}>
          Verzenden
        </Link>
      );
  }
};

const EditSmsDialog = ({number, onDismiss, onChange}) => {
  const [smsNumber, setSmsNumber] = useState(number);
  const [phoneError, setPhoneError] = useState(false);

  return (
    <Dialog
      visible={true}
      title="SMS bericht"
      titleIcon={<TextsmsIcon fill="#4a4a49" />}
      onDismiss={onDismiss}
      buttons={[
        {
          text: 'Annuleren',
          onPress: onDismiss,
        },
        {
          text: 'OK',
          disabled: phoneError,
          onPress: () => {
            if (phoneError) {
              return;
            }

            onChange(smsNumber);
            onDismiss();
          },
        },
      ]}
      options={{
        noPaddingContent: true,
      }}>
      <View style={[sy['p-4'], sy['gap-4']]}>
        <div data-private>
          <FormTextInput
            value={smsNumber}
            onChangeText={setSmsNumber}
            onEndEditing={() => {
              const valid = !smsNumber || !!smsNumber.match(phoneRegex);
              setPhoneError(!valid);
            }}
            label="Mobiele telefoonnummer"
            hasError={phoneError}
            errorMessage={
              !smsNumber?.length
                ? 'Mobiele telefoonnummer is verplicht'
                : !smsNumber?.match(phoneRegex)
                ? 'Ongeldige waarde mobiele telefoonnummer'
                : null
            }
            inputProps={{
              maxLength: 10,
              keyboardType: 'numeric',
            }}
          />
        </div>
      </View>
    </Dialog>
  );
};

const EditEmailDialog = ({email, onDismiss, onChange}) => {
  const [emailAddress, setEmailAddress] = useState(email);
  const [emailError, setEmailError] = useState(false);

  return (
    <Dialog
      visible={true}
      title="Email bericht"
      titleIcon={<EmailIcon fill="#4a4a49" />}
      onDismiss={onDismiss}
      buttons={[
        {
          text: 'Annuleren',
          onPress: onDismiss,
        },
        {
          text: 'OK',
          disabled: emailError,
          onPress: () => {
            if (emailError) {
              return;
            }

            onChange(emailAddress);
            onDismiss();
          },
        },
      ]}
      options={{
        noPaddingContent: true,
      }}>
      <View style={[sy['p-4'], sy['gap-4']]}>
        <div data-private>
          <FormTextInput
            label="E-mail"
            value={emailAddress}
            onChangeText={setEmailAddress}
            onEndEditing={() => {
              const valid = !emailAddress || !!emailAddress.match(emailRegex);
              setEmailError(!valid);
              if (valid) {
                setEmailAddress(emailAddress);
              }
            }}
            hasError={emailError}
            errorMessage="Email heeft een ongeldige waarde"
            inputProps={{
              keyboardType: 'email-address',
            }}
          />
        </div>
      </View>
    </Dialog>
  );
};

const DealerReminderDialog = ({
  visible,
  request_id,
  phone_number,
  email,
  onDismiss,
}) => {
  const dispatch = useDispatch();

  const [showEditSms, setShowEditSms] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [smsNumber, setSmsNumber] = useState(phone_number);
  const [emailAddress, setEmailAddress] = useState(email);
  const [references, setReferences] = useState(null);
  const [statuses, setStatuses] = useState(null);

  const timerID = useRef(null);

  useEffect(() => {
    if (references) {
      if (timerID.current) {
        clearInterval(timerID.current);
      }

      timerID.current = setInterval(() => {
        serviceRequestApi
          .client_message_statuses(request_id, references)
          .then(({result: statuses}) =>
            setStatuses((prevState) => {
              if (!_.isEqual(prevState, statuses)) {
                requestAnimationFrame(() =>
                  dispatch(
                    serviceRequestUpdate({
                      request_id,
                    }),
                  ),
                );
              }

              return statuses;
            }),
          );
      }, 2000);
    }

    return () => {
      if (timerID.current) {
        clearInterval(timerID.current);
        setShowEditSms(false);
        setReferences(null);
        setStatuses(null);
      }
    };
  }, [request_id, references]);

  if (showEditSms) {
    return (
      <EditSmsDialog
        number={smsNumber}
        onDismiss={() => setShowEditSms(false)}
        onChange={(number) => {
          setSmsNumber(number);
        }}
      />
    );
  }

  if (showEditEmail) {
    return (
      <EditEmailDialog
        email={emailAddress}
        onDismiss={() => setShowEditEmail(false)}
        onChange={setEmailAddress}
      />
    );
  }

  return (
    <Dialog
      visible={visible}
      title="Berichten naar berijder"
      onDismiss={onDismiss}
      buttons={[
        {
          text: 'Sluiten',
          onPress: onDismiss,
        },
      ]}
      options={{
        noPaddingContent: true,
      }}>
      <View>
        {smsNumber?.length > 0 && (
          <View style={[sy['p-4'], sy['flex-row'], sy['gap-8']]}>
            {statuses?.sms === 'SentFailed' ? (
              <SmsFailedIcon fill="#972727" />
            ) : (
              <TextsmsIcon fill="#4a4a49" />
            )}
            <View>
              <Text>SMS bericht</Text>
              <View style={sy['flex-row']}>
                <StatusLink
                  reference_id={references?.sms}
                  status={statuses?.sms}
                  onSend={async () => {
                    const {reference_id} = await dealerApi.reminder({
                      request_id,
                      phone_number: smsNumber,
                    });

                    setReferences({
                      ...references,
                      sms: reference_id,
                    });
                  }}
                />
                <Text>&nbsp;&middot;&nbsp;</Text>
                <Link
                  textStyle={[
                    sy.smallRegular,
                    sy['no-underline'],
                    {color: '#2907E3'},
                  ]}
                  onPress={() => setShowEditSms(true)}>
                  {references?.sms ? 'Bekijken' : 'Bewerken'}
                </Link>
              </View>
            </View>
          </View>
        )}
        {emailAddress?.length > 0 && (
          <>
            {smsNumber?.length > 0 && <Divider />}
            <View style={[sy['p-4'], sy['flex-row'], sy['gap-8']]}>
              <EmailIcon fill="#4a4a49" />
              <View>
                <Text>Email bericht</Text>
                <View style={sy['flex-row']}>
                  <StatusLink
                    reference_id={references?.email}
                    status={references?.email ? 'Delivered' : null}
                    onSend={async () => {
                      const {reference_id} = await dealerApi.reminder({
                        request_id,
                        email: emailAddress,
                      });

                      setReferences({
                        ...references,
                        email: reference_id,
                      });
                    }}
                  />
                  <Text>&nbsp;&middot;&nbsp;</Text>
                  <Link
                    textStyle={[
                      sy.smallRegular,
                      sy['no-underline'],
                      {color: '#2907E3'},
                    ]}
                    onPress={() => setShowEditEmail(true)}>
                    {references?.email ? 'Bekijken' : 'Bewerken'}
                  </Link>
                </View>
              </View>
            </View>
          </>
        )}
      </View>
    </Dialog>
  );
};

export default DealerReminderDialog;
