import React, {useState, useRef, useEffect} from 'react';
import {View, TextInput, Animated} from 'react-native';

import sy from '~/styles';

import {Pressable, Camera} from '~/components/controls';

import SendIcon from '~/images/md-icons/send/materialicons/24px.svg';

const LINE_HEIGHT = 24;

export default (props) => {
  const {style, onChange, onSend, onImage} = props;
  const message = props.message ?? '';

  const [lines, setLines] = useState(1);

  const cancelAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (message.length === 0) {
      setLines(1);
    }
  }, [message]);

  useEffect(() => {
    if (onImage) {
      // Skip the animation if onImage is set.
      return;
    }

    if (message.length) {
      Animated.timing(cancelAnim, {
        toValue: 100,
        duration: 250,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(cancelAnim, {
        toValue: 0,
        duration: 0,
        useNativeDriver: true,
      }).start();
    }
  }, [cancelAnim, message, onImage]);

  return (
    <View style={[sy['p-4'], sy['flex-row'], style]}>
      <View style={[sy['flex-1'], {display: 'block'}]}>
        <View
          style={[
            sy['flex-1'],
            sy['p-2'],
            {
              borderRadius: 10,
              backgroundColor: '#EBEBEB',
              height: lines * LINE_HEIGHT,
              boxSizing: 'content-box',
            },
          ]}>
          <TextInput
            style={[
              sy.regular,
              sy['flex-1'],
              {
                color: message.length > 0 ? '#4A4A49' : '#828282',
              },
            ]}
            multiline
            placeholder={'Typ een nieuw bericht'}
            value={message}
            onChangeText={(message) => onChange(message)}
            onContentSizeChange={({nativeEvent}) => {
              const {height} = nativeEvent.contentSize;
              setLines(Math.ceil(height / LINE_HEIGHT));
            }}
          />
        </View>
      </View>
      <Animated.View
        style={[
          {
            height: 30,
            paddingLeft: message.length || onImage ? 8 : 0,
            maxWidth: onImage ? 100 : cancelAnim,
          },
          sy['pb-3'],
          sy['justify-center'],
          sy['overflow-hidden'],
          sy['self-end'],
        ]}>
        {message?.length > 0 ? (
          <Pressable
            onPress={() => {
              onSend();
              setLines(1);
            }}>
            <SendIcon fill="#231fda" />
          </Pressable>
        ) : onImage ? (
          <Camera
            onPhotoTaken={async (image) => {
              onImage(image);
            }}
          />
        ) : null}
      </Animated.View>
    </View>
  );
};
