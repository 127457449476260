import React from 'react';
import {View} from 'react-native';
import _ from 'lodash';

import {STATUS_CODES} from '~/types/statuses';
import DraftsIcon from '~/images/md-icons/drafts/materialicons/24px.svg';
import InsertInvitationIcon from '~/images/md-icons/insert_invitation/materialicons/24px.svg';
import AssignmentIcon from '~/images/md-icons/assignment/materialicons/24px.svg';
import AssignmentIndIcon from '~/images/md-icons/assignment_ind/materialicons/24px.svg';
import AssignmentReturnIcon from '~/images/md-icons/assignment_return/materialicons/24px.svg';
import AssignmentReturnedIcon from '~/images/md-icons/assignment_returned/materialicons/24px.svg';
import AssignmentLateIcon from '~/images/md-icons/assignment_late/materialicons/24px.svg';
import AssignmentTurnedInIcon from '~/images/md-icons/assignment_turned_in/materialicons/24px.svg';
import InventoryIcon from '~/images/md-icons/inventory/materialicons/24px.svg';
import ReceiptIcon from '~/images/md-icons/receipt/materialicons/24px.svg';
import TaskIcon from '~/images/md-icons/task/materialicons/24px.svg';
import PendingActionsIcon from '~/images/md-icons/pending_actions/materialicons/24px.svg';
import DirectionsCarIcon from '~/images/md-icons/directions_car/materialicons/24px.svg';
import EventAvailableIcon from '~/images/md-icons/event_available/materialicons/24px.svg';
import EventBusyIcon from '~/images/md-icons/event_busy/materialicons/24px.svg';
import NoCrashIcon from '~/images/md-icons/no_crash/materialicons/24px.svg';

const ServiceRequestStatusIcon = ({
  style,
  status,
  fill,
  iconProps: defaultIconProps,
}) => {
  let icon;

  const iconProps = _.pickBy({
    fill,
    ...defaultIconProps,
  });

  switch (status) {
    case STATUS_CODES.Draft: {
      icon = <DraftsIcon fill="#231fda" {...iconProps} />;
      break;
    }

    case STATUS_CODES.Reserved: {
      icon = <InsertInvitationIcon fill="#231fda" {...iconProps} />;
      break;
    }

    case STATUS_CODES.CheckIn: {
      icon = <AssignmentIndIcon fill="#231fda" {...iconProps} />;
      break;
    }
    case STATUS_CODES.ApprovalRequested: {
      icon = <AssignmentReturnIcon fill="#231fda" {...iconProps} />;
      break;
    }

    case STATUS_CODES.Denied: {
      icon = <AssignmentLateIcon fill="#972727" {...iconProps} />;
      break;
    }

    case STATUS_CODES.Approved: {
      icon = <AssignmentReturnedIcon fill="#299976" {...iconProps} />;
      break;
    }

    case STATUS_CODES.Modified: {
      icon = <AssignmentIcon fill="#231fda" {...iconProps} />;
      break;
    }

    case STATUS_CODES.Done: {
      icon = <AssignmentTurnedInIcon fill="#299976" {...iconProps} />;
      break;
    }

    case STATUS_CODES.DoneNoInvoice: {
      icon = <AssignmentTurnedInIcon fill="#828282" {...iconProps} />;
      break;
    }

    case STATUS_CODES.NotReadyForInvoicing: {
      icon = <PendingActionsIcon fill="#FF6C00" {...iconProps} />;
      break;
    }

    case STATUS_CODES.Invoiced: {
      icon = <ReceiptIcon fill="#299976" {...iconProps} />;
      break;
    }

    case STATUS_CODES.NoInvoice: {
      icon = <TaskIcon fill="#299976" {...iconProps} />;
      break;
    }

    case STATUS_CODES.Archived: {
      icon = <InventoryIcon fill="#828282" {...iconProps} />;
      break;
    }

    case STATUS_CODES.Cancelled: {
      icon = <EventBusyIcon fill="#828282" {...iconProps} />;
      break;
    }

    case STATUS_CODES.ReplacementVehicle: {
      icon = <DirectionsCarIcon fill="#4A4A49" {...iconProps} />;
      break;
    }

    case STATUS_CODES.ProvisionallyApproved:
    case STATUS_CODES.ReplacementReservationApproved: {
      icon = <EventAvailableIcon fill="#299976" {...iconProps} />;
      break;
    }

    case STATUS_CODES.ReplacementCheckoutApproved:
    case STATUS_CODES.ReplacementExtensionApproved: {
      icon = <NoCrashIcon fill="#299976" {...iconProps} />;
      break;
    }

    default: {
      icon = null;
      break;
    }
  }

  return <View style={style}>{icon}</View>;
};

export default ServiceRequestStatusIcon;
