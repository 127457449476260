import React, {useState} from 'react';
import {View} from 'react-native';

import sy from '~/styles';
import {Dialog, FormNumberInput} from '~/components/controls';

export default ({value: initial_value, amount, onDismiss, onChange}) => {
  const [discount, setDiscount] = useState(initial_value ?? 0);

  return (
    <Dialog
      visible={true}
      title="Korting"
      onDismiss={() => {
        onChange(initial_value);
        onDismiss();
      }}
      buttons={[
        {
          text: 'Annuleren',
          onPress: () => {
            onChange(initial_value);
            onDismiss();
          },
        },
        {
          text: 'OK',
          onPress: () => {
            onChange(discount);
            onDismiss();
          },
        },
      ]}
      options={{noPaddingContent: true}}>
      <View style={[sy['p-4']]}>
        <FormNumberInput
          label="Kortingsbedrag"
          digits={2}
          value={discount}
          onChangeNumber={(value) => {
            const discount = Math.max(Math.min(value, amount), 0);
            setDiscount(discount);
            onChange(discount);
            return discount;
          }}
        />
      </View>
      <View style={[sy['p-4'], sy['pt-0']]}>
        <FormNumberInput
          label="Bedrag na korting"
          digits={2}
          value={amount - discount}
          onChangeNumber={(value) => {
            const discount = Math.max(amount - Math.max(value, 0), 0);
            setDiscount(discount);
            onChange(discount);
            return amount - discount;
          }}
        />
      </View>
    </Dialog>
  );
};
