import React, {useContext, useEffect} from 'react';
import {View} from 'react-native';
import {createMaterialBottomTabNavigator} from 'react-native-paper/react-navigation';
import {Badge} from 'react-native-paper';
import _ from 'lodash';

import MessagesScreen from '~/screens/messages';
import CalendarScreen from '~/screens/calendar';
import SearchScreen from '~/screens/search';
import withProtectedScreen from '~/components/hoc/with-protected-screen';

import {useSelector, useDispatch} from '~/lib/hooks';
import {throttle} from '~/lib/utils';
import {setMessages} from '~/actions';
import {dealer as dealerApi} from '~/api/private';
import UserContext from '~/components/context/UserContext';

import DateRangeIcon from '~/images/md-icons/date_range/materialicons/24px.svg';
import DateRangeOutlinedIcon from '~/images/md-icons/date_range/materialiconsoutlined/24px.svg';
import SearchIcon from '~/images/md-icons/search/materialicons/24px.svg';
import SearchOutlinedIcon from '~/images/md-icons/search/materialiconsoutlined/24px.svg';
import ChatIcon from '~/images/md-icons/chat/materialicons/24px.svg';
import ChatOutlinedIcon from '~/images/md-icons/chat/materialiconsoutlined/24px.svg';

const TabsStack = createMaterialBottomTabNavigator();

const doFetch = throttle(async (dispatch) => {
  const messages = await dealerApi.messages();
  dispatch(setMessages(messages ?? []));
});

export default ({route}) => {
  let {initialRouteName} = route.params ?? {};
  if (!initialRouteName) {
    // We don't have an initial route yet so we can't render the tabs
    return null;
  }

  const TabMessagesIcon = ({focused, color}) => {
    const {me} = useContext(UserContext);

    const dispatch = useDispatch();
    const fetchMessages = useSelector((state) => state.fetch_messages);
    const forceFetch = useSelector((state) => state.serviceRequestInsert);
    const forceRefresh = useSelector((state) => state.serviceRequestUpdate);

    useEffect(() => {
      if (me) {
        doFetch(dispatch);
      }
    }, [me, fetchMessages, forceFetch, forceRefresh]);

    const messages = useSelector((state) => state.messages);
    const unread = messages?.reduce((acc, message) => acc + message.unread, 0);

    return (
      <View style={{width: 24, height: 24}}>
        {focused ? (
          <ChatIcon fill={color} />
        ) : (
          <ChatOutlinedIcon fill={color} />
        )}
        <Badge
          theme={{colors: {notification: '#ff6c00'}}}
          visible={unread > 0}
          style={{position: 'absolute', top: 0, right: -2}}
          size={10}
        />
      </View>
    );
  };

  return (
    <TabsStack.Navigator
      initialRouteName={initialRouteName}
      shifting={false}
      activeColor="#231fda"
      inactiveColor="#757575"
      barStyle={{
        backgroundColor: '#ffffff',
        borderTopColor: '#dcdcdc',
        borderTopWidth: 1,
      }}
      safeAreaInsets={{bottom: 0}}
      screenOptions={{
        headerShown: false,
      }}>
      <TabsStack.Screen
        name="Messages"
        options={{
          headerShown: false,
          title: 'Berichten',
          tabBarIcon: ({focused, color}) => (
            <TabMessagesIcon focused={focused} color={color} />
          ),
        }}
        component={withProtectedScreen(MessagesScreen)}
      />
      <TabsStack.Screen
        name="Search"
        options={{
          title: 'Zoeken',
          tabBarIcon: ({focused, color}) =>
            focused ? (
              <SearchOutlinedIcon fill={color} />
            ) : (
              <SearchIcon fill={color} />
            ),
        }}
        component={withProtectedScreen(SearchScreen)}
      />
      <TabsStack.Screen
        name="Calendar"
        options={{
          title: 'Kalender',
          tabBarIcon: ({focused, color}) =>
            focused ? (
              <DateRangeIcon fill={color} />
            ) : (
              <DateRangeOutlinedIcon fill={color} />
            ),
        }}
        component={withProtectedScreen(CalendarScreen)}
      />
    </TabsStack.Navigator>
  );
};
