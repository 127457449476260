import React from 'react';
import moment from 'moment';

import globalStyles from '../../../../styles';
import {Pressable, Text} from '../../../controls';

const Day = ({selected, date, availability, selectedDealers, onChangeDate}) => {
  const closed = selectedDealers
    ?.map((dealer_id) => {
      if (!availability) {
        return false;
      }

      const dealer_availability = availability[dealer_id];
      const day_availability =
        dealer_availability && dealer_availability[date.format('YYYY-MM-DD')];
      if (!day_availability) {
        return false;
      }

      return !(day_availability?.capacity > 0) || day_availability?.is_weekend;
    })
    .every(Boolean);

  let style;
  let textStyle;
  if (date.isSame(moment(), 'day')) {
    style = {
      backgroundColor: 'rgba(41, 7, 227, 0.1)',
    };
  }

  if (selected) {
    style = {
      backgroundColor: '#231fda',
    };

    textStyle = {
      color: '#ffffff',
    };
  }

  if (closed) {
    textStyle = {
      ...textStyle,
      textDecorationLine: 'line-through',
    };
  }

  return (
    <Pressable
      style={{
        width: 40,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 90,
        ...style,
      }}
      onPress={() => onChangeDate(date.clone().add(12, 'H'))}>
      <Text style={[globalStyles.mediumBold, {fontSize: 14}, textStyle]}>
        {date.format('D')}
      </Text>
    </Pressable>
  );
};

export default Day;
