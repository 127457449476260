import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';
import _ from 'lodash';

const fleet = {
  contract: async (id) => {
    const result = await fetch(`${endpoints.fleet}/contract`, {
      method: 'POST',
      body: JSON.stringify({id}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  contract_by_license: async (license, dealer_id) => {
    const result = await fetch(`${endpoints.fleet}/contract`, {
      method: 'POST',
      body: JSON.stringify({license, dealer_id}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  request: async (id) => {
    const result = await fetch(`${endpoints.fleet}/${id}`, {
      method: 'POST',
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  invoice: async (id, pdf) => {
    const result = await fetch(`${endpoints.fleet}/${id}/invoice`, {
      method: 'POST',
      body: JSON.stringify({pdf}),
    });

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
  request_replacement_vehicle: async (request_id, replacement) => {
    const result = await fetch(
      `${endpoints.fleet}/${request_id}/replacement_vehicle`,
      {
        method: 'POST',
        body: JSON.stringify({...replacement}),
      },
    );

    if (!result) {
      return {success: false};
    }

    const json = await result.json();
    return json;
  },
  replacement_vehicle: async (request_id) => {
    const result = await fetch(
      `${endpoints.fleet}/${request_id}/replacement_vehicle`,
    );

    if (!result) {
      return {success: false};
    }

    const json = await result.json();
    return json;
  },
  cancel_replacement_vehicle: async (request_id, replacement) => {
    const result = await fetch(
      `${endpoints.fleet}/${request_id}/replacement_vehicle`,
      {
        method: 'DELETE',
        body: JSON.stringify({...replacement}),
      },
    );

    if (!result) {
      return {success: false};
    }

    const json = await result.json();
    return json;
  },
  invoice_pdf: async (id) => {
    const response = await fetch(`${endpoints.fleet}/${id}/invoice/pdf`);

    if (!response) {
      return null;
    }

    const blob = new Blob([await response.blob()], {
      type: 'application/pdf',
    });

    return blob;
  },
};

export default fleet;
