import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'react-native-paper';
import _ from 'lodash';

const SECOND_IN_MS = 1000;

const PrimaryButton = (props) => {
  const timer = useRef(null);
  const mounted = useRef(false);
  const {icon, onPress, children} = props;
  const [disabled, setDisabled] = useState(props.disabled);

  const clearTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = null;
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      clearTimer();
    };
  }, []);

  useEffect(() => {
    setDisabled(props.disabled);
    clearTimer();
  }, [props.disabled]);

  const _onPress = async () => {
    setDisabled(true);
    timer.current = setTimeout(() => setDisabled(false), 10 * SECOND_IN_MS);

    const skip_disable = await onPress();
    if (skip_disable) {
      setDisabled(false);
      clearTimer();
    }
  };

  return (
    <Button
      style={[
        {
          boxShadow: disabled ? null : 'rgba(0, 0, 0, 0.2) 0px 1.5px 5px 0.5px',
        },
        props.style,
      ]}
      color={props.color}
      icon={
        !icon
          ? null
          : () => {
              const component = _.isFunction(icon) ? icon(disabled) : icon;

              return React.cloneElement(
                component,
                _.pickBy({
                  fill: disabled && 'rgba(0, 0, 0, 0.32)',
                }),
              );
            }
      }
      disabled={disabled}
      mode="contained"
      onPress={_onPress}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
