import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  link: {
    textDecorationLine: 'underline',
  },
  disabled: {
    opacity: 0.5,
  },
});
