export default {
  'border-b': {
    borderBottomWidth: 1,
  },
  'border-t': {
    borderTopWidth: 1,
  },
  'border-tb': {
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  'border-gray': {
    borderTopColor: '#dcdcdc',
    borderBottomColor: '#dcdcdc',
  },
  'border-lightgray': {
    borderTopColor: '#f2f2f2',
    borderBottomColor: '#f2f2f2',
  },
};
