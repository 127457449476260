import React from 'react';
import ReactDOM from 'react-dom';

class Portal extends React.PureComponent {
  constructor(props) {
    super(props);
    const div = document.createElement('div');
    div.style = `
      position: fixed;
      z-index: 999999;
	`;

    document.body.appendChild(div);
    this.ref = div;
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.ref);
  }
}

export default Portal;
