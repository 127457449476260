export const STATUS_CODES = {
  Draft: 'Draft',
  Reserved: 'Reserved',
  CheckIn: 'CheckIn',
  ApprovalRequested: 'ApprovalRequested',
  Modified: 'Modified',
  Approved: 'Approved',
  Denied: 'Denied',
  Done: 'Done',
  DoneNoInvoice: 'DoneNoInvoice',
  Invoiced: 'Invoiced',
  Archived: 'Archived',
  NoInvoice: 'NoInvoice',
  NotReadyForInvoicing: 'NotReadyForInvoicing',
  ReplacementVehicle: 'ReplacementVehicle',
  ProvisionallyApproved: 'ProvisionallyApproved',
  ReplacementReservationApproved: 'ReplacementReservationApproved',
  ReplacementCheckoutApproved: 'ReplacementCheckoutApproved',
  ReplacementExtensionApproved: 'ReplacementExtensionApproved',
  Cancelled: 'Cancelled',
};

export const STATUS_DESCRIPTIONS = {
  [STATUS_CODES.Draft]: 'Concept',
  [STATUS_CODES.Reserved]: 'Reservering',
  [STATUS_CODES.CheckIn]: 'Check-in',
  [STATUS_CODES.ApprovalRequested]: 'Voorstel',
  [STATUS_CODES.Modified]: 'Voorstel',
  [STATUS_CODES.Denied]: 'Afkeuring',
  [STATUS_CODES.Approved]: 'Goedkeuring',
  [STATUS_CODES.Done]: 'Gereed',
  [STATUS_CODES.DoneNoInvoice]: 'Geen factuur',
  [STATUS_CODES.Invoiced]: 'Factuur',
  [STATUS_CODES.Modified]: 'Wijziging',
  [STATUS_CODES.Archived]: 'Archief',
  [STATUS_CODES.NoInvoice]: 'Zonder factuur',
  [STATUS_CODES.NotReadyForInvoicing]: 'In behandeling',
  [STATUS_CODES.ReplacementVehicle]: 'Vervangende auto',
  [STATUS_CODES.ProvisionallyApproved]: 'Voorlopig akkoord',
  [STATUS_CODES.ReplacementReservationApproved]:
    'Reservering voorlopig akkoord',
  [STATUS_CODES.ReplacementCheckoutApproved]: 'Uitgifte voorlopig akkoord',
  [STATUS_CODES.ReplacementExtensionApproved]: 'Verlenging voorlopig akkoord',
  [STATUS_CODES.Cancelled]: 'Annulering',
};

export const STATUS_ORDER = [
  STATUS_CODES.Draft,
  STATUS_CODES.Reserved,
  STATUS_CODES.CheckIn,
  STATUS_CODES.ApprovalRequested,
  STATUS_CODES.Modified,
  STATUS_CODES.ProvisionallyApproved,
  STATUS_CODES.ReplacementReservationApproved,
  STATUS_CODES.ReplacementCheckoutApproved,
  STATUS_CODES.ReplacementExtensionApproved,
  STATUS_CODES.Approved,
  STATUS_CODES.Denied,
  STATUS_CODES.Done,
  STATUS_CODES.DoneNoInvoice,
  STATUS_CODES.NotReadyForInvoicing,
  STATUS_CODES.Invoiced,
  STATUS_CODES.NoInvoice,
  STATUS_CODES.Archived,
  STATUS_CODES.Cancelled,
  STATUS_CODES.ReplacementVehicle,
];

export const EXPIRED_STATUS = {
  EXPIRED_30_DAYS: 'EXPIRED_30_DAYS',
  EXPIRED_60_DAYS: 'EXPIRED_60_DAYS',
};
