import React from 'react';

const HtmlDiv = ({
  itemRef,
  style,
  children,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  onMouseOut,
}) => {
  return (
    <div
      ref={itemRef}
      style={{
        display: 'flex',
        overflow: 'hidden',
        boxSizing: 'border-box',
        ...style,
      }}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseOut={onMouseOut}>
      {children}
    </div>
  );
};

export default HtmlDiv;
