import React from 'react';
import moment from 'moment';

import globalStyles from '../../../../styles';
import {Text} from '../../../controls';

const DateText = ({date}) => {
  let text;
  if (date.isSame(moment(), 'date')) {
    text = 'Vandaag';
  } else if (date.isSame(moment().add(1, 'day'), 'date')) {
    text = 'Morgen';
  } else {
    text = date.format('dddd D MMMM');
    text = `${text[0].toUpperCase()}${text.substring(1)}`;
  }

  return <Text style={globalStyles.mediumBold}>{text}</Text>;
};

export default DateText;
